<template>
  <div class="mt-3">
    <!-- <b-table v-if="selectedPacks.length > 0" striped hover :items="selectedPacks"></b-table>  -->

      <div v-for="(pack, propertyName) of groupedPacks" :key="pack.id">

      <div class="d-flex justify-content-between align-items-center">
        <h5 class="mb-3">{{propertyName}}</h5>
      </div>

  <b-card v-for="pack in selectedPacks.filter(x=> x.famille == propertyName)" :key="pack.id" class="mb-3">
    <template v-slot:header>
      <div class="d-flex justify-content-between align-items-center">
        <h6 class="mb-0">{{pack.libelle}}</h6>
        <b-button v-if='!viewOnly' @click="supprimePack(pack.id)" class="mb-2" variant="outline-danger"><b-icon icon="trash"></b-icon></b-button>
      </div>
    </template>
    <b-list-group flush class="d-flex" >
      <b-list-group-item v-for="article in pack.articles" :key="article.id" ref='selectedPacks' :data-modifiable="article.modifiable" href="#" class="justify-content-between align-items-center">
        <b-row>
          <b-col sm="4"><b-icon-lock-fill v-if="!article.modifiable"></b-icon-lock-fill>{{article.ref}}</b-col>
          <b-col sm="2">QTE : {{article.quantite}}</b-col>
          <b-col sm="2">HT : 
            <span :class="article.prixHT != article.nouveauPrixHT ? 'barre' : ''">{{article.prixHT | separators}} </span> <span v-if="article.prixHT != article.nouveauPrixHT">/ {{article.nouveauPrixHT | separators}}</span> 
           </b-col>
          <b-col sm="2">TVA : {{article.tva * 100}} %</b-col>
          <b-col sm="2">
            <!-- <b-input-group append="€">
              <b-input type="number" :id="String(article.id)" :placeholder="String(article.prixTTC)" v-model="article.prixNouveauTTC" :disabled="!article.modifiable" @change="recalculTVA(article)" number></b-input>
            </b-input-group> -->
            TTC : <span :class="article.prixTTC != article.nouveauPrixTTC ? 'barre' : ''">{{article.prixTTC | separators}} </span> <span v-if="article.prixTTC != article.nouveauPrixTTC">/ {{article.nouveauPrixTTC | separators}}</span> 
          </b-col>     
        </b-row>
      </b-list-group-item>
      <b-list-group-item class="justify-content-between align-items-center">
        <b-row>
          <b-col sm="6"></b-col>
          <b-col sm="6">
            <b-input-group prepend="Sous-Total TTC" append="€">
              <b-input type="number" :id="String(pack.id)" number v-model="pack.nouveauSousTotal" @change="repartitionDelta(pack.id)"></b-input>
            </b-input-group>
            <!-- {{ pack.articles.reduce((a, c) => a + parseInt(c.prixTTC), 0) }} -->
          </b-col>     
        </b-row>
      </b-list-group-item>
    </b-list-group>
  </b-card>

    </div>




    <b-card-group deck v-if="selectedPacks.length > 0">
      <b-card border-variant="secondary" header="Total HT" header-border-variant="secondary" align="center">
        <b-card-text>{{totalHT | separators}}</b-card-text>
      </b-card>

      <b-card border-variant="secondary" header="TVA 5,5%" header-border-variant="secondary" align="center">
        <b-card-text>{{totalTVA55 | separators}}</b-card-text>
      </b-card>

      <b-card border-variant="secondary" header="TVA 10%" header-border-variant="secondary" align="center">
        <b-card-text>{{totalTVA10 | separators}}</b-card-text>
      </b-card>

      <b-card border-variant="secondary" header="TVA 20%" header-border-variant="secondary" align="center">
        <b-card-text>{{totalTVA20 | separators}}</b-card-text>
      </b-card>
      <b-card border-variant="info" header="Total TTC" header-bg-variant="info" header-text-variant="white"  align="center">
        <b-card-text>
          {{totalTTC | separators}}

          <!-- <b-input-group append="€">
              <b-input type="number" id="totalTTC" :placeholder="String(totalTTC)" v-model="nouveauTotalTTC" @change="selectArticleModifiable()" number></b-input>
          </b-input-group> -->
        </b-card-text>
      </b-card>
    </b-card-group>
  </div>

</template>

<script>
//import { mapGetters } from "vuex";
import _ from "lodash"
export default {
name: 'InstallationPacks',
  components: {
    //TextInput
  },
  props : ['viewOnly', 'famille'] ,
  data: () => ({
    totalTTC : 0,
    totalHT : 0,
    totalTVA55 : 0,
    totalTVA10 : 0,
    totalTVA20 : 0,
    nouveauTotalTTC : 0,
    delta : 0
  }),
  computed: {
    // ...mapGetters([
    //   'packs'
    // ])
    packs () {
      return this.$store.getters.packs
    },
    selectedPacks() {
      if(this.famille == null) 
        return this.$store.getters.selectedPacks
      else 
        return this.$store.getters.selectedPacks.filter(x=>x.famille == this.famille)
    },
    groupedPacks() {
      const group = _.groupBy(this.selectedPacks, pack => pack.famille);
      return  group

    }
  },
  watch : {
    selectedPacks() {
      this.calculSousTotaux()
      this.calculeTotaux()
      
    }
  },
  methods: {
    supprimePack(packId) {
      this.$store.dispatch('deletePack', packId)
      this.$emit('change')
    },
    repartitionDelta(packId) {
        //console.log(packId)
        var pack = this.selectedPacks.filter(pack => { return pack.id == packId})[0]
        //console.log(pack.nouveauSousTotal)
        //console.log(pack.sousTotal)
        var totalNonModifiable = pack.articles.filter(article => { return !article.modifiable}).reduce((a, c) => a + parseInt(c.prixTTC), 0)
        //console.log(totalNonModifiable)
        var delta = pack.nouveauSousTotal - pack.sousTotal
        var sousTotalModifiable = pack.sousTotal - totalNonModifiable
        //console.log(delta)
        pack.articles.forEach(article => {
          if(article.modifiable) {
            var part = (Math.round((article.prixTTC / sousTotalModifiable) * 100) / 100)
            //console.log(part)
            article.nouveauPrixTTC = article.prixTTC + (delta * part)
            article.nouveauPrixHT =  Math.round((article.nouveauPrixTTC / (1 + article.tva)) * 100) / 100 
          }
        })

        this.calculeTotaux()
    },
    calculSousTotaux() {
      this.selectedPacks.forEach(pack => {
        //console.log(pack)
        pack.sousTotal = 0
        pack.nouveauSousTotal = 0
        pack.articles.forEach(item => {
          pack.sousTotal += ((1 + item.tva) * item.nouveauPrixHT) 
          })
        pack.sousTotal = Math.round(pack.sousTotal * 100) / 100
        pack.nouveauSousTotal = pack.sousTotal
        }) 
    },
    calculeTotaux() {     
      this.totalTVA55 = 0
      this.totalTVA10 = 0
      this.totalTVA20 = 0
      this.totalTTC = 0
      this.totalHT = 0
      this.selectedPacks.forEach(pack => {
        //console.log(pack)
        pack.articles.forEach(item => {
          this.totalHT += item.nouveauPrixHT
          if(item.tva == 0.055) {
            this.totalTVA55 += item.tva * item.nouveauPrixHT
          }
          if(item.tva == 0.10) {
            this.totalTVA10 += item.tva * item.nouveauPrixHT
          }
          if(item.tva == 0.20) {
            this.totalTVA20 += item.tva * item.nouveauPrixHT 
          }
        })
        this.$store.dispatch("updatePack", pack)
      });
      this.totalTVA55 = (Math.round(this.totalTVA55 * 100) / 100)
      this.totalTVA10 = (Math.round(this.totalTVA10 * 100) / 100)
      this.totalTVA20 =(Math.round(this.totalTVA20 * 100) / 100)
      this.totalHT = (Math.round(this.totalHT *100 ) / 100)
      this.totalTTC = Math.round((this.totalTVA55 + this.totalTVA10 + this.totalTVA20 + this.totalHT) * 100) /100
      this.$store.dispatch('updateTotalTTC', this.totalTTC)
      this.$emit('calculTotalTTC', this.totalTTC)
      // if(this.delta == 0)
      //   this.nouveauTotalTTC = this.totalTTC
    },
    // recalculTVA(article) {
    //   this.$store.dispatch("updateArticle", article).then(() => this.calculeTotaux())
    // }
  }
}
</script>

<style scoped>
.list-group-item {
  padding: 0.75rem 0 0.75rem 0 !important;
}
.barre {
  text-decoration-line:line-through;
}
</style>