import Api from '@/services/Api'
import authHeader from './Auth-header';

function CreateEvent (idFiche, event) {
  console.log(event)
  return Api().post(`evenement/create/ORGANISATION/${idFiche}`, event, { headers: authHeader()})
  //return Promise.resolve({status : "OK", message_specifique:"", data:{id_fiche:8530}})
}

function Events (idFiche, id_user_membre, start, length) {
  //id_user_membre = 1
  idFiche = 0
  return Api().get(`evenement/evenements/ORGANISATION/${idFiche}/EVT_VAD/1/1415/${id_user_membre}/${start}/${length}`, { headers: authHeader()})
  
  //return Promise.resolve({status : "OK", message_specifique:"", data:{id_fiche:1}})
}

export default {
    CreateEvent, Events
}