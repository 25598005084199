<template>
  <div>
      <fieldset>
        <legend>Caractéristiques des pièces à équiper en Air/Air</legend>
        <div class="table-responsive-sm">
        <table class="table">
            <thead>
            <th>Pièce</th>
            <th>m2</th>
            <th>L</th>
            <th>Diffuseurs</th>
        </thead>
        <tbody>
            <PieceInfo v-for="(piece, index) in pieces" :key="index" :piece="piece"></PieceInfo>
        </tbody>
        </table>
        </div>
    </fieldset>
    <b-col md="12">
        <b-button variant="info" class="mr-2" @click="ajouterPiece()">Ajouter</b-button>
        <b-button variant="outline-info" @click="supprimerPiece()">Supprimer</b-button>            
        <PieceRecap ></PieceRecap>
    </b-col>
  </div>
</template>

<script>
import PieceInfo from "../components/PieceInfo"
import PieceRecap from '../components/PieceRecap'

export default {
    name: 'Questions',
    components: {
        PieceInfo,
        PieceRecap        
    },
    data: () => ({
        
    }),
    computed: {
        pieces () {
            return this.$store.getters.pieces
        },
        totalDiffuseurs () {
            return this.$store.getters.totalDiffuseurs
        }
    },
    methods :{
        ajouterPiece() {
            this.$store.dispatch("ajouterPiece")
        },
        supprimerPiece() {
            this.$store.dispatch("supprimerPiece").then(() => {
            this.$store.dispatch("calculTotalDiffuseurs")
        })
        }
    }
}
</script>

<style scoped>
legend {
  font-size: 16px;
}
</style>