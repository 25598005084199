import FinancementService from '../../services/FinancementService'

const state =  {
    organismes: [],
    financement : {
      id_orga_financier : 23,
      montantCredit : 0,
      nbEcheance : 120, // nbre_mois_mensualite
      report : "Oui", // 0 ou 1
      dureeReport : 6,
      taeg : 0.0, // taux_global
      taux : 0.0, // taux
      montantEcheanceHorsAssurance : 0, //montant_echeance_hors_assurance
      montantEcheanceAvecAssurance : 0, //montant_echeance_avec_assurance
      coutTotalHorsAssurance : 0, // cout_total_hors_assurance
      coutTotalAvecAssurance : 0, // cout_total_avec_assurance
      observations : "", //commentaire_financement
      optionInstallation : "option 2",
      emprunteur : "Monsieur",
      assurance_emprunteur : "Oui",
      ade_emprunteur : 0.17,
      ddn_emprunteur : "",
      coemprunteur : "Non concerné",
      assurance_coemprunteur: "Oui",
      ade_coemprunteur: 0.17,
      ddn_coemprunteur: "",
      acompteLivraison : 0,  // montant_acompte_livraison
      acompteInstallation : 0, //montant_acompte_installation
      acompteRetractation : 0, //montant_acompte_retractationm
      montantAssuranceEmprunteur : 0,
      montantTotalAssuranceEmprunteur : 0,
      montantAssuranceCoEmprunteur : 0,
      montantTotalAssuranceCoEmprunteur : 0
    }
  };
  
  const getters = {
    organismes : state => { return state.organismes},
    financement : state => { return state.financement}
  }
  
  const mutations = {
    SET_ORGANISMES(state, organismes) {
      state.organismes = organismes
    },
    UPDATE_FINANCEMENT(state, financement) {
      state.financement = financement
    },
  }
  
  const actions = {
    GetOrganismes( context ) {
      FinancementService.ListOrganismes()
        .then(response => {
          if(response.data.statut == "OK") {
            context.commit('SET_ORGANISMES', response.data.liste_organanisme)          
          }
        })
        .catch(error => {
          console.log(error)
        })
    },

    SetFinancement(context, data) {
      context.commit('UPDATE_FINANCEMENT', data)
    },
    CreateFinancement( context , data) {

      return FinancementService.CreateFinancement(data)
        .then(response => {
          if(response.data.statut == "OK") {
             console.log('fin')
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
  
  export default {
      state, getters, mutations, actions
  }