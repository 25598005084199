<template>
<div v-if="selectedAides.length > 0" class="mt-5">
    <b-skeleton-wrapper :loading="loading">
            <template #loading>
              <b-card>
                <template v-slot:header>
                  <div class="d-flex justify-content-between align-items-center">
                    <h6 class="mb-0">Aides</h6>
                  </div>
                </template>
                <b-list-group flush class="d-flex" >
                  <b-list-group-item v-for="aide in selectedAides" :key="aide.id"  class="justify-content-between align-items-center">
                    <b-row>
                      
                      <b-col sm="12">
                        <b-skeleton width="85%"></b-skeleton>
                      <b-skeleton width="55%"></b-skeleton>
                      <b-skeleton width="70%"></b-skeleton>
                    </b-col>  
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
              </b-card>
            </template>

            <b-card>
              <template v-slot:header>
                <div class="d-flex justify-content-between align-items-center">
                  <h6 class="mb-0">Aides</h6>
                  <b-button @click="calculAides()" class="mb-2" variant="outline-info" :disabled="loading || selectedPacks.length == 0">Actualiser <b-icon icon="arrow-clockwise" font-scale="1"></b-icon></b-button>
                </div>
              </template>
              <b-list-group flush class="d-flex" v-if="!firstLoading">
                <b-list-group-item v-for="aide in filteredAides" :key="aide.id"  class="justify-content-between align-items-center">
                  <b-row > <!-- sauf cheque vert -->
                    <b-col sm="3">{{aide.titre}}</b-col>
                    <b-col sm="2"><span v-if="aide.oblige  && aide.oblige.fk_organisation !=1">{{aide.oblige.raison_sociale}}</span></b-col>
                    <b-col sm="2"><span>{{aide.categorie}}</span></b-col>
                    <b-col sm="2">
                      <span v-if="aide.grille != null">{{aide.prime | separators}}</span> 
                      <b-input-group append="€" v-else>
                        <b-input type="number" :id="aide.id" number v-model="aide.prime"></b-input>
                      </b-input-group>
                    </b-col>
                    <b-col sm="3">
                      <!-- <b-form-checkbox :id="aide.titre" v-model="aide.deductible" :checked="aide.deductible" @change="calculAide(aide)" :disabled="aide.oblige.fk_organisation=='8962'">Déduire du devis</b-form-checkbox> -->
                      <b-form-checkbox :id="aide.titre" v-model="aide.deductible" :checked="aide.deductible" @change="calculAide(aide)">Déduire du devis</b-form-checkbox>
 
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-card>

          </b-skeleton-wrapper>
  <b-card-group deck  class="mt-3">
      <b-card border-variant="secondary" header="Aides déduites" header-border-variant="secondary" align="center">
        <b-card-text>{{totalDeduit | separators}}</b-card-text>
      </b-card>

      <b-card border-variant="secondary" header="Aides payées au client" header-border-variant="secondary" align="center">
        <b-card-text>{{totalPaye | separators}}</b-card-text>
      </b-card>
    <b-card border-variant="info" header="Total Aides" header-bg-variant="info" header-text-variant="white"  align="center">
        <b-card-text>
          {{ totalAides | separators}}
        </b-card-text>
      </b-card>
  </b-card-group>
</div>
</template>

<script>

export default {
  name: 'Aides',
  props : ['informations'],
  components: {
  },
  data: () => ({
    loading: false,
    firstLoading : true
  }),
  computed: {
    selectedPacks() {
      return this.$store.getters.selectedPacks
    },
    selectedAides() {
      return this.$store.getters.selectedAides
    },
    filteredAides() {
      return this.selectedAides.filter(a => a.prime > 0 || a.id == "3" || a.id == "14")
    },
    totalAides() {
      return this.$store.getters.totalAides
    },
    totalPaye() {
      return this.$store.getters.totalPaye
    },
    totalDeduit() {
      return this.$store.getters.totalDeduit
    },
    oblige() {
      return this.$store.getters.oblige
    },
    modelVT() {
      return this.$store.getters.modelVT
    },
    aides() {
      return this.$store.getters.aides
    },
    id_user_membre() {
      return this.$store.getters.user.id_user_membre
    },
    totalRevenu() {
      return this.$store.getters.totalRevenu
    },
    totalPersonnes() {
      return this.$store.getters.totalPersonnes
    }
  },
  methods: {
    calculAide(aide) {
      
      return new Promise((resolve) => {
        //if(simple) this.loading = true

        this.selectedPacks.forEach(pack => {
            let _this = this
            let organisation = null 

            //Si obligé choisi dans la liste on le prend sinon on prend l'oblige par defaut
            let res = _this.oblige ? aide.organisations.find(x=>  x.fk_organisation== _this.oblige.id_organisation) : null
            if(res) {
              organisation = res
            }
            else {
              res  = aide.organisations.find(o=> o.is_defaut_grille == "1")
              if(res) {
                organisation = res
              }
            }

            // if((organisation  && organisation.fk_organisation != "8962")  || aide.id == "3" || aide.id == "14") {
            //   aide.deductible = true
            // }

            if(aide.id == "3" || aide.id == "14") {
              aide.deductible = true
            }

            // if(_this.oblige) {
            //   if(!aide.deductible){
            //     // calculer pour l'oblige par defaut
            //     res  = aide.organisations.find(o=> o.is_defaut_grille == "1")
            //     if(res) {
            //       organisation = res
            //     }
            //   }
            // }
            // else {
            //   aide.deductible = false
            // }

            // Calcul des aides
            if(organisation) {
              var data = {
                code_app : "APP_COMMERCIALE",
                numTransaction : Math.floor(Math.random() * 100000) + 1,
                id_user_membre : this.id_user_membre,
                id_programme_aide : aide.id,
                revenu_fiscal : this.totalRevenu,
                nombre_personne : this.totalPersonnes,
                cp : this.informations.codePostal ? this.informations.codePostal.substring(0,2) : "0",
                gamme : pack.gammeId,
                fk_organisation : organisation.fk_organisation,
                raison_sociale : organisation.raison_sociale
              }
              
              resolve(this.$store.dispatch("GetPrimeValue", data))
              // .then(() => {
              //   if(simple) { this.loading = false} 
              // }).catch(() => this.loading = false
              // );
            }
            else {
              resolve()
            }
        })
      })

    },
    calculAides() {

      if(!this.modelVT.eligibleAides)
      {
        return
      }
      
      this.loading = true
      this.firstLoading = false
      let this_ = this
      this.$store.dispatch("ResetAides").then(() => {
        let programme = this.aides//.filter(x => { return x.grille != null})
        if(programme) {
          //chaudiere a condensation que ANAH
          //if(this.modelVT.chauffagePrincipal == 'Chauffage central' && this.modelVT.chaudiereCondensation == 'Oui') {
          if(!this.modelVT.eligibleCDP) {
            // on enleve les aides CDP
            programme = programme.filter(x=> { return !x.code.startsWith('CDP')})
          }

          if(!this.modelVT.eligibleANAH) {
            //on enleve les aides ANAH
            programme = programme.filter(x=> { return !x.code.startsWith('ANAH')})
          }
         
          var promises = programme.map(aide => {
            return this_.calculAide(aide)
          });

          Promise.all(promises.map(p => p.catch(e => e))).then( () => {
            this.loading = false
          });
        }
      })
    }
  }
}
</script>

<style>

</style>