<template>
  <div>
      <b-form-row>
        <b-col md="12">
          <Selection :famille="famille"></Selection>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col md="12">
          <ul>
            <li v-for='error in errors' :key="error.id">{{error.value}}</li>
          </ul>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col md="12">
          <InstallationPacks :viewOnly='viewOnly' :famille="famille"/>
        </b-col>
      </b-form-row>
  </div>
</template>

<script>
import InstallationPacks from './InstallationPacks'
import Selection from './Selection'


export default {
name: 'Installation',
props : ['informations', 'typeDossier','famille'],
  components: {
    InstallationPacks,
    Selection
  },
  data: () => ({
    viewOnly : false,
    errors : [],
  }),
  // computed: {
  //   idOrganisation() {
  //     return this.$store.getters.idOrganisation
  //   },
  //   packList(){
  //     return this.$store.getters.packList
  //   },
  //   packs () {
  //     return this.$store.getters.packs
  //   },
  //   fabricants () {
  //     return this.$store.getters.fabricants
  //   },
  //   fabricantsDefaut () {
  //     return this.$store.getters.fabricantsDefaut
  //   },
  //   selectedPacks() {
  //     return this.$store.getters.selectedPacks
  //   },
  //   client() {
  //     return this.$store.getters.client
  //   }
  // },
  // methods: {
  //   refreshCatalog() {
  //       this.loading = true
  //       this.$store.dispatch('getPacks', this.idOrganisation).then(() => {
  //         this.filtreFabricants = [...this.fabricants]
  //         this.fabricantId = this.filtreFabricants[0].value
  //         this.selectFabricant()
  //         this.loading = false
  //       })
  //   },
  //   ajouterPack(packId){
  //     this.packState = null
  //     const pack = this.packs.filter(p => p.id == packId)
  //     if(pack.length >0) {
  //       this.$store.dispatch("addPack", pack[0]) 
  //       this.packId = null
  //     }
  //     else {
  //       this.packState = false
  //     }
  //   },
  //   //   //this.afficheSelectionProduit = false
  //   //   console.log(this.modelVT.phase)
  //   //   console.log(this.modelVT.superficie)
  //   //   console.log(this.modelVT.nbOccupants)
  //   //   console.log(this.modelVT.hauteurSousPlafond)
  //   //   console.log(this.modelVT.produits)
  //   //   console.log(this.modelVT.materiel)
  //   //   console.log(this.modelVT.produits)

  //   //   this.$store.dispatch('deleteAllPack')
  //   //   let fabricantId = null

  //   //   let pack = null
  //   //   fabricantId = this.fabricantsDefaut.find(x=> x.label == "Pompe à Chaleur Air/Eau").value

  //   // if(this.modelVT.produits.includes('RO') || this.modelVT.produits.includes('EC')) {
  //   //   if(this.modelVT.materiel == "ECS Intégré") {
  //   //     pack = this.packs.filter(
  //   //       p => p.superficieMin <= this.modelVT.superficie 
  //   //       && p.superficieMax >= this.modelVT.superficie 
  //   //       && this.modelVT.superficie > 0
  //   //       && p.gamme == this.modelVT.phase
  //   //       && p.duo == true
  //   //       && p.fabricantId == fabricantId)

  //   //       if(pack.length > 0) {
  //   //         this.ajouterPack(pack[0].id) 
  //   //         this.viewOnly = false
  //   //       }

  //   //   }else {
  //   //     pack = this.packs.filter(
  //   //      p => p.superficieMin <= this.modelVT.superficie 
  //   //      && p.superficieMax >= this.modelVT.superficie 
  //   //      && this.modelVT.superficie > 0
  //   //      && p.gamme == this.modelVT.phase
  //   //      && p.duo == false
  //   //      && p.fabricantId == fabricantId)
 
  //   //    if(pack.length > 0) {
  //   //      this.ajouterPack(pack[0].id) 
  //   //      this.viewOnly = false
  //   //    }


  //   //    //Kit Bizone - type PAC
  //   //    if(this.modelVT.kitBizone) {
  //   //     pack = this.packs.filter(
  //   //      p => p.bizone == true
  //   //      && p.fabricantId == fabricantId)
 
  //   //     if(pack.length > 0) {
  //   //       this.ajouterPack(pack[0].id) 
  //   //       this.viewOnly = false
  //   //     }
  //   //    }
 
  //   //   fabricantId = this.fabricantsDefaut.find(x=> x.label == "Ballon Thermodynamique").value
  //   //    if(this.modelVT.materiel == 'BTD') {
  //   //      if(this.modelVT.hauteurSousPlafond > 0 && this.modelVT.hauteurSousPlafond <= 210) 
  //   //      {
  //   //        pack = this.packs.filter(
  //   //        p => p.personneMin <= this.modelVT.nbOccupants 
  //   //        && p.personneMax >= this.modelVT.nbOccupants 
  //   //        && this.modelVT.nbOccupants > 0
  //   //        && p.type == 'Ballon Thermodynamique'
  //   //        && p.split == true
  //   //        && p.fabricantId == fabricantId)
  //   //      }
  //   //      else {
  //   //        pack = this.packs.filter(
  //   //        p => p.personneMin <= this.modelVT.nbOccupants 
  //   //        && p.personneMax >= this.modelVT.nbOccupants 
  //   //        && this.modelVT.nbOccupants > 0
  //   //        && p.type == 'Ballon Thermodynamique'
  //   //        && p.split == false
  //   //        && p.fabricantId == fabricantId)
  //   //      }

  //   //     if(pack.length > 0) {
  //   //         this.ajouterPack(pack[0].id) 
  //   //         this.viewOnly = false
  //   //     }
  //   //    }

  //   //   fabricantId = this.fabricantsDefaut.find(x=> x.label == "Ballon Electrique").value
  //   //    if(this.modelVT.materiel == 'Ballon Electrique') {
  //   //      pack = this.packs.filter(
  //   //      p => p.personneMin <= this.modelVT.nbOccupants 
  //   //      && p.personneMax >= this.modelVT.nbOccupants 
  //   //      && p.type == 'Ballon Electrique'
  //   //      && p.fabricantId == fabricantId)
 
  //   //      if(pack.length > 0) {
  //   //        this.ajouterPack(pack[0].id) 
  //   //        this.viewOnly = false
  //   //      }
  //   //    }

  //   //   fabricantId = this.fabricantsDefaut.find(x=> x.label == "Ballon Solaire").value
  //   //    if(this.modelVT.materiel == 'Ballon Solaire') {
  //   //      pack = this.packs.filter(
  //   //      p => p.personneMin <= this.modelVT.nbOccupants 
  //   //      && p.personneMax >= this.modelVT.nbOccupants 
  //   //      && this.modelVT.nbOccupants > 0
  //   //      && p.type == 'Ballon Solaire'
  //   //      && p.fabricantId == fabricantId)
 
  //   //      if(pack.length > 0) {
  //   //        this.ajouterPack(pack[0].id) 
  //   //        this.viewOnly = false
  //   //      }
  //   //    }
  //   //   }
  //   // }

  //   // if(this.modelVT.produits.includes('PV')){
  //   //    //Panneaux photovoltaique
  //   //    fabricantId = this.fabricantsDefaut.find(x=> x.label == "Kits résidentiels 3, 6, 9KW").value
  //   //      pack = this.packs.filter(
  //   //       p => p.qtePanneaux == this.modelVT.qtePanneaux 
  //   //       && p.puissancePanneaux == this.modelVT.puissancePanneaux 
  //   //       && this.modelVT.qtePanneaux > 0
  //   //       && p.fabricantId == fabricantId
  //   //     )
 
  //   //      if(pack.length > 0) {
  //   //        this.ajouterPack(pack[0].id) 
  //   //        this.viewOnly = false
  //   //      }
  //   // }

  //   //     //VMC
  //   // if(this.modelVT.produits.includes('VMC')){
  //   //   fabricantId = this.fabricantsDefaut.find(x=> x.label == "Double Flux").value
  //   //   if(this.modelVT.VMC) {
  //   //      pack = this.packs.filter(
  //   //      p => p.fabricantId == fabricantId
  //   //       && p.type == 'Double Flux'
  //   //     )
 
  //   //      if(pack.length > 0) {
  //   //        this.ajouterPack(pack[0].id) 
  //   //        this.viewOnly = false
  //   //      }
  //   //    }
  //   //  }
  //   // },
  //   selectFabricant() {
  //     this.packListFabricant = JSON.parse(JSON.stringify(this.packList))
  //     this.packListFabricant.forEach(group => {
  //       group.options = group.options.filter(item => item.value.fabricantId == this.fabricantId)
  //       if(group.options.length > 0) {
  //         group.options.unshift({value:null, text:"Choisir ..."})

  //       }
  //     })
  //   },
  // }
}
</script>

<style>

</style>