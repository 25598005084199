<template>
<div>
    <b-container fluid="sm" class="mt-5 p-4 bg-info">
        <b-card no-body class="overflow-hidden align-center">
            <b-card-body>

              <b-row>
                <b-col md="6">
                    <b-img center thumbnail fluid :src="pic()" alt="Image 1"></b-img>
                </b-col>
                <b-col>
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(login)" >
                
                <!-- <b-form-group id="questionMode-grp" label="Mode" label-for="questionMode">
                    <b-form-select id="questionMode" v-model="user.mode" :options="questionModeList"></b-form-select>
                </b-form-group> -->

                <b-alert variant="danger" :show="message != ''" class="mt-3 mb-3">{{message}}</b-alert>
                <validation-provider name="Identifiant" :rules="{ required: true, min: 3 }" v-slot="validationContext" >
                    <b-form-group id="login-grp" label="Identifiant" label-for="login">
                        <b-form-input id="login" type="text" v-model="user.username" trim
                        :state="getValidationState(validationContext)" aria-describedby="login-fb"></b-form-input>
                        <b-form-invalid-feedback id="login-fb">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                <validation-provider name="Mot de passe" :rules="{ required: true, min: 3 }" v-slot="validationContext" >
                    <b-form-group id="password-grp" label="Mot de passe" label-for="password">
                        <b-form-input id="password" type="password" v-model="user.password" trim
                        :state="getValidationState(validationContext)" aria-describedby="password-fb"></b-form-input>
                        <b-form-invalid-feedback id="password-fb">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                <b-button type="submit" variant='info'>
                    <b-spinner v-if="loading" small type="border"></b-spinner>
                    Connecter</b-button>
            </b-form>
        </validation-observer>
    </b-col>
              </b-row>
        </b-card-body>

    <!-- <b-row v-for="i in 1000" :key="i">
        <label for="img">Image {{i}}</label>
        <b-card-img id="img" :src="pic(i)" alt="Image" class="rounded-0"></b-card-img>
    </b-row> -->
  </b-card>
</b-container>
</div>
  
</template>

<script>
import User from '../models/user';

export default {
  name: 'Login',
  data :() => ({
    user: new User('', '',''),
    loading: false,
    message: '',
    questionModeList : [
        { value: 'simple', text: 'Simple' },
        { value: 'complet', text: 'Complet'}
    ]
  }),
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  methods: {
      pic() {
        //return "https://picsum.photos/400/400/?image=" + i
        return require('../assets/logo.png')
      },
    login() {
        this.loading = true;
        this.$store.dispatch('login', this.user).then(
            () => {
                this.$router.push('/questions');
            },
            error => {
                this.loading = false;
                if(error.response.status == 403) {
                  this.message = 'Login ou mot de passe incorrect'
                }
                else {
                  this.message = 'Erreur'
                }
            }
        )
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit (evt) {
      evt.preventDefault()
      alert(JSON.stringify(this.form))
    }
  }
};
</script>

<style>
body {
    background-color: lightblue !important;
}

</style>