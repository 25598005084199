import Vue from 'vue'
import Router from 'vue-router'
import Questions from '@/views/Questions'
import Login from "@/views/Login"
import Organisations from "@/views/Organisations"

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'Login',
      component: Login
    },
    {
      path: '/questions/:vtId?',
      name: 'Questions',
      component: Questions
    },
    {
      path: '/questions/:id_regie/:id_client/:id_dossier',
      name: 'Questions',
      component: Questions
    },
    {
      path: '/questions/:id_regie/:id_user_membre',
      name: 'Questions',
      component: Questions
    },
    {
      path: '/organisations',
      name: 'Organisations',
      component: Organisations
    }
  ]
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if(to.path =="/" && loggedIn != null)
  {
    next("/questions")
    
  } else 
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/');
  } else {
    next();
  }
});

export default router