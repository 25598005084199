<template>
    <tr>
        <td style="padding-left:0; padding-right:0">
            <b-input id="piece" class="mb-2 mr-sm-2" v-model="piece.nom"></b-input> 
            <!-- {{ piece.nom }}-->
        </td>
        <td>      
            <b-input-group class="mb-2 mr-sm-2">
                <b-input id="superficiePiece" placeholder="Superficie" v-model="piece.superficie" @change="handlePiece()"></b-input>
            </b-input-group>
        </td>
        <td>
            <div class="form-check">
                <input class="form-check-input mb-2 mr-sm-2 mb-sm-0" type="checkbox" v-model="piece.enL" @change="handlePiece()"  id="defaultCheck1">
            </div>
        </td>
        <td>
            <div v-for="(value, name) in piece.diffuseurs" :key="name"> 
               <span v-if="value != 0"> {{ value }} x {{ name }} Kw</span>
            </div>
        </td>
    </tr>
<!-- </b-form-row> -->
</template>

<script>
export default {
  name: 'PieceInfo',
  components: {
  },
  data: () => ({
      index: 1,
      nbDiffuseur : 0
  }),
  props:['piece'],
//   watch :{
//       piece : {
//           deep:true,
//           handle : function(oldValue, newValue) {
//               console.log(oldValue.enL)
//               console.log(newValue.enL)
//           }
//       }
//   },
  methods : {
    handlePiece(){    
        this.calculPuissance(this.piece.superficie)
      },
    calculPuissance(superficie) {
        var reste = superficie
        this.piece.diffuseurs = {2 : 0, 2.6: 0, 3.6: 0, 5: 0};

        if(!this.piece.enL) {
            while(reste > 0) {
                if(reste >=1 && reste <= 18) {
                    this.piece.diffuseurs[2] ++
                    reste = reste - 18
                } else if(reste >18 && reste <= 25) {
                    this.piece.diffuseurs[2.6] ++
                    reste = reste - 25
                } else if(reste >25 && reste <= 35) {
                    this.piece.diffuseurs[3.6] ++
                    reste = reste - 35
                } else if(reste >35) {
                    this.piece.diffuseurs[5] ++
                    reste = reste - 50
                }            
            }
        }
        else {            
            var nbDiffuseurs = 0
            while(reste > 0) {
                if(nbDiffuseurs < 1) {

                    if(reste >=1 && reste <= 18) {
                        this.piece.diffuseurs[2] ++
                        reste = 0
                    } else if(reste >18 && reste <= 25) {
                        this.piece.diffuseurs[2.6] ++
                        reste = 0
                    } else if(reste >25 && reste <= 30) {
                        this.piece.diffuseurs[3.6] ++
                        reste = 0
                    } else if(reste >30 && reste <= 35) {
                        this.piece.diffuseurs[2] ++
                        reste = reste - 18
                    } else if(reste >35 && reste <= 50) {
                        this.piece.diffuseurs[2.6] ++
                        reste = reste - 25 
                    } else if(reste >50 && reste <= 75) {
                        this.piece.diffuseurs[3.6] ++
                        reste = reste - 35 
                    }else if(reste >75) {
                        this.piece.diffuseurs[5] ++
                        reste = reste - 50
                    }

                    nbDiffuseurs ++
                }
                else {
                    if(reste >=1 && reste <= 18) {
                        this.piece.diffuseurs[2] ++
                        reste = reste - 18
                    } else if(reste >18 && reste <= 25) {
                        this.piece.diffuseurs[2.6] ++
                        reste = reste - 25
                    } else if(reste >25 && reste <= 35) {
                        this.piece.diffuseurs[3.6] ++
                        reste = reste - 35
                    } else if(reste >35) {
                        this.piece.diffuseurs[5] ++
                        reste = reste - 50
                    }   
                }
                nbDiffuseurs ++
            }
        }

        this.$store.dispatch("calculTotalDiffuseurs")
        console.log(this.piece.diffuseurs)
        console.log(reste)
    }
  }
}
</script>

<style>

</style>