<template>
  <div>
      <b-form-row>
        <b-col md="6">
          <label for="fabricant">Sélectionner un fabricant</label>
          <b-input-group  class="mt-1 mb-3">
            <b-form-select id="fabricant" v-model="fabricantId" :options="filtreFabricants" @change="selectFabricant()"></b-form-select>
              <b-input-group-append>
                <b-button @click="refreshCatalog()" class="mb-2" :disabled="loading" variant="info"><b-icon icon="arrow-clockwise" :animation="loading ? 'spin' :'' " font-scale="1"></b-icon></b-button>
              </b-input-group-append>
          </b-input-group>
          <b-form-invalid-feedback id="fabricant-feedback">
            Ce fabricant n'existe pas
          </b-form-invalid-feedback>
        </b-col>
        <b-col md="6">
          <label for="pack">Sélectionner un produit</label>
          <b-input-group  class="mt-1 mb-3">
            <b-form-select v-if="packList" ref="packSelect" v-model="packId" :options="packListFabricant">
              <!-- <template #first>
                <b-form-select-option value="" disabled>Choisir ...</b-form-select-option>
              </template> -->
            </b-form-select>
            <b-input-group-append>
              <b-button variant="info" @click="ajouterPack(packId.id)">Ajouter</b-button>
            </b-input-group-append>
          </b-input-group>
          <b-form-invalid-feedback id="pack-feedback">
            Ce pack n'existe pas
          </b-form-invalid-feedback>
        </b-col>
        <b-col md="6" v-if="delta != 0">
          <b-card border-variant="warning" header="Delta" header-bg-variant="warning" header-text-variant="white"  align="center" >
            <b-card-text v-if="delta > 0">Ajouter {{ delta | separators}}</b-card-text>
            <b-card-text v-else>Retrancher {{ Math.abs(delta) | separators}}</b-card-text>
          </b-card>
        </b-col>
      </b-form-row>
  </div>
</template>

<script>

export default {
  name: 'Selection',
  props: ['bloc', 'selectedTab', 'famille'],
  data: () => ({
    loading: false,
    packId : null,
    packState : null,
    filtreFabricants : [],
    errors : [],
    fabricantId: 0,
    packListFabricant : [],
  }),
  computed: {
    idOrganisation() {
      return this.$store.getters.idOrganisation
    },
    packList(){
      return this.$store.getters.packList
    },
    packs () {
      return this.$store.getters.packs
    },
    fabricants () {
      return this.$store.getters.fabricants
    },
    fabricantsDefaut () {
      return this.$store.getters.fabricantsDefaut
    },
    selectedPacks() {
      return this.$store.getters.selectedPacks
    },
    delta () {
      return this.$store.getters.delta
    },
  },
  watch: {
    fabricants() {
      //if(this.famille != null) {
      //  this.filtreFabricants = [...this.fabricants.filter(x=>x.famille == this.famille)]
      //}
      //else {
        this.filtreFabricants = [...this.fabricants]
      //}
        if(this.filtreFabricants.length > 0) {
          this.fabricantId = this.filtreFabricants[0].value.fk_orga_fab
          this.selectFabricant()
        }
        
    }
  },
  methods: {
    refreshCatalog() {
        this.loading = true
        this.$store.dispatch('getPacks', this.idOrganisation).then(() => {
          this.filtreFabricants = [...this.fabricants]
          this.fabricantId = this.filtreFabricants[0].value.fk_orga_fab

          this.selectFabricant()
          this.loading = false
        })
    },
    ajouterPack(packId){
      this.packState = null
      const pack = this.packs.filter(p => p.id == packId)
      if(pack.length >0) {
        this.$store.dispatch("addPack", pack[0]) 
        this.packId = null
      }
      else {
        this.packState = false
      }
    },
    selectFabricant() {
      this.packListFabricant = JSON.parse(JSON.stringify(this.packList))
      this.packListFabricant.forEach(group => {
        
        if(this.fabricantId) {
          console.log(this.fabricantId.fk_orga_fab)
          group.options = group.options.filter(item => item.value.fabricantId == this.fabricantId.fk_orga_fab)
        }
        
        if(group.options.length > 0) {
          group.options.unshift({value:null, text:"Choisir ..."})

        }
      })


      
      //this.packId = this.packListFabricant[0].options[0].id

    }
  }
}
</script>

<style>

</style>