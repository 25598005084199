<template>
  <b-container class="pt-2" fluid="xl">
    <b-form-row>
      <b-col md="10" >
        <b-alert :show="message !=''" variant="danger" class="text-center">{{message}}</b-alert>
      </b-col>
    </b-form-row>
    <b-form-row v-if="!editMode && !assistantMode && !creationMode">
      <b-col md="3" sm="2" >
        <b-form-group id="mode-grp" label="Mode" label-for="mode">
          <b-form-select id="mode" v-model="mode" :options="modeList" @change="onMode()"></b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="3" align-self="center" class="mt-3">
        <b-button @click="resetVT()"> Nouveau dossier</b-button>
      </b-col>
    </b-form-row>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <!-- Tabs with card integration -->
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset="onReset" v-if="mode != null || editMode || assistantMode || creationMode">
    <b-card no-body>
      <b-tabs v-model="tabIndex" small card pills>
        <b-tab title="Mes informations" :title-link-class="linkClass('info')" @click="selectedTab='info'"  v-if="!editMode">
          <b-form-row v-if="!assistantMode || creationMode">
            <b-col md="3">
              <b-form-group id="emprunteur-grp" label="Civilité" label-for="emprunteur" >
                <b-form-select id="emprunteur" v-model="informations.civilite" @change="handleCivilite()">
                    <b-form-select-option value="M.">Monsieur</b-form-select-option>
                    <b-form-select-option value="Mme">Madame</b-form-select-option>
                </b-form-select>
              </b-form-group>           
            </b-col>
            <b-col md="4">
              <TextInput id="nom" type="text" label="Nom" :rules="{ required: true, min: 3 }" v-model="informations.nom"></TextInput>
            </b-col>
            <b-col md="4">
              <TextInput id="prenom" type="text" label="Prénom" :rules="{ required: true, min: 3 }" v-model="informations.prenom"></TextInput>
            </b-col>
            <b-col style="align-self:center; padding-top:15px">
              <b-button variant="info" @click="handleConjoint()" style="width:38px;height:38px">{{ existeConjoint ? "-" : "+"}}</b-button>
            </b-col>
          </b-form-row>
          <b-form-row v-if="!assistantMode || creationMode" v-show="existeConjoint">
            <b-col md="3">
              <b-form-group id="co-emprunteur-grp" label="Civilité" label-for="co-emprunteur">
                <b-form-select id="co-emprunteur" v-model="informations.civilite_conjoint" @change="handleCivilite()">
                    <b-form-select-option value="M.">Monsieur</b-form-select-option>
                    <b-form-select-option value="Mme">Madame</b-form-select-option>
                </b-form-select>
              </b-form-group>           
            </b-col>
            <b-col md="4">
              <TextInput id="nom_conjoint" type="text" label="Nom conjoint" :rules="{ required: false, min: 3 }" v-model="informations.nom_conjoint"></TextInput>
            </b-col>
            <b-col md="4">
              <TextInput id="prenom_conjoint" type="text" label="Prénom conjoint" :rules="{ required: false, min: 3 }" v-model="informations.prenom_conjoint"></TextInput>
            </b-col>
          </b-form-row>
          <b-form-row v-if="!assistantMode || creationMode">
            <b-col md="12">
              <TextInput id="adresse" type="text" label="Adresse" :rules="{ required: true, min: 3 }" v-model="informations.adresse"></TextInput>             
            </b-col>
          </b-form-row>
          <b-form-row v-if="!assistantMode || creationMode">            
            <b-col md="3">
              <TextInput id="codePostal" type="number" label="Code postal" :rules="{ required: true, min: 5 , max:5}" v-model="informations.codePostal"></TextInput>             
            </b-col>
            <b-col md="6">
              <TextInput id="ville" type="text" label="Ville" :rules="{ required: true, min: 3 }" v-model="informations.ville"></TextInput>             
            </b-col>
          </b-form-row>
          <b-form-row v-if="!assistantMode || creationMode">
            <b-col md="6">
              <TextInput id="email" type="email" label="Email" :rules="{ required: true, email: true}" v-model="informations.email"></TextInput>             
            </b-col>
            <b-col md="3">
              <TextInput id="telephone" type="tel" label="Tél." :rules="{ required: false, min: 10, max:10 }" v-model="informations.telephone"></TextInput>             
            </b-col>
            <b-col md="3">
              <TextInput id="mobile" type="tel" label="Mobile" :rules="{ required: true, min: 10 , max: 10}" v-model="informations.mobile"></TextInput>                 
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode == '7' || assistantMode || creationMode">
            <b-col md="12">
                <h3>Informations fiscales</h3>
            </b-col>
          </b-form-row >
            <b-form-row  v-if="mode == '7'|| assistantMode || creationMode">
            <b-col md="12">
                <b-table-simple responsive>
                  <b-thead>
                    <b-tr>
                      <b-th sticky-column></b-th>
                      <b-th>Foyer fiscal 1</b-th>
                      <b-th>Foyer fiscal 2</b-th>
                      <b-th>Foyer fiscal 3</b-th>
                      <b-th>Total</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-th sticky-column>Revenu fiscal</b-th>
                      <b-td><TextInput id="revenu" type="number" label="" :rules="{ required: true}" v-model.number="informations.revenu1"></TextInput></b-td>
                      <b-td><TextInput id="revenu" type="number" label="" v-model.number="informations.revenu2"></TextInput> </b-td>
                      <b-td><TextInput id="revenu" type="number" label="" v-model.number="informations.revenu3"></TextInput></b-td>
                      <b-td>{{ totalRevenu}}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th sticky-column>Nombre de personnes</b-th>
                      <b-td><TextInput id="nbPersonnes" type="number" label="" :rules="{ required: true}" v-model.number="informations.nbPersonnes1"></TextInput></b-td>
                      <b-td><TextInput id="nbPersonnes" type="number" label="" v-model.number="informations.nbPersonnes2"></TextInput></b-td>
                      <b-td><TextInput id="nbPersonnes" type="number" label="" v-model.number="informations.nbPersonnes3"></TextInput></b-td>
                      <b-td>{{ totalPersonnes}}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
            </b-col>
          </b-form-row>
          <b-form-row v-if="(!assistantMode || creationMode) && showTabs" class="mb-2">
            <b-col md="12">
              <b-card-group deck  class="mt-3">
                <b-card border-variant="secondary" header="Catégorie MPR" :body-class="categorieColor" header-border-variant="secondary" align="center">
                  <b-card-text>{{informations.categorieMPR}} - {{categorieColor}}</b-card-text>
                </b-card>

                <b-card border-variant="secondary" header="Catégorie CEE" header-border-variant="secondary" align="center">
                  <b-card-text>{{informations.categorieCEE}}</b-card-text>
                </b-card>
              <b-card border-variant="secondary" header="Zone Géo" header-border-variant="secondary" align="center">
                  <b-card-text>
                     {{informations.zoneGeo}}
                  </b-card-text>
                </b-card>
            </b-card-group>
            </b-col>
          </b-form-row>
        </b-tab>
        <div v-if="showTabs">
        <!-- Produits -->
        <!-- Maison -->
        <b-tab :title="blocs[0].title" :title-link-class="linkClass(blocs[0].id)"  @click="selectedTab=blocs[0].id">
          <b-form-row v-if="mode !='pv' || mode == '10'">
            <b-col md="6">
              <!-- <b-form-group id="superficie-grp" label="Quel est la superficie de la maison ?" label-for="superficie">
                <b-form-input id="superficie" type="number" v-model="superficie" number></b-form-input>
              </b-form-group> -->
              <b-form-group id="superficie-grp" :label="blocs[0].questions[1].title" :label-for="blocs[0].questions[1].comment">
                <b-form-input :id="blocs[0].questions[1].comment" :type="blocs[0].questions[1].value_type" v-model="blocs[0].questions[1].value" number></b-form-input>
                <b-form-text v-if="modelVT.produits.includes('RO')" id="sup-help" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="anneeMaison-grp" :label="blocs[0].questions[0].title" :label-for="blocs[0].questions[0].comment">
                <b-form-select :id="blocs[0].questions[0].comment" v-model="blocs[0].questions[0].value" :options="blocs[0].questions[0].list_values"></b-form-select>
                <b-form-text v-if="modelVT.produits.includes('RO')" id="annee-hlp" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
          </b-form-row>     
          <b-form-row>
            <b-col md="6">
              <b-form-group :label="blocs[0].questions[48].title">
                <b-form-radio-group :id="blocs[0].questions[48].comment" v-model="blocs[0].questions[48].value" :options="blocs[0].questions[48].list_values">
                </b-form-radio-group>
                <b-form-text id="ele-help" v-if="modelVT.produits.includes('RO')" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>

          </b-form-row>
           <b-form-row>
            <!-- <b-col md="6">
              <b-form-group id="puissanceCompteur-grp" :label="blocs[0].questions[49].title" :label-for="blocs[0].questions[49].comment">
                <b-form-select :id="blocs[0].questions[49].comment" v-model="blocs[0].questions[49].value" :options="blocs[0].questions[49].list_values"></b-form-select>
              </b-form-group>
            </b-col>  -->
            <b-col md="6">
              <b-form-group id="autrePuissanceCompteur-grp" v-if="blocs[0].questions[49].value == blocs[0].questions[49].sub_questions[0].selected_parent_value"
              :label="blocs[0].questions[49].sub_questions[0].title" :label-for="blocs[0].questions[49].sub_questions[0].comment">
                <b-form-input :id="blocs[0].questions[49].sub_questions[0].comment" :type="blocs[0].questions[49].sub_questions[0].value_type" 
                v-model="blocs[0].questions[49].sub_questions[0].value" number></b-form-input>
              </b-form-group> 
            </b-col>
          </b-form-row> 

          <b-button v-b-toggle.collapse-m class="m-1">Bas de page</b-button>
            <b-collapse id="collapse-m">
                    <b-form-row>       
                      <b-col md="6">
                        <b-form-group id="dispositionMaison-grp" :label="blocs[0].questions[2].title" :label-for="blocs[0].questions[2].comment">
                          <b-form-radio-group :id="blocs[0].questions[2].comment" v-model="blocs[0].questions[2].value" name="radio-sub-component15" :options="blocs[0].questions[2].list_values">
                          </b-form-radio-group>           
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group id="combles-grp" :label="blocs[0].questions[3].title" :label-for="blocs[0].questions[3].comment">
                          <b-form-select :id="blocs[0].questions[3].comment" v-model="blocs[0].questions[3].value" :options="blocs[0].questions[3].list_values"></b-form-select>
                       </b-form-group>
                      </b-col>
                    </b-form-row>
                    <b-form-row>       
                      <b-col md="12">
                        <h3>{{blocs[0].questions[43].title}}</h3>
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col md="4">
                        <b-form-group id="deperdition" :label="blocs[0].questions[44].title" :label-for="blocs[0].questions[44].comment">
                          <b-form-select :id="blocs[0].questions[44].comment" v-model="blocs[0].questions[44].value" :options="blocs[0].questions[44].list_values"></b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group id="volume" :label="blocs[0].questions[45].title" :label-for="blocs[0].questions[45].comment">
                          <b-input-group append="M3">
                            <b-form-input :id="blocs[0].questions[45].comment" :type="blocs[0].questions[45].value_type" v-model="blocs[0].questions[45].value" number></b-form-input>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group id="altitude" :label="blocs[0].questions[46].title" :label-for="blocs[0].questions[46].comment">
                          <b-input-group append="M">
                            <b-form-input :id="blocs[0].questions[46].comment" :type="blocs[0].questions[46].value_type" v-model="blocs[0].questions[46].value" number></b-form-input>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group id="P" :label="blocs[0].questions[47].title" :label-for="blocs[0].questions[47].comment">
                          <b-form-input  readonly :id="blocs[0].questions[47].comment" :type="blocs[0].questions[47].value_type" v-model="blocs[0].questions[47].value" number></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-form-row> 
            </b-collapse>
        </b-tab>
        <!-- Chauffage -->   
        <b-tab :title="blocs[2].title" :title-link-class="linkClass(blocs[2].id)" @click="selectedTab=blocs[2].id"  v-if="mode !='pv'">
          <b-form-row v-if="mode =='vt'">
            <b-col md="3">
              <b-form-group :label="blocs[0].questions[4].title">
                <b-form-radio-group id="radio-group-2" v-model="blocs[0].questions[4].value" name="radio-sub-component" :options="blocs[0].questions[4].list_values">
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group v-if="blocs[0].questions[4].value==blocs[0].questions[4].sub_questions[0].selected_parent_value" id="numEtage-grp" :label="blocs[0].questions[4].sub_questions[0].title" :label-for="blocs[0].questions[4].sub_questions[0].comment">
                <b-form-input :id="blocs[0].questions[4].sub_questions[0].comment" :type="blocs[0].questions[4].sub_questions[0].value_type" v-model="blocs[0].questions[4].sub_questions[0].value"></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='vt'">       
            <b-col md="6">
              <b-form-group id="comblesAmenagees-grp" :label="blocs[0].questions[5].title" :label-for="blocs[0].questions[5].comment">
                <b-form-select :id="blocs[0].questions[5].comment" v-model="blocs[0].questions[5].value" :options="blocs[0].questions[5].list_values"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          
          <b-form-row>
            <b-col md="6">
              <b-form-group id="surface-grp" :label="blocs[0].questions[50].title" :label-for="blocs[0].questions[50].comment">
                <b-form-input :id="blocs[0].questions[50].comment" :type="blocs[0].questions[50].value_type" v-model="blocs[0].questions[50].value" number></b-form-input>
                <b-form-text v-if="modelVT.produits.includes('RO')" id="sup-help" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>       
            <b-col md="12">
              <h3>{{blocs[2].questions[11].title}}</h3>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="6">
              <b-form-group id="centrale-grp" :label="blocs[2].questions[1].title">
                <b-form-select :id="blocs[2].questions[1].comment" v-model="blocs[2].questions[1].value" :options="blocs[2].questions[1].list_values">
                </b-form-select>
                <b-form-text v-if="modelVT.produits.includes('RO')" id="cha-help" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="3">
              <b-form-group id="energie-grp" v-if="blocs[2].questions[1].value==blocs[2].questions[1].sub_questions[0].selected_parent_value" :label="blocs[2].questions[1].sub_questions[0].title" :label-for="blocs[2].questions[1].sub_questions[0].comment">
                <b-form-select :id="blocs[2].questions[1].sub_questions[0].comment" v-model="blocs[2].questions[1].sub_questions[0].value" :options="blocs[2].questions[1].sub_questions[0].list_values"></b-form-select>
                <b-form-text v-if="modelVT.produits.includes('RO')" id="cha-nrj" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>            
            <b-col md="3">
              <b-form-group id="anneeChaudiere-grp" v-if="blocs[2].questions[1].value==blocs[2].questions[1].sub_questions[2].selected_parent_value" :label="blocs[2].questions[1].sub_questions[2].title" :label-for="blocs[2].questions[1].sub_questions[2].comment">
                <b-form-select :id="blocs[2].questions[1].comment" v-model="blocs[2].questions[1].sub_questions[2].value" :options="blocs[2].questions[1].sub_questions[2].list_values"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group v-if="(blocs[2].questions[1].value =='Chauffage central' 
                || blocs[2].questions[1].value == 'Pompe à Chaleur Air/Air') && 
                  blocs[2].questions[1].value==blocs[2].questions[1].sub_questions[1].selected_parent_value && blocs[2].questions[1].sub_questions[0].value != 'Electricité (PAC Air/Eau)'" id="chaudiereCondensation-grp" :label="blocs[2].questions[7].title" :label-for="blocs[2].questions[7].comment">
                <b-form-select :id="blocs[2].questions[7].comment" v-model="blocs[2].questions[7].value" :options="blocs[2].questions[7].list_values"></b-form-select>
              <b-form-text id="cha-help" v-if="modelVT.produits.includes('RO')" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>

          </b-form-row>
          <b-form-row>
            <b-col md="6">
                <b-form-group id="cuve-grp" v-if="blocs[0].questions[0].value=='Entre 2 et 15 ans' && blocs[2].questions[1].value==blocs[2].questions[1].sub_questions[3].selected_parent_value && blocs[2].questions[1].sub_questions[0].value == 'Fioul'" :label="blocs[2].questions[1].sub_questions[3].title" :label-for="blocs[2].questions[1].sub_questions[3].comment">
                <b-form-select :id="blocs[2].questions[1].sub_questions[3].comment" v-model="blocs[2].questions[1].sub_questions[3].value" :options="blocs[2].questions[1].sub_questions[3].list_values"></b-form-select>
                <b-form-text v-if="modelVT.produits.includes('RO')" id="cha-cuve" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col md="3">
              <b-form-group v-if=" blocs[2].questions[1].value !=null || blocs[2].questions[1].value =='Convecteurs Electriques' || blocs[2].questions[1].value == 'Radiateurs Electriques'" id="numRadiateur-grp" :label="blocs[2].questions[9].title" :label-for="blocs[2].questions[9].comment">
                <b-form-input :id="blocs[2].questions[9].comment" :type="blocs[2].questions[9].value_type" v-model="blocs[2].questions[9].value" trim number></b-form-input>
                <b-form-text id="numRad-help" v-if="modelVT.plancherChauffant == 'Oui'" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group v-if=" blocs[2].questions[1].value =='Chauffage central' || blocs[2].questions[1].value == 'Pompe à Chaleur Air/Air'" id="matiereRadiateur-grp" :label="blocs[2].questions[8].title" :label-for="blocs[2].questions[8].comment">
                <b-form-select :id="blocs[2].questions[8].comment" v-model="blocs[2].questions[8].value" :options="blocs[2].questions[8].list_values"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if=" blocs[2].questions[1].value =='Chauffage central' || blocs[2].questions[1].value == 'Pompe à Chaleur Air/Air'">
              <b-form-group id="plancherChauffant-grp" :label="blocs[2].questions[10].title" :label-for="blocs[2].questions[10].comment">
                <b-form-select :id="blocs[2].questions[10].comment" v-model="blocs[2].questions[10].value" :options="blocs[2].questions[10].list_values"></b-form-select>
                <b-form-text v-if="modelVT.produits.includes('RO')" id="plancherChauffant-help" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-button v-b-toggle.collapse-chauffage class="m-1">Bas de page</b-button>
          <b-collapse id="collapse-chauffage">       
            <b-form-row>
              <b-col md="6">
                <b-form-group id="thermostat-grp" :label="blocs[2].questions[4].title">
                  <b-form-select :id="blocs[2].questions[4].comment" v-model="blocs[2].questions[4].value" :options="blocs[2].questions[4].list_values">
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>       
              <b-col md="12">
                  <h3>{{blocs[2].questions[12].title}}</h3>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col md="6">
                <b-form-group id="appoint-grp" :label="blocs[2].questions[5].title" :label-for="blocs[2].questions[5].comment">
                  <b-form-checkbox-group :id="blocs[2].questions[5].comment" v-model="blocs[2].questions[5].value" :options="blocs[2].questions[5].list_values"></b-form-checkbox-group>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group id="anneeAppoint-grp" :label="blocs[2].questions[6].title" :label-for="blocs[2].questions[6].comment">
                  <b-form-select :id="blocs[2].questions[6].comment" v-model="blocs[2].questions[6].value" :options="blocs[2].questions[6].list_values"></b-form-select>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col md="6">
                <b-form-group id="typeChauffageElectrique-grp" v-if="blocs[2].questions[0].value==blocs[2].questions[0].sub_questions[0].selected_parent_value" :label="blocs[2].questions[0].sub_questions[0].title" :label-for="blocs[2].questions[0].sub_questions[0].comment">
                  <b-form-select :id="blocs[2].questions[0].sub_questions[0].comment" v-model="blocs[2].questions[0].sub_questions[0].value" :options="blocs[2].questions[0].sub_questions[0].list_values"></b-form-select>
                </b-form-group>

                <b-form-group id="typeChauffageCombustible-grp" v-if="blocs[2].questions[0].value==blocs[2].questions[0].sub_questions[1].selected_parent_value" :label="blocs[2].questions[0].sub_questions[1].title" :label-for="blocs[2].questions[0].sub_questions[1].comment">
                  <b-form-select :id="blocs[2].questions[0].sub_questions[1].comment" v-model="blocs[2].questions[0].sub_questions[1].value" :options="blocs[2].questions[0].sub_questions[1].list_values"></b-form-select>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-if="chauffage=='combustible' && typeChauffageCombustible != 'insert' && mode=='vt'" >
              <b-col md="6">
                <b-form-group id="lieuChaudiere-grp" label="Où est située votre chaudière actuelle ?" label-for="lieuChaudiere">
                  <b-form-input id="lieuChaudiere" type="text" v-model="lieuChaudiere"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Votre chaudière est :">
                  <b-form-radio-group id="radio-group-3" v-model="dispositionChaudiere" name="radio-sub-component3">
                    <b-form-radio value="sol" selected>Posée au sol</b-form-radio>
                    <b-form-radio value="mur">Fixée au mur</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
            </b-form-row> 


        <b-form-row>
          <b-col md="12">
            <b-form-group id="instan-grp" v-if="blocs[3].questions[0].value==blocs[3].questions[0].sub_questions[0].selected_parent_value" :label="blocs[3].questions[0].sub_questions[0].title" :label-for="blocs[3].questions[0].sub_questions[0].comment">
              <b-form-input :id="blocs[3].questions[0].comment" :type="blocs[3].questions[0].sub_questions[0].value_type" v-model="blocs[3].questions[0].sub_questions[0].value" number></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row> 
        <b-form-row>
          <b-col md="6">
            <b-form-group id="nbAdultes-grp" :label="blocs[3].questions[1].title" :label-for="blocs[3].questions[1].comment">
              <b-form-input :id="blocs[3].questions[1].comment" :type="blocs[3].questions[1].value_type" v-model="blocs[3].questions[1].value" number></b-form-input>
            <!-- <b-form-text id="nba-help" v-if="modelVT.produits.includes('EC')" text-variant="danger">Champs requis</b-form-text> -->
            </b-form-group>
          </b-col>            
          <b-col md="6">
            <b-form-group id="nbEnfants-grp" :label="blocs[3].questions[2].title" :label-for="blocs[3].questions[2].comment">
              <b-form-input :id="blocs[3].questions[2].comment" :type="blocs[3].questions[2].value_type" v-model="blocs[3].questions[2].value" number></b-form-input>
            <!-- <b-form-text id="nbe-help" v-if="modelVT.produits.includes('EC')" text-variant="danger">Champs requis</b-form-text> -->
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col md="6">
            <b-form-group id="anneeECS-grp" :label="blocs[3].questions[3].title" :label-for="blocs[3].questions[3].comment">
              <b-form-select :id="blocs[3].questions[3].comment" v-model="blocs[3].questions[3].value" :options="blocs[3].questions[3].list_values"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group id="hauteurPlafond-grp" :label="blocs[3].questions[4].title" :label-for="blocs[3].questions[4].comment">
              <b-input-group append="cm">
                <b-form-input :id="blocs[3].questions[4].comment" :type="blocs[3].questions[4].value_type" v-model="blocs[3].questions[4].value" number></b-form-input>
              </b-input-group>
              <!-- <b-form-text id="hp-help" v-if="modelVT.produits.includes('EC')" text-variant="danger">Champs requis</b-form-text> -->
            </b-form-group>
          </b-col>  
        </b-form-row>

          </b-collapse>
        </b-tab>
        <!-- ECS -->
        <!-- <b-tab :title="blocs[3].title" :title-link-class="linkClass(blocs[3].id)" @click="selectedTab=blocs[3].id" v-if="mode !='pv' && mode != 'pacRR'">
          <b-form-row>
            <b-col md="6">
              <b-form-group :label="blocs[3].questions[0].title">
                <b-form-select :id="blocs[3].questions[0].comment" v-model="blocs[3].questions[0].value" :options="blocs[3].questions[0].list_values" ></b-form-select>
              <b-form-text id="rec-help" v-if="modelVT.produits.includes('EC')" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="recommandation-grp" v-if="blocs[3].questions[0].sub_questions[1].selected_parent_value.includes(blocs[3].questions[0].value)" :label="blocs[3].questions[0].sub_questions[1].title" :label-for="blocs[3].questions[0].sub_questions[1].comment">
                <b-form-radio-group :id="blocs[3].questions[0].sub_questions[1].comment" v-model="blocs[3].questions[0].sub_questions[1].value" :options="blocs[3].questions[0].sub_questions[1].list_values" stacked></b-form-radio-group>
              </b-form-group>
              <b-form-group id="recommandation2-grp" v-if="blocs[3].questions[0].sub_questions[2].selected_parent_value.includes(blocs[3].questions[0].value)" :label="blocs[3].questions[0].sub_questions[2].title" :label-for="blocs[3].questions[0].sub_questions[2].comment">
                <b-form-radio-group :id="blocs[3].questions[0].sub_questions[2].comment" v-model="blocs[3].questions[0].sub_questions[2].value" :options="blocs[3].questions[0].sub_questions[2].list_values" stacked></b-form-radio-group>
              </b-form-group>
              
            </b-col>
          </b-form-row>
          <b-button v-b-toggle.collapse-ecs class="m-1">Bas de page</b-button>
            <b-collapse id="collapse-ecs">
          <b-form-row>
            <b-col md="12">
              <b-form-group id="instan-grp" v-if="blocs[3].questions[0].value==blocs[3].questions[0].sub_questions[0].selected_parent_value" :label="blocs[3].questions[0].sub_questions[0].title" :label-for="blocs[3].questions[0].sub_questions[0].comment">
                <b-form-input :id="blocs[3].questions[0].comment" :type="blocs[3].questions[0].sub_questions[0].value_type" v-model="blocs[3].questions[0].sub_questions[0].value" number></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row> 
          <b-form-row>
            <b-col md="6">
              <b-form-group id="nbAdultes-grp" :label="blocs[3].questions[1].title" :label-for="blocs[3].questions[1].comment">
                <b-form-input :id="blocs[3].questions[1].comment" :type="blocs[3].questions[1].value_type" v-model="blocs[3].questions[1].value" number></b-form-input>
              <b-form-text id="nba-help" v-if="modelVT.produits.includes('EC')" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>            
            <b-col md="6">
              <b-form-group id="nbEnfants-grp" :label="blocs[3].questions[2].title" :label-for="blocs[3].questions[2].comment">
                <b-form-input :id="blocs[3].questions[2].comment" :type="blocs[3].questions[2].value_type" v-model="blocs[3].questions[2].value" number></b-form-input>
              <b-form-text id="nbe-help" v-if="modelVT.produits.includes('EC')" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="6">
              <b-form-group id="anneeECS-grp" :label="blocs[3].questions[3].title" :label-for="blocs[3].questions[3].comment">
                <b-form-select :id="blocs[3].questions[3].comment" v-model="blocs[3].questions[3].value" :options="blocs[3].questions[3].list_values"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="hauteurPlafond-grp" :label="blocs[3].questions[4].title" :label-for="blocs[3].questions[4].comment">
                <b-input-group append="cm">
                  <b-form-input :id="blocs[3].questions[4].comment" :type="blocs[3].questions[4].value_type" v-model="blocs[3].questions[4].value" number></b-form-input>
                </b-input-group>
                <b-form-text id="hp-help" v-if="modelVT.produits.includes('EC')" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>  
          </b-form-row>    
            </b-collapse>     
        </b-tab> -->
        <!-- Dépenses -->
        <b-tab :title="blocs[5].title" :title-link-class="linkClass(blocs[5].id)" @click="selectedTab=blocs[5].id">
         <b-form-row>       
            <b-col md="12">
              <h4>{{blocs[5].questions[12].title}}</h4>
            </b-col>
          </b-form-row>
          <b-form-row >
            <b-col md="6">
              <b-form-group id="depensesAnnuelleElectricite2-grp" :label="blocs[5].questions[1].title" :label-for="blocs[5].questions[1].comment">
                <b-form-input :id="blocs[5].questions[1].comment" :type="blocs[5].questions[1].value_type" v-model="blocs[5].questions[1].value" number></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="depensesAnnuelleElectricite-grp" :label="blocs[5].questions[0].title" :label-for="blocs[5].questions[0].comment">
                <b-form-input :id="blocs[5].questions[0].comment" :type="blocs[5].questions[0].value_type" v-model="blocs[5].questions[0].value" number></b-form-input>
              </b-form-group>
            </b-col>
            
          </b-form-row>
          <b-form-row >
            <b-col md="6">
              <b-form-group id="depensesAnnuelleCombustible-grp" :label="blocs[5].questions[13].title" :label-for="blocs[5].questions[13].comment">
                <b-form-input :id="blocs[5].questions[13].comment" :type="blocs[5].questions[13].value_type" v-model="blocs[5].questions[13].value" number></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="depensesAnnuelleCombustible2-grp" :label="blocs[5].questions[14].title" :label-for="blocs[5].questions[14].comment">
                <b-form-input :id="blocs[5].questions[14].comment" :type="blocs[5].questions[14].value_type" v-model="blocs[5].questions[14].value" number></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">
            <b-col md="6" >
              <b-form-group id="depensesAnnuelleGaz-grp" :label="blocs[5].questions[2].title" :label-for="blocs[5].questions[2].comment">
                <b-form-input :id="blocs[5].questions[2].comment" :type="blocs[5].questions[2].value_type" v-model="blocs[5].questions[2].value" number></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="depensesAnnuelleGazTCC-grp" :label="blocs[5].questions[3].title" :label-for="blocs[5].questions[3].comment">
                <b-form-input :id="blocs[5].questions[3].comment" :type="blocs[5].questions[3].value_type" v-model="blocs[5].questions[3].value" number></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">
            <b-col md="6">
              <b-form-group id="depensesAnnuelleFioul-grp" :label="blocs[5].questions[4].title" :label-for="blocs[5].questions[4].comment">
                <b-form-input :id="blocs[5].questions[4].comment" :type="blocs[5].questions[4].value_type" v-model="blocs[5].questions[4].value" number></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="depensesAnnuelleFioulTCC-grp" :label="blocs[5].questions[5].title" :label-for="blocs[5].questions[5].comment">
                <b-form-input :id="blocs[5].questions[5].comment" :type="blocs[5].questions[5].value_type" v-model="blocs[5].questions[5].value" number></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">
            <b-col md="6">
              <b-form-group id="depensesAnnuelleBois-grp" :label="blocs[5].questions[6].title" :label-for="blocs[5].questions[6].comment">
                <b-form-input :id="blocs[5].questions[6].comment" :type="blocs[5].questions[6].value_type" v-model="blocs[5].questions[6].value" number></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="depensesAnnuelleBoisTCC-grp" :label="blocs[5].questions[7].title" :label-for="blocs[5].questions[7].comment">
                <b-form-input :id="blocs[5].questions[7].comment" :type="blocs[5].questions[7].value_type" v-model="blocs[5].questions[7].value" number></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">
            <b-col md="6" >
              <b-form-group id="depensesAnnuelleBuche-grp" :label="blocs[5].questions[8].title" :label-for="blocs[5].questions[8].comment">
                <b-form-input :id="blocs[5].questions[8].comment" :type="blocs[5].questions[8].value_type" v-model="blocs[5].questions[8].value" number></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="depensesAnnuelleBucheTCC-grp" :label="blocs[5].questions[9].title" :label-for="blocs[5].questions[9].comment">
                <b-form-input :id="blocs[5].questions[9].comment" :type="blocs[5].questions[9].value_type" v-model="blocs[0].questions[9].value" number></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="mode =='10'">
            <b-col md="6">
              <b-form-group id="depensesAnnuellePropane-grp" :label="blocs[5].questions[10].title" :label-for="blocs[5].questions[10].comment">
                <b-form-input :id="blocs[5].questions[10].comment" :type="blocs[5].questions[10].value_type" v-model="blocs[5].questions[10].value" number></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="depensesAnnuellePropaneTCC-grp" :label="blocs[5].questions[11].title" :label-for="blocs[5].questions[11].comment">
                <b-form-input :id="blocs[5].questions[11].comment" :type="blocs[5].questions[11].value_type" v-model="blocs[5].questions[11].value" number></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-tab>
       <!-- Toiture -->
        <b-tab :title="blocs[1].title" :title-link-class="linkClass(blocs[1].id)"  @click="selectedTab=blocs[1].id">
          <b-form-row>
            <b-col md="6">
               <b-form-group :label="blocs[1].questions[7].title">
                <b-form-radio-group :id="blocs[1].questions[7].comment" v-model="blocs[1].questions[7].value" :options="blocs[1].questions[7].list_values">
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>       
            <b-col md="12">
                <h3>{{blocs[1].questions[10].title}}</h3>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="4">
              <b-form-group id="nbPanneaux-grp" :label="blocs[1].questions[8].title" :label-for="blocs[1].questions[8].comment">
                <b-form-input :id="blocs[1].questions[8].comment" :type="blocs[1].questions[8].value_type" v-model="blocs[1].questions[8].value" number></b-form-input>
                <b-form-text v-if="modelVT.produits.includes('PV')" id="qtep-help" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group id="puissancePanneaux-grp" :label="blocs[1].questions[9].title" :label-for="blocs[1].questions[9].comment">
                <b-form-select :id="blocs[1].questions[9].comment" v-model="blocs[1].questions[9].value" :options="blocs[1].questions[9].list_values"></b-form-select>
                <b-form-text v-if="modelVT.produits.includes('PV')" id="pw-help" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group id="total-puissancePanneaux-grp" label="Puissance totale" label-for="total-puissancePanneaux">
                <b-form-input id="total-puissancePanneaux" type="text" :value="blocs[1].questions[8].value * blocs[1].questions[9].value" readonly number></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
                    <b-form-row>
            <b-col md="4">
              <b-form-group id="batterie-grp" :label="blocs[1].questions[11].title" :label-for="blocs[1].questions[11].comment">
                <b-form-select :id="blocs[1].questions[11].comment" :type="blocs[1].questions[11].value_type" v-model="blocs[1].questions[11].value" :options="blocs[1].questions[11].list_values"></b-form-select>
                <b-form-text v-if="modelVT.produits.includes('PV')" id="qtep-help" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col md="12">
              <sous-installation famille="Photovoltaique"></sous-installation>
            </b-col>
          </b-form-row>
          
          <b-button v-b-toggle.collapse-m class="m-1">Bas de page</b-button>
            <b-collapse id="collapse-m">
          <b-form-row>
            <b-col md="6">
              <b-form-group id="lieuPPV-grp" :label="blocs[1].questions[0].title" :label-for="blocs[1].questions[0].comment">
                <b-form-radio-group :id="blocs[1].questions[0].comment" v-model="blocs[1].questions[0].value" name="radio-sub-component5" :options="blocs[1].questions[0].list_values">
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            </b-form-row>
            <b-form-row v-if="blocs[1].questions[0].value==blocs[1].questions[0].sub_questions[1].selected_parent_value">  
            <b-col md="6">
              <b-form-group v-if="blocs[1].questions[0].value==blocs[1].questions[0].sub_questions[0].selected_parent_value" id="precisezAnnexe-grp" :label="blocs[1].questions[0].sub_questions[0].title" :label-for="blocs[1].questions[0].sub_questions[0].comment">
                <b-form-input :id="blocs[1].questions[0].sub_questions[0].comment" :type="blocs[1].questions[0].sub_questions[0].value_type" v-model="blocs[1].questions[0].sub_questions[0].value"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="distanceAnnexeMaison-grp" 
              :label="blocs[1].questions[0].sub_questions[1].title" :label-for="blocs[1].questions[0].sub_questions[1].comment">
                <b-form-input :id="blocs[1].questions[0].sub_questions[1].comment" :type="blocs[1].questions[0].sub_questions[1].value_type" v-model="distanceAnnexeMaison" number></b-form-input>
              </b-form-group>
            </b-col>
            </b-form-row>
            <b-form-row>  
            <b-col md="6">
              <b-form-group :label="blocs[1].questions[1].title">
                <b-form-radio-group id="radio-group-6" v-model="blocs[1].questions[1].value" name="radio-sub-component6" :options="blocs[1].questions[1].list_values">
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="superficieToit-grp" :label="blocs[1].questions[2].title" :label-for="blocs[1].questions[2].comment">
                <b-form-input :id="blocs[1].questions[2].comment" :type="blocs[1].questions[2].value_type" v-model="blocs[1].questions[2].value"></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="6">
              <b-form-group :label="blocs[1].questions[4].title">
                <b-form-radio-group :id="blocs[1].questions[4].comment" v-model="blocs[1].questions[4].value" :options="blocs[1].questions[4].list_values">
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group v-if="blocs[1].questions[4].value==blocs[1].questions[4].sub_questions[0].selected_parent_value" id="inclinaisonToit-grp" 
              :label="blocs[1].questions[4].sub_questions[0].title" :label-for="blocs[1].questions[4].sub_questions[0].comment">
                <b-form-input :id="blocs[1].questions[4].sub_questions[0].comment" :type="blocs[1].questions[4].sub_questions[0].value_type" v-model="blocs[1].questions[4].sub_questions[0].value" number></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row >
            <b-col md="6">
              <b-form-group id="exposition-grp" :label="blocs[1].questions[3].title" :label-for="blocs[1].questions[3].comment">
                <b-form-input :id="blocs[1].questions[3].comment" type="text" v-model="blocs[1].questions[3].value" ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="typeTuile-grp" :label="blocs[1].questions[5].title" :label-for="blocs[1].questions[5].comment">
                <b-form-select :id="blocs[1].questions[5].comment" v-model="blocs[1].questions[5].value" :options="blocs[1].questions[5].list_values"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group v-if="blocs[1].questions[5].value==blocs[1].questions[5].sub_questions[0].selected_parent_value" id="autreTypeTuile-grp" 
                :label="blocs[1].questions[5].sub_questions[0].title" :label-for="blocs[1].questions[5].sub_questions[0].comment">
                <b-form-input :id="blocs[1].questions[5].sub_questions[0].comment" :type="blocs[1].questions[5].sub_questions[0].value_type" v-model="blocs[1].questions[5].sub_questions[0].value"></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="6">
              <b-form-group id="autreTypeTuile-grp" 
                :label="blocs[1].questions[6].title" :label-for="blocs[1].questions[6].comment">
                <b-form-input :id="blocs[1].questions[6].comment" :type="blocs[1].questions[6].value_type" v-model="blocs[1].questions[6].value"></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
            </b-collapse>
        </b-tab>
        <!-- PAC AIR/EAU -->
        <b-tab :title="blocs[12].title" :title-link-class="linkClass(blocs[12].id)" @click="selectedTab=blocs[12].id" >
          <b-form-row>
            <b-col md="6">
              <b-form-group :label="blocs[12].questions[0].title">
                <b-form-radio-group :id="blocs[12].questions[0].comment" v-model="blocs[12].questions[0].value" :options="blocs[12].questions[0].list_values">
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="12">
              <sous-installation famille="Chauffage"></sous-installation>
            </b-col>
          </b-form-row>
        </b-tab>
         <!-- Ballon Thermo -->
        <b-tab :title="blocs[13].title" :title-link-class="linkClass(blocs[13].id)" @click="selectedTab=blocs[13].id" >
                    <b-form-row>
            <b-col md="6">
              <b-form-group :label="blocs[3].questions[0].title">
                <b-form-select :id="blocs[3].questions[0].comment" v-model="blocs[3].questions[0].value" :options="blocs[3].questions[0].list_values" ></b-form-select>
              <b-form-text id="rec-help" v-if="modelVT.produits.includes('EC')" text-variant="danger">Champs requis</b-form-text>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="recommandation-grp" v-if="blocs[3].questions[0].sub_questions[1].selected_parent_value.includes(blocs[3].questions[0].value)" :label="blocs[3].questions[0].sub_questions[1].title" :label-for="blocs[3].questions[0].sub_questions[1].comment">
                <b-form-radio-group :id="blocs[3].questions[0].sub_questions[1].comment" v-model="blocs[3].questions[0].sub_questions[1].value" :options="blocs[3].questions[0].sub_questions[1].list_values" stacked></b-form-radio-group>
              </b-form-group>
              <b-form-group id="recommandation2-grp" v-if="blocs[3].questions[0].sub_questions[2].selected_parent_value.includes(blocs[3].questions[0].value)" :label="blocs[3].questions[0].sub_questions[2].title" :label-for="blocs[3].questions[0].sub_questions[2].comment">
                <b-form-radio-group :id="blocs[3].questions[0].sub_questions[2].comment" v-model="blocs[3].questions[0].sub_questions[2].value" :options="blocs[3].questions[0].sub_questions[2].list_values" stacked></b-form-radio-group>
              </b-form-group>
              
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="12">
              <sous-installation famille="Eau Chaude"></sous-installation>
            </b-col>
          </b-form-row>
        </b-tab>
        <!-- PAC AIR/AIR -->
        <b-tab :title="blocs[11].title" :title-link-class="linkClass(blocs[11].id)" @click="selectedTab=blocs[11].id" >
          <b-form-row>
            <b-col md="6">
              <b-form-group id="nbGrpExt-grp" :label="blocs[11].questions[0].title" :label-for="blocs[11].questions[0].comment">
                <b-form-input :id="blocs[11].questions[0].comment" :type="blocs[11].questions[0].value_type" v-model="blocs[11].questions[0].value" number></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="nbDiff-grp" :label="blocs[11].questions[1].title" :label-for="blocs[11].questions[1].comment">
                <b-form-input :id="blocs[11].questions[1].comment" :type="blocs[11].questions[1].value_type" v-model="blocs[11].questions[1].value" number></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="12">
              <sous-installation famille="Chauffage"></sous-installation>
            </b-col>
          </b-form-row>
        </b-tab>
        <!-- Mon isolation -->
        <b-tab :title="blocs[8].title" :title-link-class="linkClass(blocs[8].id)" @click="selectedTab=blocs[8].id" v-if="mode =='vt'">
          <b-form-row>
            <b-col md="6">
              <b-form-group id="qualiteIsolation-grp" :label="blocs[8].questions[0].title" :label-for="blocs[8].questions[0].comment">
                <b-form-select :id="blocs[8].questions[0].comment" v-model="blocs[8].questions[0].value" :options="blocs[8].questions[0].list_values"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="isolationExterieure-grp" :label="blocs[8].questions[1].title" :label-for="blocs[8].questions[1].comment">
                <b-form-select :id="blocs[8].questions[1].comment" v-model="blocs[8].questions[1].value" :options="blocs[8].questions[1].list_values"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="6">
              <b-form-group id="isolationExterieure-grp" :label="blocs[8].questions[2].title" :label-for="blocs[8].questions[2].comment">
                <b-form-select :id="blocs[8].questions[2].comment" v-model="blocs[8].questions[2].value" :options="blocs[8].questions[2].list_values"></b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-tab>
        <!-- Recommandations -->
        <b-tab :title="blocs[6].title" :title-link-class="linkClass(blocs[6].id)" @click="selectedTab=blocs[6].id" v-if="mode =='10'">
          <b-form-row>
            <b-col md="8">
              <b-form-group id="typeInstallation-grp" :label="blocs[6].questions[0].title" :label-for="blocs[6].questions[0].comment">
                <b-form-checkbox-group :id="blocs[6].questions[0].comment" v-model="blocs[6].questions[0].value" :options="blocs[6].questions[0].list_values" stacked></b-form-checkbox-group>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
          <b-col md="4">
              <b-form-group id="capaciteBallon-grp" v-if="blocs[6].questions[0].value.includes(blocs[6].questions[0].sub_questions[0].selected_parent_value)"
              :label="blocs[6].questions[0].sub_questions[0].title" :label-for="blocs[6].questions[0].sub_questions[0].comment">
                <b-form-input :id="blocs[6].questions[0].sub_questions[0].comment" :type="blocs[6].questions[0].sub_questions[0].value_type" v-model="blocs[6].questions[0].sub_questions[0].value" number></b-form-input>
              </b-form-group> 
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="10" v-if="blocs[6].questions[0].value.includes('PAC Air/Air')">
              <ConfigurateurPAC></ConfigurateurPAC>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="6">
              <b-form-group id="commentaire-grp" :label="blocs[6].questions[1].title" :label-for="blocs[6].questions[1].comment">
                <b-form-input :id="blocs[6].questions[1].comment" :type="blocs[6].questions[1].value_type" v-model="blocs[6].questions[1].value" number></b-form-input>
              </b-form-group> 
            </b-col>
          </b-form-row>
        </b-tab>
        <b-tab title="Mon installation" :title-link-class="linkClass('installation')" @click="selectedTab='installation'" v-if="mode !='10' && !editMode">
          <Installation :informations="informations"/>
        </b-tab>
        <!-- Financement -->
        <b-tab title="Financement" :title-link-class="linkClass('financement')" @click="selectedTab='financement'" v-if="mode !='10' && !editMode">
          <Financement @onError="financementOnError"/>
        </b-tab>
        <!-- Checklist -->
        <b-tab :title="blocs[7].title" :title-link-class="linkClass(blocs[7].id)" @click="selectedTab=blocs[7].id">
          <b-form-row>
            <b-col md="10">
              <div v-for="question in blocs[7].questions" :key="question.id">
                <h5 v-if="question.question_type =='section'">{{question.title}}</h5>
                <SingleFileUpload v-if="question.question_type =='uploadDoc' && (question.condition == null || modelVT && modelVT.produits.includes(question.condition) )" :id="question.comment" :label="question.title" :blockId=7 :questionId=question.id ></SingleFileUpload>
              </div>
            </b-col>
          </b-form-row>
        </b-tab>
        <!-- <b-tab title="Contrat" :title-link-class="linkClass('contrat')" @click="selectedTab='contrat'" v-if="mode =='10' || mode == 'pacRR' || mode == 'pacRO'  || mode == '7' && !editMode">
          <b-form-row>
            <b-col md="12">
              <b-icon icon="file-earmark-text" font-scale="1" class="mr-2"></b-icon>
              <b-link href="https://form.jotform.com/202902468538056" target="blank">Ouvrir le contrat dans une nouvelle fenêtre</b-link>
            </b-col>
          </b-form-row>
        </b-tab> -->
        <b-tab title="Dossier" :title-link-class="linkClass('dossier')" @click="selectedTab='dossier'" v-if="(mode =='10' || mode == 'pacRR' || mode == 'pacRO'  || mode == '7' || assistantMode || creationMode) && !editMode">
          <b-form-row>
            <b-col md="12">
              <CreationDossier :informations="informations" :blocs="blocs" :itemRequired="true"/>
            </b-col>
          </b-form-row>
        </b-tab>
        </div>
      </b-tabs>
      <b-card-footer v-if="editMode"><b-button variant="info" @click="updateVT()">Enregistrer</b-button></b-card-footer>
    </b-card>
    </b-form>
    </validation-observer>
  </b-container>
</template>

<script>
import TextInput from "../components/TextInput";
import Installation from "../components/Installation"
import ConfigurateurPAC from "../components/ConfigurateurPAC"
import SingleFileUpload from "../components/SingleFileUpload"
import CreationDossier from '../components/CreationDossier'
import Financement from '../components/Financement'
import DossierService from '../services/DossierService'
import AidesService from '../services/AideService'
import SousInstallation from '../components/SousInstallation.vue';

//import Param from '../components/Param.vue';
//import BlocItem from '../components/BlocItem'

export default {
  name: 'Questions',
  components: {
    TextInput,
    Installation,
    SingleFileUpload,
    CreationDossier,
    ConfigurateurPAC,
    Financement,
    SousInstallation
    //Param
    //BlocItem
  },
  data: () => ({
      index: 1,
      tabIndex: 0,      
      selectedTab: "info",
      existeConjoint: false,
      show: true,
      mode: null,
      visibleMaison: false,
      editMode : false,
      assistantMode : false,
      creationMode : false,
      categorieMPR: '',
      categorieCEE: '',
      zoneGeo:'',
      categorieColor:'',
      produits: [],
      message :'',
      blocs : [],
      showTabs : true,
      oblige: null , //Premium Energy
      numPieces:null, //0,
      garage:null,
      garageIsole:null,
      chauffage:null,
      nbPiecesChauffees: null, //0
      typeChauffageElectrique: null,
      numRadiateur:null, //0,
      typeChauffageCombustible:null,
      anneeChaudiere:null, //0,
      chaudiereCondensation: null, 
      matiereRadiateur : null,
      plancherChauffant : null,
      chauffageComplet : null,
      superficieChauffee : null, //0,
      qualiteIsolation : null,
      typeVitrage : null,
      isolationExterieure: null,
      productionEauChaude:null,
      cumulus:null,
      installationElectrique:null,
      exposition:null,
      dispositionChaudiere:null,
      numPersonnesFoyer: null, //0
      lieuChaudiere:null,
      typologieClient:null,
      // productionElectricite: null,

      lieuPPV:null,
      puissanceCompteur: null, //0,
      autrePuissanceCompteur: null, //0
      distanceAnnexeMaison:null, //0,
      precisezAnnexe:null,
      productionElectricite: null,
      vtId: null,
      tempAides : [],
      obligeList : [],
      typeTuileList : [
        { value: null, text: 'Choisir ...' },
        { value: 'PVC', text: 'PVC' },
        { value: 'Taule', text: 'Taule'},
        { value: 'Autre', text: 'Autre'}
      ],
      modeList : [
        { value: null, text: 'Choisir ...' },
        // { value: 'pv', text: 'Photovoltaïque' },
        // { value: 'pacRR', text: 'PAC Air/Air' },
        // { value: 'pacRO', text: 'PAC Air/Eau' },
        { value: '7', text: 'VENTE' },
        // { value: 'mixte', text: 'Mixte'},
        { value: '10', text: 'AMO'}
      ],
		zoneCP : [
      {zone : 'A' , cp : ['2A','2B', '86', '06']}, 
			{zone : 'B' , cp : ['29','22','56','50', '44', '85','17', '33', '40']},  
			{zone : 'C' , cp : ['35','16', '24', '47', '32', '64', '65', '82', '81', '34', '11', '66', '09', '31', '13']},  
			{zone : 'D' , cp : ['76','60','02','14','27','95','75','77','92','93','94','91','78','61', '28','45','53','72','41','18','49','37','36','49','86', '26', '07', '46', '12']},  
			{zone : 'E' , cp : ['87','23', '19','03','63','15', '43', '07', '48', '04',]},  
			{zone : 'F' , cp : ['59','62','80']},  
			{zone : 'G' , cp : ['08','51','10','89','21','58','71','39','42','69','01','74', '38', '73', '05', ]}, 
			{zone : 'H' , cp : ['55','52','70', '25']}, 
			{zone : 'I' , cp : ['57','54','88','67','68', '90']}
		],d : {
			A : [[200,-2], [400,-4],[600,-6],[800,-8],[1000,-10],[1200,-12],[1400,-14],[1600,-16],[1800,-18],[2000,-20],[2200,1]], 
			B : [[200,-4], [400,-5],[600,-6],[800,-7],[1000,-8],[1200,-9],[1400,-10],[1600,1],[1800,1],[2000,1],[2200,1]],  
			C : [[200,-5], [400,-6],[600,-7],[800,-8],[1000,-9],[1200,-10],[1400,-11],[1600,-12],[1800,-13],[2000,-14],[2200,-15]],  
			D : [[200,-7], [400,-8],[600,-9],[800,-11],[1000,-13],[1200,-14],[1400,-15],[1600,1],[1800,1],[2000,1],[2200,1]],  
			E : [[200,-8], [400,-9],[600,-11],[800,-13],[1000,-15],[1200,-17],[1400,-19],[1600,-21],[1800,-23],[2000,-25],[2200,27]],  
			F : [[200,-9], [400,-10],[600,-11],[800,-12],[1000,-13],[1200,1],[1400,1],[1600,1],[1800,1],[2000,1],[2200,1]],  
			G : [[200,-10], [400,-11],[600,-13],[800,-14],[1000,-17],[1200,-19],[1400,-21],[1600,-23],[1800,-24],[2000,-25],[2200,29]], 
			H : [[200,-12], [400,-13],[600,-15],[800,-17],[1000,-19],[1200,-21],[1400,-23],[1600,-24],[1800,1],[2000,1],[2200,1]], 
			I : [[200,-15], [400,-15],[600,-19],[800,-21],[1000,-23],[1200,-24],[1400,-25],[1600,1],[1800,1],[2000,1],[2200,1]],
		}
  }),
  mounted(){

    this.$store.dispatch('getPacks', this.idOrganisation).then(() => {

    })

    this.blocs = this.modelVT.blocs
    this.mode = 7
    DossierService.GetTypes().then(result => {
      if(result.data.statut == 'OK') {
        this.modeList = result.data.liste.map(f=> {return {value: f.id_type_dossier, text: f.libelle_type_dossier_app}})
        this.modeList.unshift({ value: null, text: 'Choisir ...' })
        
      }
      else {
        this.message = result.error_message
      }
    }).catch(err => {
        console.log(err.message)
        this.message = err.message
      }
    )

    DossierService.GetObliges().then(result => {
      if(result.data.statut == 'OK') {
        this.obligeList = result.data.liste.map(f=> {return {value: f, text: `${f.raison_sociale} (${f.libelle_activite_organisation}) `}})
        this.obligeList.unshift({ value: null, text: 'Choisir ...' })
        this.oblige = result.data.liste.find(x => x.id_organisation == '11523')
      }
      else {
        this.message = result.error_message
      }
    }).catch(err => {
        console.log(err.message)
        this.message = err.message
      }
    )

    if(this.$route.params && this.$route.params.vtId && this.$route.params.id_regie == null) {
      this.editMode = true;
      this.showTabs = true
      this.assistantMode = false;
      this.creationMode = false;
      this.vtId = this.$route.params.vtId
      DossierService.GetVT(this.$store.state.auth.user.id_user_membre, this.$route.params.vtId).then(response => {
        console.log(response.data.dataVt.json_vt_string)
        const vt = JSON.parse(response.data.dataVt.json_vt_string)
        if(vt.blocs)
          this.blocs = this.modelVT.parse(vt.blocs)
        else 
          this.blocs = this.modelVT.parse(vt)
                 
        }).catch(err => {
          console.log(err)
          this.message = err.message
        }
      )
    }

    if(this.$route.params && this.$route.params.id_regie && this.$route.params.id_user_membre) {
      this.editMode = false
      this.assistantMode = false
      this.creationMode = true;
      this.tabIndex= 0
      this.selectedTab = 'info'
      this.informations.idRegie = this.$route.params.id_regie
    }

    if(this.$route.params && this.$route.params.id_regie && this.$route.params.id_client && this.$route.params.id_dossier) {
      this.editMode = false
      this.assistantMode = true
      this.creationMode = false
      this.informations.id = this.$route.params.id_client

      this.tabIndex= 0
      this.selectedTab = 'info'
    }

    this.$store.dispatch("setClient", this.informations)
    this.$store.dispatch('GetProgrammeAide', this.mode || 7)
  },
  computed : {
    informations() {
      return this.$store.getters.client
    },
    totalRevenu() {
      return this.$store.getters.totalRevenu
    },
    totalPersonnes() {
      return this.$store.getters.totalPersonnes
    },
    codePostal() {
      return this.$store.getters.client.codePostal
    },
    modelVT() {
      return this.$store.getters.modelVT
    },
    aides() {
      return this.$store.getters.aides
    },
    id_user_membre() {
      return this.$store.getters.user.id_user_membre
    },
    idOrganisation() {
      return this.$store.getters.idOrganisation
    },
    fabricantsDefaut() {
      return this.$store.getters.fabricantsDefaut
    },
    g() {
     return  Number(this.modelVT.blocs[0].questions[44].value)
    },
    volume() {
      return this.modelVT.blocs[0].questions[45].value
    },
    altitude() {
      return this.modelVT.blocs[0].questions[46].value
    },
    financement() {
      return this.$store.getters.financement
    },
  },
  watch: {
    informations: {
      handler(){
        if(this.informations.codePostal != null && this.totalPersonnes !=null && this.totalRevenu !=null) {
          this.showTabs = true
        }
        this.$refs.observer.validate().then(success => {
          //this.showTabs = success

          if(success) {
            this.$store.dispatch("setClient", this.informations)
          }
        })
      },
      deep: true
    },
    codePostal () {
      if(this.checkCategorieZone()) {
        this.categorieZoneGeo()
        this.calculerP()
      }
    },
    revenu1 () {
      if(this.checkCategorieZone()) {
        this.categorieZoneGeo()
      }
    },
    nbPersonnes1 () {
      if(this.checkCategorieZone()) {
        this.categorieZoneGeo()
      }
    },
    altitude : {
      handler() {
        this.calculerP()
      }
    },
    g : {
      handler() {
        this.calculerP()
      }
    },
    volume : {
      handler() {
        this.calculerP()
      }
    },
    totalRevenu : {
      handler() {
          this.categorieZoneGeo()
      }
    },
    totalPersonnes : {
      handler() {
          this.categorieZoneGeo()
      }
    },
    modelVT: {
      handler(){
        this.$store.dispatch("setModelVT", this.modelVT)
        if(this.modelVT.produits.includes('PV')) {
          if(this.informations.typeDossier != 1) {
            this.informations.typeDossier = 1
            
          }
        }
        else {
          if(this.informations.typeDossier != this.mode) {
            this.informations.typeDossier = this.mode
          }
          //this.modelVT.blocs[1].questions[9].value = null
        }
        //console.log(this.modelVT.materiel)
      },
      deep: true
    },
    oblige : {
      handler() {
        this.$store.dispatch("MajOblige", this.oblige)
      }
    }
  },
  methods: {
    financementOnError() {
      console.log('financementOnError')
      this.selectedTab='info'
      this.tabIndex=0
      this.$bvToast.toast(`Merci de renseigner le conjoint`, {
          title: `Erreur`,
          toaster: 'b-toaster-top-center',
          solid: true,
          variant:'danger',
          appendToast: false
        })
    },
    checkCategorieZone() {
      return this.informations.codePostal.length >= 5 && this.informations.revenu1.length > 2 && this.informations.nbPersonnes1.length > 0
    },
    linkClass(tab) {
        if (this.selectedTab === tab) {
          return ['bg-info', 'text-light']
        } else {
          return ['bg-light', 'text-info']
        }
      },
    onMode() {
      switch (this.mode) {
        case 'pacRR':
            this.chauffage = "electrique"
          break;
        case 'pacRO':
          this.chauffage = "combustible"
          break;
        case '7' :
          this.chauffage = 'combustible'
          this.showTabs = true
          break;
        case '10' :
          this.showTabs = true
          break;
        default:
          this.chauffage = null
            break;
      }
      this.mode ="vt"
      this.tabIndex= 0
      this.selectedTab = "info"
      this.informations.typeDossier = this.mode
      this.$store.dispatch("setClient", this.informations)
      this.$store.dispatch('GetProgrammeAide', this.mode)
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    showParam() {
      this.$bvModal.show('modal')
    },
    onSubmit (evt) {
      evt.preventDefault()
      alert(JSON.stringify(this.form))
    },
    resetVT() {
      console.log("resetVT")
      this.blocs.forEach(bloc => {
        bloc.questions.forEach(question => {
          Array.isArray(question.value) ? question.value = [] : question.value = null
          if(question.sub_questions)
          {
            question.sub_questions.forEach(subQuestion => {
              Array.isArray(subQuestion.value) ? subQuestion.value = [] : subQuestion.value = null
            })
          }
        })        
      });

      this.informations.civilite = ''
      this.informations.nom = ''
      this.informations.nom_conjoint = ''
      this.informatinos.civilite_conjoint = ''
      this.informations.prenom = ''
      this.informations.prenom_conjoint = ''
      this.informations.adresse = ''
      this.informations.codePostal = null
      this.informations.ville = ''
      this.informations.mobile = null
      this.informations.telephone = null
      this.informations.email = ''
      this.informations.revenu1 = null
      this.informations.revenu2 = null
      this.informations.revenu3 = null
      this.informations.nbPersonnes1 = null
      this.informations.nbPersonnes2 = null
      this.informations.nbPersonnes3 = null

      this.tabIndex= 0
      this.selectedTab = "info"

      this.blocs[10].questions[0].value = ['RO','EC']
      this.$store.dispatch("viderDocuments")
    },
    updateVT() {
      if(this.vtId) {
        DossierService.UpdateVT({
          code_app : "APP_COMMERCIALE",
          numTransaction : Math.floor(Math.random() * 100000) + 1,
          id_user_membre : this.id_user_membre,
          module: "DOSSIER_AFFAIRE",
          id_VT : this.vtId,
          batiment : "",
          detail_emplacement : "",
          jsonVtString : JSON.stringify(this.blocs)
        }).then(() => {
            this.$bvModal.msgBoxOk('Enregistrement réussi', {
                  title: 'Confirmation',
                  size: 'sm',
                  buttonSize: 'sm',
                  okVariant: 'info',
                  hideHeader : true,
                  headerClass: 'p-2 border-bottom-0',
                  footerClass: 'p-2 border-top-0',
                  centered: true
                })
        })
      }
    },
    onReset (evt) {
      evt.preventDefault()
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    updateFabricantDefaut() {
      this.$store.dispatch("updateFabricantDefaut", this.fabricantsDefaut)
    },
    handleCivilite() {
      if(this.informations.civilite == "M.") {
        this.financement.emprunteur = "Monsieur"
      }
      else {
        this.financement.emprunteur = "Madame"
      }

      if(!this.informations.civilite_conjoint || this.informations.civilite_conjoint == '' ) {
        this.financement.coemprunteur = "Non concerné"
      }
      else {
        
        if(this.informations.civilite_conjoint == "M.") {
          this.financement.coemprunteur = "Monsieur"
        }
        else {
          this.financement.coemprunteur = "Madame"
        }
      }
    },
    handleConjoint() {
      this.existeConjoint =! this.existeConjoint
      this.informations.nom_conjoint = ''
      this.informations.prenom_conjoint = ''
    },
    calculerP() {

      const z =  this.zoneCP.find(x=> x.cp.includes(this.informations.codePostal.substring(0,2))) //'A'

      if(z == undefined)
        return 0

      let  deltaT = 0

      if(z.zone != null && this.altitude != null) {
				deltaT = this.d[z.zone].find(x=> { return x[0] >= this.altitude})[1]
			}

			if(this.g != 0 && this.volume != 0) {
        this.blocs[0].questions[47].value = this.g * this.volume * deltaT
        return this.blocs[0].questions[47].value;
      }
			else 
				return 0
    },
    categorieZoneGeo() {
      var data = {
          code_app : "APP_COMMERCIALE",
          numTransaction : Math.floor(Math.random() * 100000) + 1,
          id_user_membre : this.id_user_membre,
          id_programme_aide : 1,
          revenu_fiscal : this.totalRevenu,
          nombre_personne : this.totalPersonnes,
          cp : this.informations.codePostal ? this.informations.codePostal.substring(0,2) : "0",
          fk_organisation : "3651"
        }
        
        AidesService.GetCategorieClient(data)
        .then(response => {
  
          if(response.data.statut == "OK") {
            this.informations.categorieMPR = response.data.nom_categorieMPR
            this.informations.id_categorieMPR = response.data.id_categorieMPR
            this.informations.categorieCEE = response.data.nom_categorieCEE
            this.informations.id_categorieCEE = response.data.id_categorieCEE
            this.informations.zoneGeo = response.data.zone_geo

            switch (this.informations.categorieMPR) {
              case "Très modestes":
                this.categorieColor = "Bleue"
                break;
              case "Modestes":
                this.categorieColor = "Jaune"
                break;
              case "Intermédiaires":
                this.categorieColor = "Violet"
                break;
              case "Les Plus Elevés":
                this.categorieColor = "Rose"
                break;
                                    
              default:
                break;
            }
          }
          
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
legend {
  font-size: 16px;
}
/* Très modestes (Grands Précaires pour CEE)	
Modestes (Précaires pour CEE)	
Intermédiaires (Classiques pour CEE)	
Les Plus Elevés (Classiques pour CEE)	 */
.Bleue {
  background-color: cornflowerblue;
  color: #fff;
}
.Jaune {
  background-color:yellow;
}
.Violet {
  background-color:blueviolet ;
  color: #fff;
}
.Rose {
  background-color: violet;
  color: #fff;
}
</style>
