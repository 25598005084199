<template>
  <div>

      <b-form-row>       
            <b-col md="12">
              <h4>TARIFS ET CONDITIONS DE PAIEMENT</h4>
            </b-col>
          </b-form-row>
          <b-form-row >
            <b-col md="12">
            <b-card-group deck  class="mt-3 mb-3">
              <b-card border-variant="secondary" header="Total TTC" header-border-variant="secondary" align="center">
                <b-card-text>{{totalTTC | separators}}</b-card-text>
              </b-card>
              <b-card border-variant="secondary" header="Aides déduites" header-border-variant="secondary" align="center">
                <b-card-text>{{totalDeduit | separators}}</b-card-text>
              </b-card>
              <b-card border-variant="secondary" header="TOTAL NET A REGLER TTC" header-border-variant="secondary" align="center">
                  <b-card-text>{{ totalNetTTC | separators}}</b-card-text>
              </b-card>
            </b-card-group>
            </b-col>
          </b-form-row>
          <b-form-row >
            <b-col md="12" class="mt-3 mb-3">
              <b-card border-variant="info" header="Au Comptant" header-bg-variant="info" header-text-variant="white"  align="center">
                <b-card-text>
                  <b-col md="6" >
                    <b-form-group label="à la livraison">
                      <b-input-group append="€">
                        <b-form-input id="livraison" type="number" v-model="financement.acompteLivraison" number></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" >
                    <b-form-group label="à l'installation">
                      <b-input-group append="€">
                        <b-form-input id="installation" type="number" v-model="financement.acompteInstallation" number></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-card-text>
              </b-card>
            </b-col>
            <!-- <b-col md="4" >
              <b-form-group label="à l'expiration du délai de retractation">
                <b-input-group append="€">
                  <b-form-input id="retractation" type="number" v-model="financement.acompteRetractation" number></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col> 
            <b-col md="6" >
              <b-form-group label="à la livraison">
                <b-input-group append="€">
                  <b-form-input id="livraison" type="number" v-model="financement.acompteLivraison" number></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6" >
              <b-form-group label="à l'installation">
                <b-input-group append="€">
                  <b-form-input id="installation" type="number" v-model="financement.acompteInstallation" number></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>-->
          </b-form-row>
          <b-form-row>
            <b-col md="12" class="mt-3 mb-3">
              <b-card border-variant="info" header="A Crédit" header-bg-variant="info" header-text-variant="white"  align="center">
                <b-card-text>
                  {{montantFinancement | separators}}
                </b-card-text>
              </b-card>
            </b-col>
          </b-form-row>
          <b-form-row >
            <b-col md="4">
              <b-form-group id="maison-financement-grp" label="Maison de financement" label-for="maisonFin">
                <b-form-select id="maisonFin" v-model="financement.id_orga_financier" @change="handleOrganismeFinancement()">
                    <b-form-select-option value="23">Sofinco</b-form-select-option>
                    <b-form-select-option value="8727">Cetelem</b-form-select-option>
                    <b-form-select-option value="3480">Financo</b-form-select-option>
                    <b-form-select-option value="12">Domo Finance</b-form-select-option>
                    <b-form-select-option value="22">Projexio</b-form-select-option>
                    <b-form-select-option value="1">Comptant</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col md="4" v-if="!comptant">
              <b-form-group id="nb-echeance-grp" label="Nombre d'echéances" label-for="nb-echeance">
                <b-form-select id="nb-echeance" v-model="financement.nbEcheance" invalid-feedback="Merci de renseigner le nombre d'échéances" :state="financement.nbEcheance != null"
                @change="handleNbEcheance">
                    <b-form-select-option value="12">12</b-form-select-option>
                    <b-form-select-option value="24">24</b-form-select-option>
                    <b-form-select-option value="36">36</b-form-select-option>
                    <b-form-select-option value="48">48</b-form-select-option>
                    <b-form-select-option value="60">50</b-form-select-option>
                    <b-form-select-option value="72">72</b-form-select-option>
                    <b-form-select-option value="84">84</b-form-select-option>
                    <b-form-select-option value="96">96</b-form-select-option>
                    <b-form-select-option value="108">108</b-form-select-option>
                    <b-form-select-option value="120">120</b-form-select-option>
                    <b-form-select-option value="132">132</b-form-select-option>
                    <b-form-select-option value="144">144</b-form-select-option>
                    <b-form-select-option value="156">156</b-form-select-option>
                    <b-form-select-option value="168">168</b-form-select-option>
                    <b-form-select-option value="180">180</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col md="2" v-if="!comptant">
              <b-form-group label="Report 6 mois">
                <b-form-radio-group id="report" v-model="financement.report" name="radio-sub-component" :options="['Oui','Non']" invalid-feedback=
                "Merci de renseigner le report" :state="financement.report != null" @change="handleDuree()">
                </b-form-radio-group>
              </b-form-group>
            </b-col>          

          </b-form-row>
          <b-form-row v-if="!comptant">
            <b-col md="4">
              <b-form-group id="emprunteur-grp" label="Emprunteur" label-for="emprunteur" @select="handleCoemprunteur()">
                <b-form-select id="emprunteur" v-model="financement.emprunteur" readonly>
                    <b-form-select-option value="Monsieur">Monsieur</b-form-select-option>
                    <b-form-select-option value="Madame">Madame</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="4" v-if="financement.id_orga_financier != 23">
              <b-form-group id="age-grp" label="Age" label-for="age">
                <b-form-select id="age-emprunteur" v-model="financement.ade_emprunteur">
                    <b-form-select-option value="0.17">-60 ans </b-form-select-option>
                    <b-form-select-option value="0.21">+60 ans</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
              <b-col md="4" v-if="financement.id_orga_financier == 23" >
                <b-form-group id="ddn-grp" label="Date de naissance" label-for="ddn_emprunteur">
                  <b-input-group class="mb-3">
                  <b-form-input
                    id="ddn_emprunteur-input"
                    v-model="ddn_emprunteur"
                    type="text"
                    placeholder="JJ/MM/AAAA"
                    autocomplete="off"
                    v-mask="'##/##/####'"
                    @change="setEmprunteurDate(ddn_emprunteur)"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      id="ddn_emprunteur"
                      :initial-date="initialDate"
                      v-model="financement.ddn_emprunteur"
                      button-only
                      right
                      locale="fr"
                      :max="new Date()"
                      aria-controls="ddn_emprunteur-input"
                      @context="onContextEmprunteur"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="4" v-if="financement.id_orga_financier == 23" >              
                <b-form-group id="assurance-emprunteur-grp" label="Assurance Emprunteur" label-for="assurance-emprunteur">
                  <b-form-select id="assurance-emprunteur" v-model="financement.assurance_emprunteur">
                    <b-form-select-option value="Oui">Oui </b-form-select-option>
                    <b-form-select-option value="Non">Non</b-form-select-option>
                </b-form-select>
              </b-form-group>          
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="4" >
              <b-form-group id="coemprunteur-grp" label="Co-Emprunteur" label-for="co-emprunteur">
                <b-form-select id="co-emprunteur" v-model="financement.coemprunteur">
                    <b-form-select-option value="Non concerné">Non concerné</b-form-select-option>
                    <b-form-select-option value="Monsieur">Monsieur</b-form-select-option>
                    <b-form-select-option value="Madame">Madame</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="4" v-if="financement.coemprunteur != 'Non concerné' && financement.id_orga_financier != 23" >              
                <b-form-group id="age-coemprunteur-grp" label="Age Co-Emprunteur" label-for="age-co-emprunteur">
                <b-form-select id="age-co-emprunteur" v-model="financement.ade_coemprunteur">
                    <b-form-select-option value="0.17">-60 ans </b-form-select-option>
                    <b-form-select-option value="0.21">+60 ans</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="4" v-if="financement.coemprunteur != 'Non concerné' && financement.id_orga_financier == 23" >              
                <b-form-group id="ddn-coemprunteur-grp" label="Date de naissance Co-Emprunteur" label-for="ddn-coemprunteur">
                    <b-input-group class="mb-3">
                  <b-form-input
                    id="ddn_coemprunteur-input"
                    v-model="ddn_coemprunteur"
                    type="text"
                    placeholder="JJ/MM/AAAA"
                    autocomplete="off"
                    v-mask="'##/##/####'"
                    @change="setCoEmprunteurDate(ddn_coemprunteur)"
                  ></b-form-input>
                  <b-input-group-append>

                    <b-form-datepicker
                      id="ddn_coemprunteur"
                      :initial-date="initialDate"
                      v-model="financement.ddn_coemprunteur"
                      button-only
                      right
                      locale="fr"
                      :max="new Date()"
                      aria-controls="ddn_coemprunteur-input"
                      @context="onContextCoEmprunteur"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>          
            </b-col>
            <b-col md="4" v-if="financement.coemprunteur != 'Non concerné' && financement.id_orga_financier == 23" >              
                <b-form-group id="assurance-coemprunteur-grp" label="Assurance Co-Emprunteur" label-for="assurance-coemprunteur">
                  <b-form-select id="assurance-co-emprunteur" v-model="financement.assurance_coemprunteur">
                    <b-form-select-option value="1">Oui </b-form-select-option>
                    <b-form-select-option value="0">Non</b-form-select-option>
                </b-form-select>
              </b-form-group>          
            </b-col>
          </b-form-row>
          <b-form-row v-if="!comptant">
            <b-col md="4">
              <b-form-group id="taeg-grp" label="TAEG" label-for="taeg">
                <b-input-group append="%">
                  <b-form-input id="taeg" type="number" v-model="financement.taeg" number :readonly="isOrganismeFinancier"></b-form-input>
                  <!-- <the-mask :mask="['#.##', '##.##']" :value="financement.taeg" v-model="financement.taeg" type="number" :masked=true placeholder="" class="form-control"></the-mask>-->
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="4" >
              <b-form-group label="Taux débiteur" label-for="taux">
                <b-input-group append="%">
                  <b-form-input id="taux" type="number" v-model="financement.taux" number :readonly="isOrganismeFinancier"></b-form-input> 
                  <!--<the-mask :mask="['#.##', '##.##']" :value="financement.taux" v-model="financement.taux" type="number" :masked=true placeholder="" class="form-control"></the-mask>-->
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="4" class="d-flex align-items-end" style="margin-top: 32px;">
              <b-form-group lablel="">
                <b-button @click="getGrille()" class="mb-2" :disabled="loading" variant="info" v-if="isOrganismeFinancier">Actualiser la grille <b-icon icon="arrow-clockwise" :animation="loading ? 'spin' :'' " font-scale="1"></b-icon></b-button>
            </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="!comptant">
            <b-col md="4" >
              <b-form-group label="Montant des échéances (hors assurance)" label-for="montant-echeance-ha">
                <b-input-group append="€">
                  <b-form-input id="montant-echeance-ha" type="number" v-model="financement.montantEcheanceHorsAssurance" number :readonly="isOrganismeFinancier"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="4" >
              <b-form-group label="Montant des échéances (avec assurance)" label-for="montant-echeance-aa">
                <b-input-group append="€">
                  <b-form-input id="montant-echeance-aa" type="number" v-model="financement.montantEcheanceAvecAssurance" number :readonly="isOrganismeFinancier"></b-form-input>
                </b-input-group> 
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="!comptant">
            <b-col md="4">
              <b-form-group id="cout-total-ha-grp" label="Cout total du crédit (hors assurance)" label-for="cout-total-ha">
                <b-input-group append="€">
                  <b-form-input id="cout-total-ha" type="number" v-model="financement.coutTotalHorsAssurance" number :readonly="isOrganismeFinancier"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group id="cout-total-grp" label="Cout total du crédit (avec assurance)" label-for="cout-total-aa">
                <b-input-group append="€">
                  <b-form-input id="cout-total-aa" type="number" v-model="financement.coutTotalAvecAssurance" number :readonly="isOrganismeFinancier"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>       
            <b-col md="12">
              <h4>DELAIS</h4>
            </b-col>
          </b-form-row>

          <b-form-row>       
            <b-col md="12">
              <b-form-group label="L'installation des produits sera réalisée" v-slot="{ ariaDescribedby }" >
                <b-form-radio-group :aria-describedby="ariaDescribedby" v-model="financement.optionInstallation">
                  <b-form-radio value="option 1">Entre le 15ème et le 30ème jour suivant la livraison des produits (stockage des produits et transfert des risques chez le client)</b-form-radio>
                  <b-form-radio value="option 2">Le jour de la livraison des produits (cf article 4 des conditions générales de vente).</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>       
            <b-col md="12">
              <h4>OBSERVATIONS</h4>
            </b-col>
          </b-form-row>
          <b-form-row> 
            <b-col md="12">
              <b-form-textarea id="textarea-rows" rows="4" no-resize v-model="financement.observations"></b-form-textarea>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="12" class="mt-2">
              <b-button variant="info" v-if="financement.id_orga_financier == 23" @click="transfertContext">Transfert de context</b-button>
            </b-col>
          </b-form-row>


          <b-toast id="invalide-date" variant="warning" solid auto-hide-delay="50000" toaster="b-toaster-top-center">
            <template #toast-title>
              <div class="d-flex flex-grow-1 align-items-baseline">
                <b-img blank blank-color="#ff5555" class="mr-2" width="12" height="12"></b-img>
                <strong class="mr-auto">Attention !</strong>
              </div>
            </template>
              La date de fin d'installation dépasse les 85 ans et 11 mois.<br>
              Veuillez choisir une durée de financement plus courte
          </b-toast>

        </div>

        
</template>

<script>
import FinancementService from '../services/FinancementService'
import {mask} from 'vue-the-mask'

export default {
  name: 'Financement',
  data() {

    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      // 15th two months prior
    const initialDate = new Date(today)
    initialDate.setYear(initialDate.getYear() - 30)
    initialDate.setDate(15)

    return {
      comptant : false,
      loading: false,
      ddn_emprunteur :'',
      ddn_coemprunteur :'',
      initialDate : initialDate,
      equipmentCode :''
    }
  },
  directives: {mask},
  computed : {
    financement() {
      return this.$store.getters.financement
    },
    informations() {
      return this.$store.getters.client
    },
    montantFinancement () { //montant_credit
      console.log(typeof(this.financement.acompteInstallation))
      return this.totalNetTTC - (this.financement.acompteLivraison + this.financement.acompteInstallation + 
        this.financement.acompteRetractation)
      // const acomptes  = 
      //   this.financement.acompteLivraison == '' ? Number(0) : Number(this.financement.acompteLivraison) + 
      //   this.financement.acompteInstallation == '' ? Number(0) : Number(this.financement.acompteInstallation) + 
      //   this.financement.acompteRetractation == ''? Number(0) : Number(this.financement.acompteRetractation) 

      // return this.totalNetTTC - acomptes
    },
    totalTTC () {
      return this.$store.getters.totalTTC
    },
    totalNetTTC () {
      return this.$store.getters.totalTTC - this.$store.getters.totalDeduit;
    },
    duree () {
      return this.financement.nbEcheance - this.financement.dureeReport
    },
    totalDeduit() {
      return this.$store.getters.totalDeduit
    },
    user() {
      return this.$store.state.auth.user
    },
    isOrganismeFinancier() {
      return (this.financement.id_orga_financier == 3480 || this.financement.id_orga_financier == 22 || this.financement.id_orga_financier == 23) &&
        ((this.financement.report != null && this.financement.nbEcheance != null && this.financement.emprunteur != 'Non concerné' && this.financement.ade_emprunteur != 'Non concerné') ||
        (this.financement.report != null && this.financement.nbEcheance != null && this.financement.emprunteur != 'Non concerné' && this.financement.ade_emprunteur != 'Non concerné' && this.financement.coemprunteur != null && this.financement.ade_coemprunteur != null))
    },
    modelVT() {
      return this.$store.getters.modelVT
    },
  },
  watch: {
    financement:{
      handler(){
        if(this.financement.acompteInstallation == '') this.financement.acompteInstallation = 0
        if(this.financement.acompteLivraison == '') this.financement.acompteLivraison = 0
        if(this.financement.acompteRetractation == '') this.financement.acompteRetractation = 0
        this.$store.dispatch("SetFinancement", this.financement)

        if(this.financement.coemprunteur != 'Non concerné' && this.informations.nom_conjoint == null) {
          console.log("EMPTY_CO_EMPRUNTEUR")
          this.$emit("onError", "EMPTY_CO_EMPRUNTEUR")
        } 
      },
      deep: true,
    },
    montantFinancement:{
      handler(newValue){
        this.financement.montantCredit = newValue
      },
      deep: true,
    }
  },
  methods: {
    handleNbEcheance() {
      if(this.financement.ddn_emprunteur) {
        this.isValidInstallationDate(this.financement.ddn_emprunteur)
      }

      if(this.financement.ddn_coemprunteur) {
        this.isValidInstallationDate(this.financement.ddn_coemprunteur)
      }
    },
    isValidInstallationDate(date) {

      if(!date)
        return 

      const INSTALLATION_MONTH = 2
      const WITH_REPORT_MONTH = 5
      const WITHOUT_REPORT_MONTH = 1

      let installation_date = new Date()
      if(this.financement.dureeReport == 0) {
        installation_date.setMonth(installation_date.getMonth() + Number(this.financement.nbEcheance) + WITHOUT_REPORT_MONTH + INSTALLATION_MONTH)
      }
      else {
        installation_date.setMonth(installation_date.getMonth() + Number(this.financement.nbEcheance) + WITH_REPORT_MONTH + INSTALLATION_MONTH)
      }

      let dob = new Date(date)
      var dobYear = dob.getYear();  
      var dobMonth = dob.getMonth();  
        
      var currentYear = installation_date.getYear();  
      var currentMonth = installation_date.getMonth();  
         
      let yearAge = currentYear - dobYear;  
      let monthAge = 0

      //get months  
      if (currentMonth >= dobMonth)  
        //get months when current month is greater  
        monthAge = currentMonth - dobMonth;  
      else {  
        yearAge--;  
        monthAge = 12 + currentMonth - dobMonth;  
      }  

      if(yearAge > 85 || (yearAge == 85 && monthAge >= 11)) {
        this.$bvToast.show('invalide-date')
        return false
      }

      return true
    },
    setEmprunteurDate(date) {
      const day = date.substring(0,2)
      const month = date.substring(3,5)
      const year = date.substring(6,10)
      this.financement.ddn_emprunteur = `${year}-${month}-${day}`
      //console.log(this.financement.ddn_emprunteur)
    },
    setCoEmprunteurDate(date) {
      const day = date.substring(0,2)
      const month = date.substring(3,5)
      const year = date.substring(6,10)
      this.financement.ddn_coemprunteur = `${year}-${month}-${day}`
      //console.log(this.financement.ddn_coemprunteur)
    },
    onContextEmprunteur(ctx) {
        this.ddn_emprunteur = ctx.selectedFormatted
        this.financement.ddn_emprunteur = ctx.activeYMD

        this.isValidInstallationDate(this.financement.ddn_emprunteur)
      },
    onContextCoEmprunteur(ctx) {
        this.ddn_coemprunteur = ctx.selectedFormatted
        this.financement.ddn_coemprunteur = ctx.activeYMD

        this.isValidInstallationDate(this.financement.ddn_coemprunteur)

      },
    handleDuree() {
      if(this.financement.report == "Non") 
        this.financement.dureeReport = 0
      else
        this.financement.dureeReport = 6
    },
    handleCoemprunteur() {
      if(this.financement.emprunteur == 'Monsieur')
        this.financement.coemprunteur = 'Madame'
      
      if(this.financement.emprunteur == 'Madame')
        this.financement.coemprunteur = 'Monsieur'
    },
    handleOrganismeFinancement() {
      this.comptant = this.financement.id_orga_financier == 1
      this.loading = false
    },
    getGrilleProjexio() {
      this.loading = true
      FinancementService.GetGrilleProjexio(this.financement.ade_emprunteur, Math.round(this.financement.montantCredit), this.financement.nbEcheance,this.financement.dureeReport)
        .then(result =>{
          this.financement.taeg = Number(result.data.taeg)
          this.financement.taux = Number(result.data.taea)
          this.financement.montantEcheanceHorsAssurance = Number(result.data.mensualiteSansAssurance)
          this.financement.montantEcheanceAvecAssurance = Number(result.data.mensualiteAvecAssurance) 
          this.financement.coutTotalHorsAssurance = Number(result.data.totalHorsAssurances)
          this.financement.coutTotalAvecAssurance = Number(result.data.totalAvecAssurances)
          
          if(this.financement.coemprunteur != 'Non concerné') {
            FinancementService.GetGrilleProjexio(this.financement.ade_coemprunteur, Math.round(this.financement.montantCredit), this.financement.nbEcheance,this.financement.dureeReport)
            .then(result =>{
              this.financement.montantEcheanceAvecAssurance += (Number(result.data.mensualiteAvecAssurance) - Number(result.data.mensualiteSansAssurance))
              this.financement.coutTotalAvecAssurance += (Math.round(Number(result.data.totalAvecAssurances) -  Number(result.data.totalHorsAssurances),2))
            }).catch(error => {
              this.loading= false
              if(error.response.status == 500) {
                console.log("Error Grille")
              }
            })
          }          
          this.loading = false
        }).catch(error => {
          this.loading= false
          if(error.response.status == 500) {
            console.log("Error Grille")
          }
        })
    },
    getGrilleSofinco() {

      this.equipmentCode = this.modelVT.produits.includes('RO') || this.modelVT.produits.includes('RR') ? '292' : '29c'
      this.loading = true
      const data = {
        amount: Math.round(this.financement.montantCredit),
        dueNumbers: [this.financement.nbEcheance],
        scaleCode: this.financement.report == "Non" ? "AHNC" : "AHNCR6",
        equipmentCode: this.equipmentCode,
        brandStructure: "TV",
        monthlyAmount: null,
        hasBorrowerInsurance: this.financement.assurance_emprunteur == 'Oui',
        hasCoBorrowerInsurance: this.financement.coemprunteur != 'Non concerné' && this.financement.assurance_coemprunteur == 'Oui',
        borrowerBirthDate: this.financement.ddn_emprunteur,
        coBorrowerBirthDate: this.financement.coemprunteur != 'Non concerné' ? this.financement.ddn_coemprunteur : null,
        businessProviderId: null,
        offerDate: null,
        hasEquipmentInsurance: false,
        personalContributionAmount: null,
        orderAmount: null,
        promotionalCode: null
      }

      try {
        FinancementService.GetGrilleSofinco(data).then(result => {
          if(result.data) {
            this.financement.taux = Number(result.data[0].annualPercentageRate)
            this.financement.taeg = Number(result.data[0].annualPercentageRateOfCharge)
            this.financement.montantEcheanceHorsAssurance = Number(result.data[0].monthlyAmountWithoutInsurance)
            this.financement.montantEcheanceAvecAssurance = Number(result.data[0].monthlyAmountWithInsurance) 
            this.financement.coutTotalHorsAssurance = Number(result.data[0].totalAmountWithoutInsurance)
            this.financement.coutTotalAvecAssurance = Number(result.data[0].totalAmountWithInsurance)
            this.financement.montantAssuranceEmprunteur = Number(result.data[0].borrowerMonthlyInsuranceAmount)
            this.financement.montantTotalAssuranceEmprunteur = Number(result.data[0].borrowerTotalInsuranceAmount)
            this.financement.montantAssuranceCoEmprunteur = Number(result.data[0].coBorrowerMonthlyInsuranceAmount)
            this.financement.montantTotalAssuranceCoEmprunteur = Number(result.data[0].coBorrowerTotalInsuranceAmount)
            console.log(result)
          }
          this.loading = false
        })
      } catch (error) {
        this.loading= false
          if(error.response.status == 500) {
            console.log("Error Grille")
          }

          if(error.response.status == 401) {
            console.log("Token expired")
          }
      }
     
    },
    getGrille() {

      if(this.financement.id_orga_financier == 22) {
        this.getGrilleProjexio()
        return
      }

      if(this.financement.id_orga_financier == 23) {
        this.getGrilleSofinco()
        return
      }

      if(this.financement.report == null || this.financement.nbEcheance == null)
        return

      this.loading = true
      //id_organisation, montant_credit, nombre_mensualite, report
      FinancementService.GetGrille(this.financement.id_orga_financier, 
        Math.round(this.financement.montantCredit), this.financement.nbEcheance,this.financement.dureeReport)
      //FinancementService.GetGrille(this.financement.id_orga_financier, 1000, this.financement.nbEcheance,this.financement.dureeReport)
      .then(result =>{
        this.financement.taeg = Number(result.data.TAEG)
        this.financement.taux = Number(result.data.taux_debiteur_fixe)
        this.financement.montantEcheanceHorsAssurance = Number(result.data.montant_mensualites_hors_ass)
        this.financement.montantEcheanceAvecAssurance = Number(result.data.montant_mensualites_hors_ass) + Number(result.data.cout_mensuel_ass_1)
        this.financement.coutTotalHorsAssurance = Number(result.data.montant_total_emprunteur_hors_ass)
        this.financement.coutTotalAvecAssurance = Number(result.data.montant_total_emprunteur_hors_ass) + Number(result.data.cout_total_ass_1)
        this.loading = false
      }).catch(error => {
        this.loading= false
        if(error.response.status == 500) {
          console.log("Error Grille")
        }
        
      })
    },
    async transfertContext() {
      
      const businessData ={
        //"uniqueExternalSaleId": "87654321",
        "action": "SUBSCRIPTION",
        "borrower": {
            "salutationCode": this.financement.emprunteur=='Monsieur' ? "1" : "2",
            "name": this.informations.nom,
            "firstName": this.informations.prenom,    
            "birthDate": this.financement.ddn_emprunteur.replaceAll('-',''),
            "addressStreet": this.informations.adresse,
            "addressStreetAddInfo": null,
            "addressZipCode": this.informations.codePostal,
            "addressCity": this.informations.ville,
            "addressLocality": null,
            "addressCountry": "F",
            "phoneNumber": this.informations.telephone,
            "mobilePhoneNumber": this.informations.mobile,
            "email": this.informations.email
        },
        "simulation": {
            "loanDuration": this.financement.nbEcheance,
            "productNature": this.equipmentCode,
            "purchaseOrder": "1324567",
            "purchaseAmount": Math.round(this.financement.montantCredit),
            "loanAmount": Math.round(this.financement.montantCredit),
            "downPaymentAmount": 0,
            "otherScale" : this.financement.report == "Non" ? "AHNC" : "AHNCR6",
            "priorSimulationResult": {
              "monthlyAmountWithoutInsurance": this.financement.montantEcheanceHorsAssurance,
              "monthlyAmountWithInsurance": this.financement.montantEcheanceAvecAssurance,
              "totalAmountWithoutInsurance": this.financement.coutTotalHorsAssurance,
              "totalAmountWithInsurance": this.financement.coutTotalAvecAssurance,
              "borrowerMonthlyInsuranceAmount": this.financement.montantAssuranceEmprunteur ,
              "borrowerTotalInsuranceAmount": this.financement.montantTotalAssuranceEmprunteur 
            }
        },
        "configContext": {
            "configContextApplicationId": "ceasy",
            "configContextPartnerId": "pdv_em",
            "configContextSourceId": "vac"
        }
    }

    if(this.financement.coemprunteur != 'Non concerné') {
      businessData.partner = {
        "salutationCode": this.financement.assurance_coemprunteur=='Monsieur' ? "1" : "2",
        "name": this.informations.nom_conjoint,
        "firstName": this.informations.prenom_conjoint,
        "birthDate": this.financement.ddn_coemprunteur.replaceAll('-','')
      }
    }

    console.log(businessData)

    const data = {
      tokenFormat: "opaque",
      businessContext : JSON.stringify(businessData),
      associationKey: "PA086154"
    }

    try {
      const result = await FinancementService.CreateFinancementSofinco(data)
      console.log(result)

      if(result.data) {
        const url = `https://part.c-easy.transcred.com/PDV_CEASY/login-ldap?q6=em&b6=${result.data.token}&b6o=${result.data.tokenOwner}`
        window.open(url, "_blank");
      }
    } catch (error) {
      this.loading= false
          if(error.response.status == 500) {
            console.log("Error tranfert context")
          }
    }
    


    }
  }
}
</script>

<style>

</style>