<template>
    <fieldset class="mt-3">
        <!-- <legend>Récapitulatif des diffuseurs à installer</legend>
        <div v-for="(value, name) in totalDiffuseurs" :key="name"> 
            <span v-if="value != 0"> {{ value }} x {{ name }} Kw</span>
        </div> -->
        <b-card-group deck v-if="nbDiffuseurs > 0" class="mt-3">
        <b-card border-variant="secondary" header="Récapitulatif des diffuseurs à installer" header-border-variant="secondary" align="center">
            <b-card-text>
                <div v-for="(value, name) in totalDiffuseurs" :key="name"> 
                    <span v-if="value != 0"> {{ value }} x {{ name }} Kw</span>
                </div>
            </b-card-text>
        </b-card>

        <b-card border-variant="secondary" header="Diffuseur(s)" header-border-variant="secondary" align="center">
            <b-card-text>{{nbDiffuseurs}}</b-card-text>
        </b-card>

        <b-card border-variant="secondary" header="Puissance" header-border-variant="secondary" align="center">
            <b-card-text>{{puissance}} KW </b-card-text>
        </b-card>

        <b-card border-variant="info" header="Groupe(s) Externe(s)" header-bg-variant="info" header-text-variant="white"  header-border-variant="secondary" align="center">
            <b-card-text>
              <div v-for="(value, name) in totalGroupesExternes" :key="name"> 
                    <span v-if="value != 0"> {{ value }} x {{ name }} Kw</span>
                </div>
            </b-card-text>
        </b-card>
        </b-card-group>
    </fieldset>
</template>

<script>
export default {
  name: 'PieceRecap',
  components: {
  },
  data: () => ({
      
  }),
  computed : {
    totalDiffuseurs () {
      return this.$store.getters.totalDiffuseurs
    },
    totalGroupesExternes () {
      return this.$store.getters.totalGroupesExternes
    },
    nbDiffuseurs () {
      return this.$store.getters.nbDiffuseurs
    },
    puissance () {
      return this.$store.getters.puissance
    },
  }
}
</script>

<style scoped>
legend {
  font-size: 16px;
}
</style>