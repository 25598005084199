import Api from '@/services/Api'
//import authHeader from './Auth-header';

function GetPacks (id_organisation) {
    return Api().get('catalogue/produits/' + id_organisation)
  }

function GetArticles(id_organisation) {
  return Api().get('catalogue/articles/' + id_organisation)
}

function GetMakers () {
  //return Api().post('organisation/create/', customer, { headers: authHeader()})
  return Promise.resolve(
        [{id:5327, name: "ATLANTIC"},	
        {id:5459	,name: "LA FRANCILIENNE"},
        {id:5461 ,name: "SOLUXTEC"}]
  )
}
export default {
    GetPacks, GetArticles, GetMakers
}