export default class Document {
    constructor(id, title, blockId, questionId, file) {
      this.id = id;
      this.title = title;
      this.blockId = blockId;
      this.questionId = questionId;
      this.uploadPercentage = 0;
      this.file  = file;
      this.url = null;
      this.status = 'ADDED'
    }
  }