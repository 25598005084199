import Api from '@/services/Api'
import authHeader from './Auth-header';

function CreateCustomer (customer) {
  console.log(customer)
  return Api().post('organisation/create/', customer, { headers: authHeader()})
  //return Promise.resolve({status : "OK", message_specifique:"", data:{id_fiche:8530}})
}

export default {
    CreateCustomer
}