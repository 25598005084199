import AidesService from '../../services/AideService'
import Aide from '../../models/aide'

const state =  {
    aides: [],
    selectedAides : [],
    oblige : null
  };
  
  const getters = {
    aides : state => { return state.aides},
    selectedAides : state => { return state.selectedAides},
    totalAides: state => {
      return state.aides.length >0 ? state.aides.reduce((a, c) => a +  parseFloat(c.prime), 0) : 0
    },
    totalPaye: state => {
      return state.aides.length >0 ? state.aides.filter(a=> { return !a.deductible }).reduce((a, c) => a +  parseFloat(c.prime), 0) : 0
    },
    totalDeduit: state => {
      return state.aides.length >0 ? state.aides.filter(a=> { return a.deductible }).reduce((a, c) => a + parseFloat(c.prime) , 0) : 0
    },
    oblige : state => {
      return state.oblige
    }
  }
  
  const mutations = {
    SET_AIDES(state, aides) {
      state.aides = aides
    },
    UPDATE_AIDE(state, aides) {
      state.aides = aides
    },
    RESET_AIDES(state) {
      return new Promise(() => { 
        state.aides.forEach(aide => {
          aide.prime = 0
          aide.categorie = "",
          aide.oblige = ""
        })
      })
    },
    UPDATE_SELECTED_AIDES(state, aides) {
      state.selectedAides = aides;
    },
    SET_OBLIGE(state, oblige) {
      if(oblige == null) {
        oblige = {"id_organisation":"8962","raison_sociale":"EDF Particuiler","fk_activite_organisation":"16","nom_contact":"","prenom_contact":null,"code_postal":"","ville":"","fk_pays":"1","zone":"","email":null,"date_creation":"11/04/2021 20:39","libelle_activite_organisation":"Pollueur - Obligé","libelle_pays":"France"} // EDF Particulier
      }
      state.oblige = oblige
    }
  }
  
  const actions = {
    GetProgrammeAide( context , typeDossier) {
      AidesService.GetProgrammeAide(typeDossier)
        .then(response => {
  
          var aides =  []
          var selectedAides = []
          if(response.data.statut == "OK") {
            aides = response.data.liste.map(aide => { 
              return new Aide(
                aide.id_programme_aide, 
                aide.Codification, 
                aide.fk_modele_grille, 
                aide.titre, 
                0, 
                '', 
                false, //aide.bool_regle_client_defaut == "1", 
                aide.list_organisation.map(o=> { return { fk_organisation:o.fk_organisation, is_defaut_grille:o.is_defaut_grille, raison_sociale: o.raison_sociale}}))
            })
            selectedAides = aides.filter(a => { return a.grille == a.grille })
          }
        
          context.commit('SET_AIDES', aides)
          context.commit("UPDATE_SELECTED_AIDES", selectedAides);
        })
        .catch(error => {
          console.log(error)
        })
    },
    GetPrimeValue( context , data) {

      var aide = state.aides.find(a => { return a.id == data.id_programme_aide})
      if(aide !== undefined) {
         aide.prime = 0
         aide.categorie = "",
         aide.oblige = ""
      }

      return AidesService.GetPrimeValue(data)
        .then(response => {
  
          if(response.data.statut == "OK") {
             var aide = state.aides.find(a => { return a.id == data.id_programme_aide})
             if(aide !== undefined) {
                var montant = response.data.montant
                aide.prime += Number(montant)
                aide.categorie = aide.categorie != "" && aide.categorie != "Non éligible" 
                ? aide.categorie : response.data.categorie
                aide.oblige = {fk_organisation : data.fk_organisation, raison_sociale : data.raison_sociale }
             }
          }
          console.log(`update aide ${data.id_programme_aide}`)
          context.commit('UPDATE_AIDE', state.aides)
        })
        .catch(error => {
          console.log(error)
        })
    },
    ResetAides(context) {
      context.commit('RESET_AIDES')
    },
    MajSelectedAide(context, aides) {
      context.commit("UPDATE_SELECTED_AIDES", aides);
    },
    MajOblige(context, oblige) {
      context.commit("SET_OBLIGE", oblige);
    },
    
  }
  
  export default {
      state, getters, mutations, actions
  }