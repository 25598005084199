export default class Articles {
    constructor(id, ref, designation, tva, quantite, prixHT, modifiable, fabricantId, fabricantLibelle) {
      this.id = Number(id);
      this.ref = ref;
      this.designation = designation;
      this.tva = Number(tva) / 100;
      this.quantite = quantite == 0 ? 1 : quantite
      this.prixHT = quantite * Number(prixHT);
      this.nouveauPrixHT = quantite * Number(prixHT);
      this.modifiable = modifiable;
      this.prixTTC = this.prixHT * (1 + this.tva);
      this.nouveauPrixTTC = this.prixHT * (1 + this.tva);
      this.fabricantId = fabricantId
      this.fabricantLibelle = fabricantLibelle
    }
  }