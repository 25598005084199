<template>
  <div>
      <b-form-row>
        <b-col md="12" >
          <b-alert :show="message !=''" variant="danger" class="text-center">{{message}}</b-alert>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col md="12" class="mt-5">
           <b-button block variant="info" v-if="showCreateButton" @click="creerDossier">{{labelButton}}</b-button>
        </b-col>
      </b-form-row>
      <div v-show="createGlobal">
        <b-form-row class="mt-2" v-if="this.idClient == '0'">
          <b-col md="5" >
            <span :class="customerCreation ? 'font-weight-bold' : 'font-weight-normal'">Creation du client</span>
          </b-col>
          <b-col md="2">
            <b-icon v-show="customerCreation" icon="three-dots" animation="cylon" font-scale="1"></b-icon>
            <b-icon v-show="customerCreated" icon="check-circle-fill" variant="success" font-scale="1"></b-icon>
          </b-col>
        </b-form-row>
        <b-form-row class="mt-2" v-if="this.idDossier == '0'">
          <b-col md="5" >
            <span :class="contractCreation ? 'font-weight-bold' : 'font-weight-normal'">Creation du dossier</span>
          </b-col>
          <b-col md="2">
            <b-icon v-show="contractCreation" icon="three-dots" animation="cylon" font-scale="1"></b-icon>
            <b-icon v-show="contractCreated" icon="check-circle-fill" variant="success" font-scale="1"></b-icon>
          </b-col>
        </b-form-row>
        <b-form-row class="mt-2" v-if="itemRequired">
          <b-col md="5">
            <span :class="orderCreation ? 'font-weight-bold' : 'font-weight-normal'">Creation des articles</span>
          </b-col>
          <b-col md="2">
            <b-icon v-show="orderCreation" icon="three-dots" animation="cylon" font-scale="1"></b-icon>
            <b-icon v-show="orderCreated" icon="check-circle-fill" variant="success" font-scale="1"></b-icon>
          </b-col>
        </b-form-row> 
        <b-form-row class="mt-2" v-if="documents.length > 0">
          <b-col md="5">
            <span :class="photoCreation ? 'font-weight-bold' : 'font-weight-normal'">Envoie des documents au serveur</span>
          </b-col>
        </b-form-row>
         <b-form-row class="mt-2" v-if="documents.length > 0">
          <b-col md="12">
            <b-list-group>
              <b-list-group-item v-for="doc in documents" :key="doc.id" class="d-flex justify-content-between align-items-center">
                <div class="d-flex w-100 justify-content-between">
                {{doc.title}} : {{doc.file.name}} 
                <progress v-show="doc.uploadPercentage != 100" max="100" :value.prop="doc.uploadPercentage"></progress>
                <b-icon v-show="doc.uploadPercentage == 100" icon="check-circle-fill" variant="success" font-scale="1"></b-icon>
                <b-icon v-show="doc.status == 'ERROR'" icon="x-circle" variant="danger" font-scale="1"></b-icon>
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-form-row> 
        <b-form-row class="mt-2">
          <b-col md="5">
            <span :class="vtCreation ? 'font-weight-bold' : 'font-weight-normal'">Creation de la visite technique</span>
          </b-col>
          <b-col md="2">
            <b-icon v-show="vtCreation" icon="three-dots" animation="cylon" font-scale="1"></b-icon>
            <b-icon v-show="vtCreated" icon="check-circle-fill" variant="success" font-scale="1"></b-icon>
          </b-col>
        </b-form-row>     
        <b-form-row class="mt-2">
          <b-col md="5">
            <span :class="aidesCreation ? 'font-weight-bold' : 'font-weight-normal'">Activation des aides</span>
          </b-col>
          <b-col md="2">
            <b-icon v-show="aidesCreation" icon="three-dots" animation="cylon" font-scale="1"></b-icon>
            <b-icon v-show="aidesCreated" icon="check-circle-fill" variant="success" font-scale="1"></b-icon>
          </b-col>
        </b-form-row>  
        <b-form-row class="mt-2">
          <b-col md="5">
            <span :class="financementCreation ? 'font-weight-bold' : 'font-weight-normal'">Financement</span>
          </b-col>
          <b-col md="2">
            <b-icon v-show="financementCreation" icon="three-dots" animation="cylon" font-scale="1"></b-icon>
            <b-icon v-show="financementCreated" icon="check-circle-fill" variant="success" font-scale="1"></b-icon>
          </b-col>
        </b-form-row> 
      </div>
  </div>
</template>

<script>
import AideService from '../services/AideService'
import ClientService from '../services/ClientService'
import DossierService from '../services/DossierService'
import FinancementService from '../services/FinancementService'
//import FacturationService from '../services/FacturationService'

export default {
  name: 'CreationDossier',
  components: {
  },
  props: ["blocs","itemRequired"],
  data: () => ({
    show : false,
    message : '',
    createGlobal : false,
    customerCreation : false,
    contractCreation : false,
    orderCreation : false,
    customerCreated : false,
    contractCreated : false,
    orderCreated : false,
    vtCreation : false,
    vtCreated : false,
    photoCreation : false,
    photoCreated : false,
    aidesCreation : false,
    aidesCreated : false,
    financementCreation : false,
    financementCreated : false
  }),
  mounted() {
    
  },
  computed: {
    informations () {
      return this.$store.getters.client
    },
    showCreateButton() {
      return this.$store.getters.selectedPacks.length > 0 || !this.itemRequired
    },
    labelButton() {
      return this.idClient != "0" ? "Actualisation du dossier d'affaire" : "Creation du dossier d'affaire"
    },
    id_user_membre() {
      return this.$route.params && this.$route.params.id_user_membre || this.$store.getters.user.id_user_membre
    },
    selectedPacks() {
      return this.$store.getters.selectedPacks
    },
    documents() {
      return this.$store.getters.documents
    },
    selectedAides() {
      return this.$store.getters.selectedAides
    },
    financement() {
      return this.$store.getters.financement
    },
    totalTTC () {
      return this.$store.getters.totalTTC
    },
    idDossier() {
      return this.$route.params && this.$route.params.id_dossier || 0
    },
    idClient() {
      return this.$route.params && this.$route.params.id_client || 0
    },
    oblige() {
      return this.$store.getters.oblige
    }
  },
  watch: {
    informations() {
      this.show = false;
    }
  },
  methods: {
    newCustomer(numTransaction) {
      return {
        code_app : "APP_COMMERCIALE",
        numTransaction : numTransaction,
        id_user_membre : this.id_user_membre,
        typeop : 1,
        statutOrga : 2,
        id_origine_affaire_ecommerce : 500,
        id_secteur_organisation : 0,
        civilite : this.informations.civilite,
        nom : this.informations.nom,
        prenom : this.informations.prenom,
        adresse : this.informations.adresse,
        codePostal : this.informations.codePostal,
        ville : this.informations.ville,
        id_pays : 1,
        mobile: this.informations.mobile,
        telephone: this.informations.telephone,
        email : this.informations.email,
        zone : this.informations.zoneGeo,
        //Conjoint
        liv_civilite : this.informations.civilite_conjoint,
        liv_raison_sociale : "",
        liv_adresse : this.informations.adresse,
        liv_code_postal : this.informations.codePostal,
        liv_ville : this.informations.ville,
        liv_fk_pays : 1,
        liv_contact : this.informations.nom_conjoint,
        liv_contact_prenom : this.informations.prenom_conjoint,
        liv_num_tel_1 : this.informations.mobile,
        liv_num_tel_2 : this.informations.telephone,
        liv_email : this.informations.email
      }
    },
    newDossier(numTransaction, titre_dossier, id_organisation_client) {

      let categorie_revenu = "TRES_MODESTES"

      switch (this.informations.id_categorieMPR) {
        case 1:
          categorie_revenu = "TRES_MODESTES"
          break;
        case 2:
          categorie_revenu = "MODESTES"
          break;
        case 3:
          categorie_revenu = "INTERMEDIAIRES"
          break;
        case 4:
          categorie_revenu = "ELEVES"
          break;
        default:
          break;
      } 

      return {
            code_app : "APP_COMMERCIALE",
            numTransaction : numTransaction,
            id_user_membre : this.id_user_membre,
            id_organisation_client : id_organisation_client,
            id_type_dossier : this.informations.typeDossier,
            id_regie : this.informations.idRegie,
            titre_dossier : titre_dossier,
            bool_qui_facturer : 0,
            id_moe : this.oblige.id_organisation, // envoyer l'obligé
            jsonORGAString : "",
            bloc_dossier_num_1 : "0",
            bloc_dossier_num_2 : this.totalTTC,
            revenu_fiscal_1: this.informations.revenu1, 
            revenu_fiscal_2: this.informations.revenu2, 
            revenu_fiscal_3: this.informations.revenu3, 
            nombre_personne: this.informations.nbPersonnes1 + this.informations.nbPersonnes2 + this.informations.nbPersonnes3, 
            categorie_revenu: categorie_revenu
          }
    },
    newArticles(numTransaction, idDossier, articles) {
      return {
            code_app : "APP_COMMERCIALE",
            numTransaction : numTransaction,
            id_user_membre : this.id_user_membre,
            idDossier : idDossier,
            articles : articles
          }
    }, 
    newVT(numTransaction, idDossier) {
      return {
          code_app : "APP_COMMERCIALE",
          numTransaction : numTransaction,
          id_user_membre : this.id_user_membre,
          module: "DOSSIER_AFFAIRE",
          id_model_VT : 2,
          idOrganisation : 0,
          idDossier : idDossier,
          modeVT : "VIEW",
          batiment : "",
          detail_emplacement : "",
          jsonVtString : JSON.stringify(this.blocs)
        }
    },
    newFinancement(numTransaction, idDossier) {
      return {
        code_app : "APP_COMMERCIALE",
        numTransaction : numTransaction,
        id_user_membre : this.id_user_membre,
        id_dossier : idDossier,
        id_orga_financier : this.financement.id_orga_financier,
        montant_acompte : this.financement.acompteLivraison,
        montant_acompte_installation : this.financement.acompteInstallation,
        montant_acompte_retractation : this.financement.acompteRetractation,
        montant_credit : this.financement.montantCredit,
        nbre_mois_mensualite: this.financement.nbEcheance,
        taux : this.financement.taux,
        taux_global : this.financement.taeg,
        report : this.financement.report == 'Oui' ? 1 : 0, 
        duree_mois_report : this.financement.dureeReport,
        montant_echeance_hors_assurance : this.financement.montantEcheanceHorsAssurance,
        montant_echeance_avec_assurance : this.financement.montantEcheanceAvecAssurance,
        cout_total_hors_assurance : this.financement.coutTotalHorsAssurance,
        cout_total_avec_assurance : this.financement.coutTotalAvecAssurance,
        commentaire_financement : this.financement.observations,
        option_installation : this.financement.optionInstallation,
        emprunteur : this.financement.emprunteur,
        coemprunteur : this.financement.coemprunteur
      }
    },
    newFacturation(numTransaction, idDossier) {
      return  {
          code_app : "APP_COMMERCIALE",
          numTransaction : numTransaction,
          id_user_membre : this.id_user_membre,
          id_piece_facturation : 3,
          id_dossier : idDossier
        }
    },
    handleProgress(doc) {
      return {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: function( progressEvent ) {
          doc.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
        }.bind(this)
  }
    },
    async creerDossier() {

      try {
        this.message = ""
        this.show = false;
        if(this.informations.nom == '' || this.informations.prenom =='' || this.informations.adresse == '' || this.informations.codePostal == null
        && this.informations.ville == '' || this.informations.mobile == null) {
          this.show = true
          this.message = 'Merci de remplir les informations clients'
          return
        }
        this.createGlobal=true
        this.customerCreation = true
        let idDossier = 0
        let VT_Id = 0
        var numTransaction = Math.floor(Math.random() * 100000) + 1;
        var customer = this.newCustomer(numTransaction)
        let idFiche = 0
        let result = null

        if(this.idClient == "0") 
        {
          result = await ClientService.CreateCustomer(customer)
          // Creation du dossier
          if(result.status =="NOK") {
            console.log(result.message_specifique)
            if(result.error_number == 1062) {
              // le client existe deja
              if(result.data.id_fiche == 0) {
                // message : 
                this.message = "Impossible d'identifier le client. Merci de contacter le support"
                return
              }            
            }
            else {
              this.message = result.message_specifique
              return
            }
          }
          idFiche = result.data.id_fiche 
        }
        else {
          console.log(this.informations.id)
          idFiche = this.informations.id
        }

        this.customerCreation = false
        this.customerCreated = true
        this.contractCreation = true      

        if(this.idDossier == '0') {
          let titre_dossier = this.informations.typeDossier == 10 ? `AMO - ${this.blocs[6].questions[0].value.join(' + ')} ` : 
            `DOSSIER VAD - ${this.selectedPacks.map(t=> (t.distributeur == null || t.distributeur === "") ? t.ref : t.distributeur).join(' + ')}`
          
          if(this.$route.params) {
            //titre_dossier = `${this.blocs[10].questions[0].value.join(' + ')}`.replace('PV', `${this.blocs[1].questions[8].value} x ${this.blocs[1].questions[9].value}`)
            titre_dossier = this.selectedPacks.map(t=> (t.distributeur == null || t.distributeur === "") ? t.ref : t.distributeur).join(' + ')
          }
          
          var dossier = this.newDossier(numTransaction, titre_dossier, idFiche);
    
          result = await DossierService.CreateDossier(dossier)
            if(result.status =="NOK") {
              this.message = result.message_specifique
              return
            }
            idDossier = result.data.id_fiche;
        }
        else {
          idDossier = this.idDossier
        }

        //Creation des articles
        this.contractCreation = false
        this.contractCreated = true
        this.orderCreation = true      
          
        var articles = [];
        this.selectedPacks.forEach(x=> {
          x.articles.forEach(a=> {
            articles.push({id_article : a.id, prixVenteHT: a.prixHT, qte:1})
          })
        })

        
          var dossierArticles = this.newArticles(numTransaction, idDossier, articles) 

          result = await DossierService.CreateArticles(dossierArticles)
          if(result.status =="NOK") {
              this.message = result.message_specifique
              return
          }
          this.orderCreation = false
          this.orderCreated = true
          this.vtCreation = true
  
          result = await DossierService.CreateVT(this.newVT(numTransaction,idDossier ))
          if(result.status =="NOK") {
            this.message = result.message_specifique
          }

          
          this.photoCreation = true
          // envoi des photos

          VT_Id = result.data.id_fiche
          var _this = this
          let requests = []
          for (let index = 0; index < _this.documents.length; index++) {
            
            let doc = {
              id : _this.documents[index].id,
              code_app : "APP_COMMERCIALE",
              numTransaction : numTransaction,
              id_user_membre : _this.id_user_membre,
              module: "DOSSIER_AFFAIRE",
              titre_doc : "",
              description_doc : _this.documents[index].title,
              id_fiche : 0,
              VT_Id : VT_Id,
              VT_JSchedule_api : 0,
              VT_blockId : _this.documents[index].blockId,
              VT_blocklIteration : 0,
              VT_comment: "",
              VT_itemId : _this.documents[index].questionId,
              file: _this.documents[index].file
            }
          
            
            requests.push(
              DossierService.SendDocument(doc)
              .then((result) => {
    
                if(result.status =="NOK") {
                  console.log(result.message_specifique)
                }
                else {
                  doc.status = 'SENT'
                  _this.blocs[result.data.VT_blockId].questions[result.data.VT_itemId].value = result.data.nom_docWithExt
                  console.log(doc)
                }

              }).catch((error) => {
                
                console.log(error)
                doc.status = 'ERROR'
              })
            )
          }
          await Promise.all(requests.map(p => p.catch(e => e)))

          this.photoCreated = true

          var aides = this.selectedAides.filter(a => a.prime > 0).map(a => { 
            return { 
              id_programme_aide: a.id, 
              fk_organisation: a.oblige.fk_organisation, 
              revenu_fiscal_1: this.informations.revenu1, 
              revenu_fiscal_2: this.informations.revenu2, 
              revenu_fiscal_3: this.informations.revenu3, 
              nombre_personne: this.informations.nbPersonnes1 + this.informations.nbPersonnes2 + this.informations.nbPersonnes3, 
              montant_aide: a.prime, 
              bool_regle_client: a.deductible ? 0 : 1} 
          })

          result = await DossierService.UpdateVT({
            code_app : "APP_COMMERCIALE",
            numTransaction : numTransaction,
            id_user_membre : this.id_user_membre,
            module: "DOSSIER_AFFAIRE",
            id_VT : VT_Id,
            batiment : "",
            detail_emplacement : "",
            jsonVtString : JSON.stringify({informations : this.informations, blocs : this.blocs, financement: this.financement, aides:aides})
          })
          if(result.status =="NOK") {
              console.log(result.message_specifique)
          }

          this.vtCreation = false
          this.vtCreated = true
          this.aidesCreation = true

          var data = {
                  code_app : "APP_COMMERCIALE",
                  numTransaction : numTransaction,
                  id_user_membre : this.id_user_membre,
                  idDossier : idDossier,
                  programmes_aides: aides
          }
       
          result = await AideService.ActivationAide(data)
          if(result.status =="NOK") {
              console.log(result.message_specifique)
          }
          this.aidesCreation = false
          this.aidesCreated = true
          this.financementCreation = true 

          result = await FinancementService.CreateFinancement(this.newFinancement(numTransaction, idDossier))
          if(result.status =="NOK") {
              console.log(result.message_specifique)
          }
          this.financementCreation = false 
          this.financementCreated = true

          // //Creation de la pièce de facturation
          // result = await FacturationService.FacturationService(this.newFacturation(numTransaction, idDossier))
          // if(result.status =="NOK") {
          //     console.log(result.message_specifique)
          // }

          this.$emit("onCreated");
        
        } catch (error) {
          console.log(error)
          this.message = error.response.data.message_specifique || 'Une erreur est survenue. Merci de contacter le support'
        }
      }      
    }
  }

</script>

<style>

</style>