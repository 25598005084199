export default class ModelVT {
  constructor() {
    this.blocs = [
    {
      id : 1,
      title : "Ma maison",
      questions: [
        {
          id:0,
          title :"Quelle est l'année de la maison ?",
          comment : "anneeMaison",
          question_type: "inputText",
          value_type : "number",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Moins de 2 ans', text: 'Moins de 2 ans' },
            { value: 'Entre 2 et 15 ans', text: 'Entre 2 et 15 ans'},
            { value: 'Plus de 15 ans', text: 'Plus de 15 ans'}
          ],
          value: null,
        },
        {
          id:1,
          title :"Quel est la superficie de la maison ?",
          comment : "superficie",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:2,
          title :"La maison est :",
          comment : "dispositionMaison",
          question_type: "radio",
          value_type : "radio",
          list_values : [{ text: 'De plein pied', value: 'De plein pied' },{ text: 'A étage', value: 'A étage' }],
          value: null,
          sub_questions : [
            {
              id:100,
              title :"Combien d'étage ?",
              comment : "numEtage",
              question_type: "inputText",
              value_type : "number",
              selected_parent_value : "etage",
              list_values : null,
              value: null,
            }
          ]
        },
        {
          id:3,
          title :"Vos combles sont-elles aménagées ?",
          comment : "comblesAmenagees",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Oui', text: 'Oui' },
            { value: 'Non', text: 'Non'}
          ],
          value: null
        },
        {
          id:4,
          title :"Nombre de niveaux chauffés",
          comment : "niveauChauffes",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 1, text: '1' },
            { value: 2, text: '2'},
            { value: 3, text: '3'}
          ],
          value: null
        },
        {
          id:5,
          title :"Type de plancher haut",
          comment : "plancherHaut",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Combles perdu', text: 'Combles perdus' },
            { value: 'Toiture sous rampants', text: 'Toiture sous rampants'},
            { value: 'Toit terrasse', text: 'Toit terrasse'},
            { value: 'Autre logement', text: 'Autre logement'}
          ],
          value: null
        },
        {
          id:6,
          title :"Type de plancher bas",
          comment : "plancherBas",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Terre plein', text: 'Terre plein' },
            { value: 'Vide sanitaire', text: 'Vide sanitaire'},
            { value: 'Local non chauffé (LNC)', text: 'Local non chauffé (LNC)'},
            { value: 'Autre logement', text: 'Autre logement'},
            { value: 'Bureau ou commerce', text: 'Bureau ou commerce'},
            { value: 'Extérieur', text: 'Extérieur'}
          ],
          value: null
        },
        {
          id:7,
          title :"Forme du logement",
          comment : "formeLogement",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Compact', text: 'Compact' },
            { value: 'Allongé', text: 'Allongé' },
            { value: 'En L', text: 'En L'},
            { value: 'En L allongé', text: 'En L allongé'},
            { value: 'En U', text: 'En U'}
          ],
          value: null
        },
        {
          id:8,
          title :"Oriantation de la façade principale",
          comment : "orientationFacade",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Nord', text: 'Nord' },
            { value: 'Nord-Est', text: 'Nord-Est'},
            { value: 'Est', text: 'Est'},
            { value: 'Nord-Ouest', text: 'Nord-Ouest'},
            { value: 'Sud', text: 'Sud'},
            { value: 'Sud-Est', text: 'Sud-Est'},
            { value: 'Ouest', text: 'Ouest'},
            { value: 'Sud-Ouest', text: 'Sud-Ouest'}
          ],
          value: null
        },
        {
          id:9,
          title :"Nord (ou N-O)",
          comment : "orientationMurNord",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Aucune', text: 'Aucune' },
            { value: 'Autre logement', text: 'Autre logement'},
            { value: 'Local non chauffé', text: 'Local non chauffé'},
            { value: 'Bureau ou commerce', text: 'Bureau ou commerce'}
          ],
          value: null
        },
        {
          id:10,
          title :"Est (ou N-E)",
          comment : "orientationMurEst",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Aucune', text: 'Aucune' },
            { value: 'Autre logement', text: 'Autre logement'},
            { value: 'Local non chauffé', text: 'Local non chauffé'},
            { value: 'Bureau ou commerce', text: 'Bureau ou commerce'}
          ],
          value: null
        },
        {
          id:11,
          title :"Ouest (ou S-O)",
          comment : "orientationMurOuest",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Aucune', text: 'Aucune' },
            { value: 'Autre logement', text: 'Autre logement'},
            { value: 'Local non chauffé', text: 'Local non chauffé'},
            { value: 'Bureau ou commerce', text: 'Bureau ou commerce'}
          ],
          value: null
        },
        {
          id:12,
          title :"Sud (ou S-E)",
          comment : "orientationMurSud",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Aucune', text: 'Aucune' },
            { value: 'Autre logement', text: 'Autre logement'},
            { value: 'Local non chauffé', text: 'Local non chauffé'},
            { value: 'Bureau ou commerce', text: 'Bureau ou commerce'}
          ],
          value: null
        },
        {
          id:13,
          title :"Nord (ou N-O)",
          comment : "masqueNord",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Aucun', text: 'Aucun' },
            { value: 'Arbre (feuilles caduques)', text: 'Arbre (feuilles caduques)'},
            { value: 'Arbre (feuillage persistant)', text: 'Arbre (feuillage persistant)'},
            { value: 'Construction', text: 'Construction'},
            { value: 'Relief', text: 'Relief'}
          ],
          value: null
        },
        {
          id:14,
          title :"Est (ou N-E)",
          comment : "masqueEst",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Aucun', text: 'Aucun' },
            { value: 'Arbre (feuilles caduques)', text: 'Arbre (feuilles caduques)'},
            { value: 'Arbre (feuillage persistant)', text: 'Arbre (feuillage persistant)'},
            { value: 'Construction', text: 'Construction'},
            { value: 'Relief', text: 'Relief'}
          ],
          value: null
        },
        {
          id:15,
          title :"Ouest (ou S-O)",
          comment : "masqueOuest",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Aucun', text: 'Aucun' },
            { value: 'Arbre (feuilles caduques)', text: 'Arbre (feuilles caduques)'},
            { value: 'Arbre (feuillage persistant)', text: 'Arbre (feuillage persistant)'},
            { value: 'Construction', text: 'Construction'},
            { value: 'Relief', text: 'Relief'}
          ],
          value: null
        },
        {
          id:16,
          title :"Sud (ou S-E)",
          comment : "masqueSud",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Aucun', text: 'Aucun' },
            { value: 'Arbre (feuilles caduques)', text: 'Arbre (feuilles caduques)'},
            { value: 'Arbre (feuillage persistant)', text: 'Arbre (feuillage persistant)'},
            { value: 'Construction', text: 'Construction'},
            { value: 'Relief', text: 'Relief'}
          ],
          value: null
        },
        {
          id:17,
          title :"Fenêtres",
          comment : "fenetre",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:18,
          title :"Baies vitrées",
          comment : "baie",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:19,
          title :"Portes-fenêtres",
          comment : "porteFenetre",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:20,
          title :"Fenêtres de toit",
          comment : "fenetreToit",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:21,
          title :"Portes",
          comment : "porte",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:22,
          title :"Materiau du gros oeuvre",
          comment : "materiau",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Pierre', text: 'Pierre' },
            { value: 'Brique', text: 'Brique'},
            { value: 'Béton', text: 'Béton'},
            { value: 'Béton cellulaire', text: 'Béton cellulaire'},
            { value: 'Terre', text: 'Terre'},
            { value: 'Bois', text: 'Bois'},
            { value: 'Je ne sais pas', text: 'Je ne sais pas'},
            { value: 'Autre', text: 'Autre'}
          ],
          value: null,
          sub_questions : [
            {
              id:104,
              title :"Précisez",
              comment : "autreMateriau",
              question_type: "inputText",
              value_type : "text",
              selected_parent_value : "Autre",
              list_values : null,
              value: null,
            }
          ]
        },
        {
          id:23,
          title :"Isolation des murs extérieurs",
          comment : "iso",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Aucune', text: 'Aucune' },
            { value: 'Ancienne', text: 'Ancienne'},
            { value: 'Récente (< 10 ans)', text: 'Récente (< 10 ans)'},
            { value: 'Je ne sais pas', text: 'Je ne sais pas'}
          ],
          value: null
        },
        {
          id:24,
          title :"Ouvertures",
          comment : "ouvertures",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Bois', text: 'Bois' },
            { value: 'PVC', text: 'PVC'},
            { value: 'Alu', text: 'Alu'}
          ],
          value: null
        },
        {
          id:25,
          title :"Vitrages",
          comment : "vitrages",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Simple', text: 'Simple' },
            { value: 'Double Ancien (< 2010)', text: 'Double Ancien (< 2010)'},
            { value: 'Double Récent (> 2010)', text: 'Double Récent (> 2010)'}
          ],
          value: null
        },
        {
          id:26,
          title :"Occupation du logement",
          comment : "occupation",
          question_type: "select",
          value_type : "select",
          list_values : [{ text: 'Depuis moins d\'un an', value: 'Depuis moins d\'un an' },{ text: 'Depuis plus d\'un an', value: 'Depuis plus d\'un an' }],
          value: null
        },
        {
          id:27,
          title :"Habitation du logement pendant les travaux",
          comment : "habitationTravaux",
          question_type: "select",
          value_type : "select",
          list_values : [{ text: 'Oui', value: 'oui' },{ text: 'Non', value: 'non' },{ text: 'Ne sais pas', value: 'Ne sais pas' }],
          value: null
        },
        {
          id:28,
          title :"Niveau de confort été",
          comment : "confortEte",
          question_type: "select",
          value_type : "select",
          list_values : [{ text: 'Mauvais', value: 'Mauvais' },{ text: 'Correct', value: 'Correct' },{ text: 'Bon', value: 'Bon' }],
          value: null
        },
        {
          id:29,
          title :"Niveau de confort hiver",
          comment : "confortHiver",
          question_type: "select",
          value_type : "select",
          list_values : [{ text: 'Mauvais', value: 'Mauvais' },{ text: 'Correct', value: 'Correct' },{ text: 'Bon', value: 'Bon' }],
          value: null
        },
        {
          id:30,
          title :"Isolation plancher haut",
          comment : "isoPlancherHaut",
          question_type: "select",
          value_type : "select",
          list_values : [{ text: 'Oui', value: 'Oui' },{ text: 'Non', value: 'Non' },{ text: 'Ne sais pas', value: 'Ne sais pas' }],
          value: null
        },
        {
          id:31,
          title :"Epaisseur plancher haut",
          comment : "epaisseurPlancherHaut",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:32,
          title :"Année de réalisation plancher haut",
          comment : "anneeIsoPlancherHaut",
          question_type: "inputText",
          value_type : "text",
          list_values : null,
          value: null
        },
        {
          id:33,
          title :"Isolation plancher bas",
          comment : "isoPlancherBas",
          question_type: "select",
          value_type : "select",
          list_values : [{ text: 'Oui', value: 'Oui' },{ text: 'Non', value: 'Non' },{ text: 'Ne sais pas', value: 'Ne sais pas' }],
          value: null
        },
        {
          id:34,
          title :"Epaisseur plancher bas",
          comment : "epaisseurPlancherBas",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:35,
          title :"Année de réalisation plancher bas",
          comment : "anneeIsoPlancherBas",
          question_type: "inputText",
          value_type : "text",
          list_values : null,
          value: null
        },
        {
          id:36,
          title :"Contexte",
          comment : "",
          question_type: "section",
          value_type : "text",
          list_values : null,
          value: null
        },
        {
          id:37,
          title :"Architecture",
          comment : "",
          question_type: "section",
          value_type : "text",
          list_values : null,
          value: null
        },
        {
          id:38,
          title :"Murs extérieures",
          comment : "",
          question_type: "section",
          value_type : "text",
          list_values : null,
          value: null
        },
        {
          id:39,
          title :"Mitoyenneté des murs",
          comment : "",
          question_type: "section",
          value_type : "text",
          list_values : null,
          value: null
        },
        {
          id:40,
          title :"Masques solaires",
          comment : "",
          question_type: "section",
          value_type : "text",
          list_values : null,
          value: null
        },
        {
          id:41,
          title :"Nombre d'ouvertures",
          comment : "",
          question_type: "section",
          value_type : "text",
          list_values : null,
          value: null
        },
        {
          id:42,
          title :"Si vous ne connaissez pas l'année",
          comment : "precisezAnneeMaison",
          question_type: "select",
          value_type : "select",
          selected_parent_value : "Autre",
          list_values : [
              { value: null, text: 'Choisir ...' },
              { value: 'Avant 1900', text: 'Avant 1900' },
              { value: 'Entre 1900 et 1949', text: 'Entre 1900 et 1949'},
              { value: 'Entre 1945 et 1974', text: 'Entre 1945 et 1974'},
              { value: 'Entre 1974 et 1981', text: 'Entre 1974 et 1981'},
              { value: 'Entre 1982 et 1989', text: 'Entre 1982 et 1989'},
              { value: 'Entre 1990 et 1999', text: 'Entre 1990 et 1999'},
              { value: 'Entre 2000 et 2005', text: 'Entre 2000 et 2005'},
              { value: 'Entre 2006 et 2012', text: 'Entre 2006 et 2012'},
              { value: 'Apres 2012', text: 'Apres 2012'}
        ],
          value: null,
        },
        {
          id:43,
          title :"Note de dimensionnement",
          comment : "note",
          question_type: "section",
          value_type : "text",
          list_values : null,
          value: null,
        },
        {
          id:44,
          title :"Coéficient de déperdition de la maison",
          comment : "coefdeperdition",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { text: 'Maison bioclimatique (RE 2020)' , value: '0.6' },
            { text: 'Maison RT 2012' , value: '0.7'},
            { text: 'Maison RT 2005' , value: '0.75'},
            { text: 'Maison RT 2000' , value: '0.85'},
            { text: 'Maison excellente isolation' , value: '1.1'},
            { text: 'Maison avec mauvaise isolation' , value: '1.3'},
            { text: 'Maison avec très mauvaise isolation' , value: '1.6'},
          ]
        },
        {
          id:45,
          title :"Volume de la maison",
          comment : "volumeMaison",
          question_type: "inputText",
          value_type : "number",
          list_values : null
        },
        {
          id:46,
          title :"Altitude",
          comment : "altitude",
          question_type: "inputText",
          value_type : "number",
          list_values : null
        },
        {
          id:47,
          title :"P",
          comment : "P",
          question_type: "calcul",
          value_type : "number",
          list_values : null
        },
        {
            id:48,
            title :"Quel est votre type d'installation électrique ?",
            comment : "typeInstallationElectrique",
            question_type: "radio",
            value_type : "radio",
            list_values : [
              { value: 'Monophasé', text: 'Monophasé' },
              { value: 'Triphasé', text: 'Triphasé'},
            ],
            value: null
          },
          {
            id:49,
            title :"Quel est la puissance de votre compteur ?",
            comment : "puissanceCompteur",
            question_type: "select",
            value_type : "select",
            list_values : [
              { value: null, text: 'Choisir ...' },
              { value: '12 KVA', text: '12 KVA' },
              { value: '24 KVA', text: '24 KVA'},
              { value: '36 KVA', text: '36 KVA'},
              { value: 'Autre', text: 'Autre'}
            ],
            value: null,
            sub_questions : [
              {
                id:0,
                title :"Précisez",
                comment : "puissanceCompteur",
                question_type: "inputText",
                value_type : "number",
                selected_parent_value : "Autre",
                list_values : null,
                value: null
              }
            ]
          },
          {
            id:50,
            title :"Surface à chauffer",
            comment : "surf",
            question_type: "inputText",
            value_type : "number",
            list_values : null
          }
        ],
    },
    {
      id : 2,
      title : "Panneaux photovoltaïques",
      questions: [
        {
          id:0,
          title :"Où seront installés les panneaux photovoltaïques ?",
          comment : "lieuPPV",
          question_type: "radio",
          value_type : "radio",
          list_values : [{ text: 'Sur la maison', value: 'maison' },{ text: 'Sur une annexe', value: 'annexe' }],
          value: null,
          sub_questions : [
            {
              id:0,
              title :"Précisez",
              comment : "precisezAnnexe",
              question_type: "inputText",
              value_type : "text",
              selected_parent_value : "annexe",
              list_values : null,
              value: null,
            },
            {
              id:0,
              title :"Quelle est la distance entre l'annexe et la maison ?",
              comment : "distanceAnnexeMaison",
              question_type: "inputText",
              value_type : "number",
              selected_parent_value : "annexe",
              list_values : null,
              value: null,
            }
          ]
        },
        {
          id:1,
          title :"Pose des panneaux en :",
          comment : "posePPV",
          question_type: "radio",
          value_type : "radio",
          list_values : [{ text: 'Surimposition', value: 'Surimposition' },{ text: 'Intégration', value: 'Intégration' }],
          value: null,
        },
        {
          id:2,
          title :"Quel est la superficie de la toiture ?",
          comment : "superficieToit",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:3,
          title :"Quel est l’exposition de la maison ?",
          comment : "expoToit",
          question_type: "inputText",
          value_type : "text",
          list_values : null,
          value: null
        },
        {
          id:4,
          title :"Le toit est ?",
          comment : "inclinaisonToit",
          question_type: "radio",
          value_type : "radio",
          list_values : [{ text: 'Plat', value: 'Plat' },{ text: 'Incliné', value: 'Incliné' }],
          value: null,
          sub_questions : [
            {
              id:0,
              title :"Quelle est l'inclinaison du toit ?",
              comment : "inclinaison",
              question_type: "inputText",
              value_type : "number",
              selected_parent_value : "Incliné",
              list_values : null,
              value: null,
            }
          ]
        },
        {
          id:5,
          title :"Quel type de tuiles ?",
          comment : "typeTuiles",
          question_type: "select",
          value_type : "select",
          list_values : [{ text: 'Choisir', value: null },{ text: 'PVC', value: 'PVC' },{ text: 'Taule', value: 'Taule' },{ text: 'Autre', value: 'Autre' }],
          value: null,
          sub_questions : [
            {
              id:0,
              title :"Precisez",
              comment : "autreTuiles",
              question_type: "inputText",
              value_type : "number",
              selected_parent_value : "Autre",
              list_values : null,
              value: null,
            }
          ]
        },
        {
          id:6,
          title :"A quelle hauteur approximative se trouve la toiture ?",
          comment : "hauteurToit",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:7,
          title :"Electricité produite pour",
          comment : "hauteurToit",
          question_type: "radio",
          value_type : "radio",
          list_values : [
            { text: 'Autoconsommation / Injection directe', value: 'Autoconsommation / Injection directe' },
            { text: 'Revente EDF', value: 'Revente EDF' }
          ],
          value: null,
        },
        {
          id:8,
          title :"Nombre de panneaux",
          comment : "nbPanneaux",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:9,
          title :"Puissance des panneaux",
          comment : "puissancePanneaux",
          question_type: "select",
          value_type : "select",
          list_values : [
            { text: '375Wc', value: '375' },
            { text: '400Wc', value: '400' }
          ],
          value: 375
        },
        {
          id:10,
          title :"Installation à prévoir",
          comment : "",
          question_type: "section",
          value_type : "text",
          list_values : null,
          value: null
        },
        {
          id:11,
          title :"Batterie",
          comment : "batterie",
          question_type: "select",
          value_type : "select",
          list_values : [
            { text: 'Choisir ...', value: null },
            { text: 'Oui', value: 'Oui' },
            { text: 'Non', value: 'Non' }
          ],
          value: null
        }
        
      ],
    },
    {
      id : 3,
      title : "Mon chauffage",
      questions: [
        {
          id:0,
          title :"Comment êtes-vous chauffés ?",
          comment : "chauffage",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Electrique', text: 'Electrique' },
            { value: 'Combustible', text: 'Combustible'},
          ],
          value: null,
          sub_questions : [
            {
              id:0,
              title :"Type de chauffage électrique",
              comment : "typeChauffageElectrique",
              question_type: "select",
              value_type : "select",
              selected_parent_value : "Electrique",
              list_values : [
                { value: null, text: 'Choisir ...' },
                { value: 'Radiateurs électriques', text: 'Radiateurs électriques' },
                { value: 'PAC Air/Air', text: 'PAC Air/Air'},
              ],
              value: null,
            },
            {
              id:1,
              title :"Type de combustible",
              comment : "typeChauffageCombustible",
              question_type: "select",
              value_type : "select",
              selected_parent_value : "Combustible",
              list_values : [
                { value: null, text: 'Choisir ...' },
                { value: 'Chaudière au fioul', text: 'Chaudière au Fioul' },
                { value: 'Chaudière à Gaz', text: 'Chaudière à Gaz'},
                { value: 'Chaudière à Charbon', text: 'Chaudière à Charbon'},
                { value: 'Chaudière à Bois', text: 'Chaudière à Bois'},
                { value: 'Insert au bois', text: 'Insert au bois'},
              ],
              value: null,
            }
          ]
        },
        {
          id:1,
          title :"Comment êtes vous chauffés ?",
          comment : "centrale",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Chauffage central', text: 'Chauffage central' },
            { value: 'Convecteurs Electriques', text: 'Convecteurs Electriques' },
            { value: 'Radiateurs Electriques', text: 'Radiateurs Electriques'},
            { value: 'Insert au bois', text: 'Insert au bois'},
            { value: 'Poêle à Bois', text: 'Poêle à Bois'},
            { value: 'Insert à Granulés', text: 'Insert à Granulés'},
            { value: 'Poêle à Granulés', text: 'Poêle à Granulés'},
            { value: 'Pompe à Chaleur Air/Air', text: 'Pompe à Chaleur Air/Air'},
            { value: 'Autre', text: 'Autre'},
          ],
          value: null,
          sub_questions : [
            {
              id:0,
              title :"Energie",
              comment : "energie",
              question_type: "select",
              value_type : "select",
              selected_parent_value : "Chauffage central",
              list_values : [
                { value: null, text: 'Choisir ...' },
                { value: 'Fioul', text: 'Fioul' },
                { value: 'Gaz', text: 'Gaz'},
                { value: 'Bois', text: 'Bois'},
                { value: 'Electricité (PAC Air/Eau)', text: 'Electricité (PAC Air/Eau)'},
              ],
              value: null,
            },
            {
              id:1,
              title :"Type de chaudière",
              comment : "typeChaudiere",
              question_type: "select",
              value_type : "select",
              selected_parent_value : "Chauffage central",
              list_values : [
                { value: null, text: 'Choisir ...' },
                { value: 'Condensation', text: 'Condensation' },
                { value: 'Classique', text: 'Classique'},
                { value: 'Pompe à chaleur Air/Eau', text: 'Pompe à chaleur Air/Eau'},
              ],
              value: null,
            },
            {
              id:2,
              title :"Année d'installation",
              comment : "anneeChaudiere",
              question_type: "select",
              value_type : "select",
              selected_parent_value : "Chauffage central",
              list_values : [
                { value: null, text: 'Choisir ...' },
                { value: 'Avant 1988', text: 'Avant 1988' },
                { value: 'Entre 1988 et 2000', text: 'Entre 1988 et 2000'},
                { value: 'Entre 2000 et 2010', text: 'Entre 2000 et 2010'},
                { value: 'Après 2010', text: 'Après 2010'},
              ],
              value: null,
            },
            {
              id:3,
              title :"Dépose de la cuve",
              comment : "deposeCuve",
              question_type: "select",
              value_type : "select",
              selected_parent_value : "Chauffage central",
              list_values : [
                  { value: null, text: 'Choisir ...' },
                  { value: 'Oui', text: 'Oui' },
                  { value: 'Non', text: 'Non'}
              ],
              value: 'Non',
            }
          ]
        },
        {
          id:2,
          title :"Générateur de chaleur",
          comment : "generateur",
          question_type: "select",
          value_type : "select",
          list_values : [
                { value: null, text: 'Choisir ...' },
                { value: 'Convecteurs Electriques', text: 'Convecteurs Electriques' },
                { value: 'Radiateurs Electriques', text: 'Radiateurs Electriques'},
                { value: 'Insert au bois', text: 'Insert au bois'},
                { value: 'Poêle à Bois', text: 'Poêle à Bois'},
                { value: 'Insert à Granulés', text: 'Insert à Granulés'},
                { value: 'Poêle à Granulés', text: 'Poêle à Granulés'},
                { value: 'Pompe à Chaleur Air/Air', text: 'Pompe à Chaleur Air/Air'},
                { value: 'Autre', text: 'Autre'},
              ],
          value: null,
          sub_questions : [
            {
              id:0,
              title :"Precisez",
              comment : "precisezGenerateur",
              question_type: "inputText",
              value_type : "text",
              selected_parent_value : "Autre",
              list_values : null,
              value: null,
            }
          ]
        },
        {
          id:3,
          title :"Année d'installation générateur",
          comment : "anneeGenerateur",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Moins de 5 ans', text: 'Moins de 5 ans' },
            { value: 'Entre 5 et 15 ans', text: 'Entre 5 et 15 ans'},
            { value: 'Plus de 15 ans', text: 'Plus de 15 ans'}
          ],
          value: null,
        },
        {
          id:4,
          title :"Avez vous un thermostat d'ambiance (possibilité d'abaissement de température) ?",
          comment : "thermostat",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Oui', text: 'Oui' },
            { value: 'Non', text: 'Non'},
            { value: 'Ne sais pas', text: 'Ne sais pas'}
          ],
          value: null,
        },
        {
          id:5,
          title :"Générateur de chaleur",
          comment : "appoint",
          question_type: "select",
          value_type : "select",
          list_values : [
                { value: 'Convecteurs Electriques', text: 'Convecteurs Electriques' },
                { value: 'Radiateurs Electriques', text: 'Radiateurs Electriques'},
                { value: 'Insert au bois', text: 'Insert au bois'},
                { value: 'Poêle à Bois', text: 'Poêle à Bois'},
                { value: 'Insert à Granulés', text: 'Insert à Granulés'},
                { value: 'Poêle à Granulés', text: 'Poêle à Granulés'},
                { value: 'Pompe à Chaleur Air/Air', text: 'Pompe à Chaleur Air/Air'},
                { value: 'Autre', text: 'Autre'},
              ],
          value: [],
          sub_questions : [
            {
              id:0,
              title :"Precisez",
              comment : "precisezGenerateur",
              question_type: "inputText",
              value_type : "text",
              selected_parent_value : "Autre",
              list_values : null,
              value: null,
            }
          ]
        },
        {
          id:6,
          title :"Année d'installation chauffage",
          comment : "anneeAppoint",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Moins de 5 ans', text: 'Moins de 5 ans' },
            { value: 'Entre 5 et 15 ans', text: 'Entre 5 et 15 ans'},
            { value: 'Plus de 15 ans', text: 'Plus de 15 ans'}
          ],
          value: null,
        },
        {
          id:7,
          title :"Est-ce une chaudière à condensation ?",
          comment : "chaudiereCondensation",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Oui', text: 'Oui' },
            { value: 'Non', text: 'Non'}
          ],
          value: null,
        },
        {
          id:8,
          title :"Type de radiateurs ?",
          comment : "matiereRadiateur",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Acier', text: 'Acier' },
            { value: 'Fonte', text: 'Fonte'},
            { value: 'Aluminium', text: 'Aluminium'},
            { value: 'Autre', text: 'Autre'},
            { value: 'Pas de radiateur', text: 'Pas de radiateur'}
          ],
          value: null,
        },
        {
          id:9,
          title :"Nombre de radiateurs ?",
          comment : "matiereRadiateur",
          question_type: "select",
          value_type : "number",
          list_values : null,
          value: null,
        },
        {
          id:10,
          title :"Avez-vous un plancher chauffant ?",
          comment : "plancherChauffant",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Oui', text: 'Oui' },
            { value: 'Non', text: 'Non'}
          ],
          value: null,
        },
        {
          id:11,
          title :"Chauffage principal",
          comment : "",
          question_type: "section",
          value_type : "text",
          list_values : null,
          value: null
        },
        {
          id:12,
          title :"Chauffage d'appoint",
          comment : "",
          question_type: "section",
          value_type : "text",
          list_values : null,
          value: null
        }
      ]
    },
    {
      id : 4,
      title : "Mon ECS",
      questions: [
        {
          id:0,
          title :"Production d'eau chaude sanitaire",
          comment : "productionEauChaude",
          question_type: "radio",
          value_type : "radio",
          list_values : [
            { text: 'Choisir', value: null },
            { text: 'Chaudière actuelle', value: 'Chaudière actuelle' },
            { text: 'Pompe à chaleur Air/Eau', value: 'Pompe à chaleur Air/Eau' },
            { text: 'Chauffe-eau Eléctrique', value: 'Chauffe-eau Eléctrique' },
            { text: 'Chauffe-eau Gaz', value: 'Chauffe-eau Gaz' },
            { text: 'Ballon Thermodynamique', value: 'Ballon Thermodynamique' },
            { text: 'Chauffe-eau Solaire', value: 'Chauffe-eau Solaire' },
            { text: 'Autre', value: 'Autre' }
          ],
          value: null,
          sub_questions : [
            {
              id:0,
              title :"Précisez",
              comment : "typeInstan",
              question_type: "inputText",
              value_type : "text",
              selected_parent_value : "Autre",
              list_values : null,
              value: null,
            },
            {
              id:1,
              title :"Matériel à installer",
              comment : "recommandation",
              question_type: "radio",
              value_type : "radio",
              selected_parent_value : ["Chaudière actuelle","Pompe à chaleur Air/Eau"],
              list_values : [
                  { text: 'Ballon Thermodynamique', value: 'BTD' },
                  { text: 'Ballon Electrique', value: 'Ballon Electrique' },
                  { text: 'ECS Intégré (Duo)', value: 'ECS Intégré' },
                  { text: 'Ballon Solaire', value: 'Ballon Solaire' },
                ],
              value: "BTD",
            },
            {
              id:2,
              title :"Matériel à installer",
              comment : "recommandation2",
              question_type: "radio",
              value_type : "radio",
              selected_parent_value : ['Chauffe-eau Eléctrique','Chauffe-eau Gaz', 'Ballon Thermodynamique', 'Chauffe-eau Solaire'],
              list_values : [
                  { text: 'Ballon Thermodynamique', value: 'BTD' },
                  { text: 'Ballon Electrique', value: 'Ballon Electrique' },
                  { text: 'Ballon Solaire', value: 'Ballon Solaire' },
                  { text: 'Rien', value: 'Rien' },
                ],
              value: "BTD"
            }
          ]
        },
        {
          id:1,
          title :"Nombre d'occupants adultes",
          comment : "nbAdultes",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null,
        },
        {
          id:2,
          title :"Nombre d'occupants enfants",
          comment : "nbEnfants",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null,
        },
        {
          id:3,
          title :"Année d'installation ECS",
          comment : "anneeECS",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Moins de 5 ans', text: 'Moins de 5 ans' },
            { value: 'Entre 5 et 15 ans', text: 'Entre 5 et 15 ans'},
            { value: 'Plus de 15 ans', text: 'Plus de 15 ans'}
          ],
          value: null,
        },
        {
          id:4,
          title :"Hauteur sous plafond",
          comment : "hauteurssplafond",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null,
        }
      ],
    },
    {
      id : 5,
      title : "Ma ventilation",
      questions: [
        {
          id:0,
          title :"Système de ventilation",
          comment : "ventilation",
          question_type: "select",
          value_type : "select",
          list_values : [
                { text: 'Par infiltration', value: 'Par infiltration' },
                { text: 'Par grilles hautes et basses', value: 'Par grilles hautes et basses' },
                { text: 'VMC SF auto', value: 'VMC SF auto' },
                { text: 'VMC Hygro', value: 'VMC Hygro' },
                { text: 'Ne sais pas', value: 'Ne sais pas' }
              ],
          value: null
        },
        {
          id:1,
          title :"Avez vous un système de ventilation existant ?",
          comment : "system-ventilation",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Oui', text: 'Oui' },
            { value: 'Non', text: 'Non'}
          ],
          value: null,
          sub_questions : [
            {
              id:0,
              title :"Type de ventilation",
              comment : "TypeVentilation",
              question_type: "select",
              value_type : "select",
              selected_parent_value : "Oui",
              list_values : [
                { value: null, text: 'Choisir ...' },
                { value: 'VMC Simple flux', text: 'VMC Simple flux' },
                { value: 'VMC Double flux', text: 'VMC Double flux'}
              ],
              value: null
            }
          ]
        }
      ],
    },
    {
      id : 6,
      title : "Mes dépenses",
      questions: [
        {
          id:0,
          title :"Electricité (KWh/an)",
          comment : "depensesAnnuelleElectricite",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:1,
          title :"Electricité (TTC/an)",
          comment : "depElecTTC",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:2,
          title :"Gaz (KWh ou m3/an)",
          comment : "depAnGaz",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:3,
          title :"Gaz (TTC/an)",
          comment : "depAnGazTTC",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:4,
          title :"Fioul (L ou KWh/an)",
          comment : "depAnFioul",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:5,
          title :"Fioul (TTC/an)",
          comment : "depAnFioulTTC",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:6,
          title :"Bois granulés (sac, tonnes ou kWh/an)",
          comment : "depAnBois",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:7,
          title :"Bois granulés (TTC/an)",
          comment : "depAnBoisTTC",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:8,
          title :"Bois bûches (steres ou KWh/an)",
          comment : "depAnBuches",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:9,
          title :"Bois bûches (TTC/an)",
          comment : "depAnBuchesTTC",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:10,
          title :"Propane (kg, tonnes ou KWh/an)",
          comment : "depAnPropane",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:11,
          title :"Propane (TTC/an)",
          comment : "depAnpropaneTTC",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:12,
          title :"Dépenses énergétiques",
          comment : "",
          question_type: "section",
          value_type : "text",
          list_values : null,
          value: null
        },
        {
          id:13,
          title :"Combustible (TTC/an)",
          comment : "depCombustibleTTC",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:14,
          title :"Combustible (KWh/an)",
          comment : "depensesAnnuelleCombustible",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
      ],
    },
    {
      id : 7,
      title : "Recommandations",
      questions: [
        {
          id:0,
          title :"Quel type d'installation recommandez-vous ?",
          comment : "typeInstallation",
          question_type: "checklist",
          value_type : "checkbox",
          list_values : [
                { text: 'PAC Air/Air', value: 'RR' },
                { text: 'PAC Air/Eau', value: 'RO' },
                { text: 'Isolation Thermique Extérieure (ITE)', value: 'ITE' },
                { text: 'Isolation Thermique Intérieure (ITI)', value: 'ITI' },
                { text: 'Isolation des combles', value: 'Isolation des combles' },
                { text: 'Isolation des sous-sols', value: 'Isolation des sous-sols' },
                { text: 'Ballon Thermodynamique', value: 'BTD' },
                { text: 'Ballon Electrique', value: 'Ballon Electrique' },
              ],
          value: [],
          sub_questions : [
            {
              id:0,
              title :"Capacité du ballon",
              comment : "capaciteBallon",
              question_type: "inputText",
              value_type : "number",
              selected_parent_value : "Ballon Thermodynamique",
              list_values : null,
              value: null
            }
          ]
        },
        {
          id:1,
          title :"Commentaire",
          comment : "commentaire",
          question_type: "inputText",
          value_type : "text",
          list_values : null,
          value: null
        }
      ],
    },
    {
      id : 8,
      title : "Check-list",
      questions: [
        // {
        //   id:0,
        //   title :"Documents à fournir",
        //   comment : "documents",
        //   question_type: "section",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:1,
        //   title :"Contrat AMO",
        //   comment : "contratAMO",
        //   question_type: "uploadDoc",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:2,
        //   title :"Devis",
        //   comment : "devis",
        //   question_type: "uploadDoc",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:3,
        //   title :"Dernier avis d'imposition (toutes les pages)",
        //   comment : "avisImpot",
        //   question_type: "uploadDoc",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:4,
        //   title :"Si 2 déclarants, fournir le deuxième avis d'imposition (toutes les pages)",
        //   comment : "avisImpot2",
        //   question_type: "uploadDoc",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:5,
        //   title :"CNI recto",
        //   comment : "CNIrecto",
        //   question_type: "uploadDoc",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:6,
        //   title :"CNI verso",
        //   comment : "CNIverso",
        //   question_type: "uploadDoc",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:7,
        //   title :"Dernier bulletin de salaire",
        //   comment : "bulletinSalaire",
        //   question_type: "uploadDoc",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:8,
        //   title :"Livret de famille (si enfants à charge). Si pas d'enfant, un acte de mariage, de Pacs ou autre",
        //   comment : "livretFamille",
        //   question_type: "uploadDoc",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:9,
        //   title :"Dernière taxe foncière ou acte notarié",
        //   comment : "taxeFonciere",
        //   question_type: "uploadDoc",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:10,
        //   title :"Si Co-demandeur",
        //   comment : "codemandeur",
        //   question_type: "section",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:11,
        //   title :"CNI recto",
        //   comment : "codemandeurCNIrecto",
        //   question_type: "uploadDoc",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:12,
        //   title :"CNI verso",
        //   comment : "codemandeurCNIverso",
        //   question_type: "uploadDoc",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:13,
        //   title :"Bulletin de salaire",
        //   comment : "codemandeurBulletinSalaire",
        //   question_type: "uploadDoc",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:14,
        //   title :"Livret de famille",
        //   comment : "codemandeurLivret",
        //   question_type: "uploadDoc",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:15,
        //   title :"Si célibataire sans enfants, demander une attestation sur l'honneur",
        //   comment : "attestationCelibataire",
        //   question_type: "uploadDoc",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:16,
        //   title :"Si célibataire avec enfants, demander une attestation sur l'honneur et acte de naissance des enfants",
        //   comment : "attestationCelibataireAvecEnfants",
        //   question_type: "uploadDoc",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:17,
        //   title :"Si locataire",
        //   comment : "locataire",
        //   question_type: "section",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:18,
        //   title :"Bail de location",
        //   comment : "bail",
        //   question_type: "uploadDoc",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:19,
        //   title :"Attestation du propriétaire autorisant la demande et les travaux', value: 'Attestation du propriétaire autorisant la demande et les travaux",
        //   comment : "attestationProprietaire",
        //   question_type: "uploadDoc",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:20,
        //   title :"Si système de chauffage",
        //   comment : "sysChauffage",
        //   question_type: "section",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:21,
        //   title :"Diagnostic",
        //   comment : "diagnostic",
        //   question_type: "uploadDoc",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        {
          id:22,
          title :"Photos",
          comment : "photos",
          question_type: "section",
          value_type : null,
          list_values : null,
          value: null
        },
        // {
        //   id:23,
        //   title :"Photo de la facade",
        //   comment : "photoFacade",
        //   question_type: "uploadDoc",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:24,
        //   title :"Photo du vitrage",
        //   comment : "photoVitrage",
        //   question_type: "uploadDoc",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        {
          id:25,
          title :"Photo Chauffage : La chaudière",
          comment : "photoChauffageChaudiere",
          question_type: "uploadDoc",
          value_type : null,
          list_values : null,
          value: null
        },
        {
          id:26,
          title :"Photo Chauffage : Les tuyeaux au départ de la chaudière",
          comment : "photoChauffageTuyaux",
          question_type: "uploadDoc",
          value_type : null,
          list_values : null,
          value: null
        },
        {
          id:27,
          title :"Photo Chauffage : Radiateurs existants",
          comment : "photoChauffageRadiateurs",
          question_type: "uploadDoc",
          value_type : null,
          list_values : null,
          value: null
        },
        {
          id:28,
          title :"Photo Compteur EDF de près pour pouvoir lire dessus",
          comment : "photoCompteur",
          question_type: "uploadDoc",
          value_type : null,
          list_values : null,
          value: null
        },
        // {
        //   id:29,
        //   title :"Si système de chauffage et maison de moins de 5 ans",
        //   comment : "sysChauffageMaisonM5",
        //   question_type: "section",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:30,
        //   title :"Photo de l'isolation en place (Photo 1)",
        //   comment : "photoIso1",
        //   question_type: "uploadDoc",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:31,
        //   title :"Photo de l'isolation en place (Photo 2)",
        //   comment : "photoIso2",
        //   question_type: "uploadDoc",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:32,
        //   title :"Photo de l'isolation en place (Photo 3)",
        //   comment : "photoIso",
        //   question_type: "uploadDoc",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:33,
        //   title :"Si système de chauffage et maison de plus de 5 ans",
        //   comment : "sysChauffageMaisonP5",
        //   question_type: "section",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // },
        // {
        //   id:34,
        //   title :"Facture isolation ou attestation sur l'honneur",
        //   comment : "factureIso",
        //   question_type: "uploadDoc",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // } ,
        // {
        //   id:35,
        //   title :"Devis",
        //   comment : "devis",
        //   question_type: "uploadDoc",
        //   value_type : null,
        //   list_values : null,
        //   value: null
        // } , 
        {
          id:36,
          title :"Photo de la maison",
          comment : "maison",
          question_type: "uploadDoc",
          value_type : null,
          list_values : null,
          value: null,
          condition: "PV"
        } ,
        {
          id:37,
          title :"Photo de la toiture",
          comment : "toiture",
          question_type: "uploadDoc",
          value_type : null,
          list_values : null,
          value: null,
          condition : "PV"
        }   
      ],
    },
    {
      id : 9,
      title : "Isolation",
      questions: [
        {
          id:0,
          title :"Est-ce que vos combles sont bien isolés ?",
          comment : "qualiteIsolation",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Mal', text: 'Mal' },
            { value: 'Moyen', text: 'Moyen'},
            { value: 'Bien', text: 'Bien'}
        ],
          value: null
        },
        {
          id:1,
          title :"Est-ce que votre isolation extérieure est bonne ?",
          comment : "isolationExterieure",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Oui', text: 'Oui' },
            { value: 'Non', text: 'Non'}
          ],
          value: null
        },
        {
          id:2,
          title :"Type de vitrage",
          comment : "typeVitrage",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: 'Simple', text: 'Simple' },
            { value: 'Double', text: 'Double'},
            { value: 'Triple', text: 'Triple'}
          ],
          value: null
        }
      ],
    },
    {
      id : 10,
      title : "Mon raccordement electrique",
      questions: [
        {
          id:0,
          title :"Quel est votre type d'installation électrique ?",
          comment : "typeInstallationElectrique",
          question_type: "radio",
          value_type : "radio",
          list_values : [
            { value: 'Monophasé', text: 'Monophasé' },
            { value: 'Triphasé', text: 'Triphasé'},
          ],
          value: null
        },
        {
          id:1,
          title :"Quel est la puissance de votre compteur ?",
          comment : "puissanceCompteur",
          question_type: "select",
          value_type : "select",
          list_values : [
            { value: null, text: 'Choisir ...' },
            { value: '12 KVA', text: '12 KVA' },
            { value: '24 KVA', text: '24 KVA'},
            { value: '36 KVA', text: '36 KVA'},
            { value: 'Autre', text: 'Autre'}
          ],
          value: null,
          sub_questions : [
            {
              id:0,
              title :"Précisez",
              comment : "puissanceCompteur",
              question_type: "inputText",
              value_type : "number",
              selected_parent_value : "Autre",
              list_values : null,
              value: null
            }
          ]
        }
      ],

    },
    {
      id : 11,
      title : "Produits / Obligés",
      questions: [
        {
          id:0,
          title :"Produits recommandés",
          comment : "tproduitReco",
          question_type: "checklist",
          value_type : "checkbox",
          list_values : [
            { text: 'Photovoltaïque', value: 'PV' },
            { text: 'PAC Air/Eau', value: 'RO' },
            { text: 'PAC Air/Air', value: 'RR' },
            { text: 'Eau chaude', value: 'EC' },
            { text: 'Ventilation', value: 'VMC' },
            { text: 'Isolation', value: 'ISO' }
          ],
          value: ['RO','EC','VMC'],
        },
      ]
    },
    {
      id : 12,
      title : "PAC Air/Air",
      questions: [
        {
          id:0,
          title :"Nombre de groupe(s) extérieur(s)",
          comment : "nbGrpExt",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:1,
          title :"Nombre de diffuseurs",
          comment : "nbDiff",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
        {
          id:2,
          title :"Système de régulation du chauffage",
          comment : "sysRegulChau",
          question_type: "checklist",
          value_type : "checkbox",
          list_values : [
            { text: 'Oui', value: 'Oui' },
            { text: 'Non', value: 'Non' }
          ],
          value: null,
          sub_questions : [
            {
              id:0,
              title :"Nombre",
              comment : "nbSysRegulChau",
              question_type: "inputText",
              value_type : "number",
              selected_parent_value : "Oui",
              list_values : null,
              value: null
            }
          ]
        },
      ],
    },
    {
      id : 13,
      title : "PAC Air/Eau",
      questions: [
        {
          id:0,
          title :"",
          comment : "actionchaudiere",
          question_type: "radio",
          value_type : "radio",
          list_values : [
            { text: 'Relève de chaudière', value: 'Relève de chaudière' },
            { text: 'Suppression de chaudière', value: 'Suppression de chaudière' }
          ],
          value: null,
        }
      ],
    }
    ,
    {
      id : 14,
      title : "Eau Chaude Sanitaire",
      questions: [
        {
          id:0,
          title :"Capacité",
          comment : "capBallon",
          question_type: "inputText",
          value_type : "number",
          list_values : null,
          value: null
        },
      ],
    }
    ];
  }


  get eligibleAides() {
    // Si radiateurs, pas d'aides
    return this.blocs[2].questions[0].value != "Convecteurs Electriques" && this.blocs[2].questions[0].value != 'Radiateurs Electriques'
  }

  get eligiblePAC() {
    // Si chauffage central et pas condensation
    return this.chauffagePrincipal == 'Chauffage central'
  }

  get eligibleCDP() {
    if(new Date().getFullYear() >= 2023)
      return true;
      
    return this.chauffagePrincipal != 'Chauffage central' || this.chaudiereCondensation != 'Oui'
  }

  get eligibleANAH() {
    let res = false
    switch (this.blocs[0].questions[0].value) {
      case "Moins de 2 ans":
          res = false
        break;
      case "Entre 2 et 15 ans":
        // Chaudiere au fioul et depose de la cuve
          if(this.blocs[2].questions[1].value == 'Chauffage central' 
            && this.blocs[2].questions[1].sub_questions[0].value == 'Fioul' 
            && this.blocs[2].questions[1].sub_questions[3].value == 'Oui') {
            res = true
          }
        break;
      case "Plus de 15 ans":
          res = true
        break;
          
      default:
        break;
    }
    return res
  }

  get chauffagePrincipal() {
    // Si chauffage central et pas condensation
    return this.blocs[2].questions[1].value
  }

  get chaudiereCondensation() {
    return this.blocs[2].questions[7].value
  }

  get productionECS() {
    return this.blocs[3].questions[0].value
  }

  get superficie() {
    return this.blocs[0].questions[1].value
  }

  get surfaceAChauffer() {
    return this.blocs[0].questions[50].value
  }


  get nbOccupants() {
    return this.blocs[3].questions[1].value + this.blocs[3].questions[2].value
  }

  get phase() {
    return this.blocs[9].questions[0].value
  }

  get hauteurSousPlafond() {
    return this.blocs[3].questions[4].value || 0
  }

  get plancherChauffant() {
    return this.blocs[2].questions[10].value
  }

  get radiateurs() {
    return this.blocs[2].questions[9].value 
  }

  get typeVMC() {
    return this.blocs[4].questions[1].sub_questions[0].value
  }

  get combles() {
    return this.blocs[0].questions[3].value 
  }

  get amenagement() {
    return this.blocs[0].questions[2].value 
  }


  get kitBizone () {
    return this.plancherChauffant == 'Oui' && this.radiateurs > 0
  }

  get VMC() {
    return this.combles == "Non" && this.typeVMC =="VMC Simple flux"
  }

  get produits() {
    return this.blocs[10].questions[0].value || []
  }

  get materiel(){
    console.log("get materiel")
    return this.blocs[3].questions[0].value ?
    (this.blocs[3].questions[0].value.includes("Chaudière actuelle")  
    || this.blocs[3].questions[0].value.includes("Pompe à chaleur Air/Eau")
      ? this.blocs[3].questions[0].sub_questions[1].value
      : this.blocs[3].questions[0].sub_questions[2].value) : []
  }

  get libelleDossierPV() {
    return `${this.blocs[1].questions[9].value}PVX${this.blocs[1].questions[8].value}`
  }

  get qtePanneaux() {
    return this.blocs[1].questions[8].value
  }

  get puissancePanneaux() {
    return this.blocs[1].questions[9].value
  }

  parse(vt) {
    if(vt === undefined || vt == '') 
      return this.blocs;

    vt.forEach(bloc => {
        var currentBloc = this.blocs.filter(x => { return x.id == bloc.id })
        if(currentBloc.length > 0) {
          bloc.questions.forEach(question => {
            var currentQuestion = currentBloc[0].questions.filter(q => { return q.id == question.id })
            if(currentQuestion.length > 0) {
              currentQuestion[0].value = question.value
              if(question.sub_questions !== undefined) {
                question.sub_questions.forEach(sub => {
                  var currentSub = currentQuestion[0].sub_questions.filter(s => { return s.id == sub.id})
                  if(currentSub.length > 0) {
                    currentSub[0].value = sub.value
                  }
                })
              }
            }
          })
        }   
    });  
    
    return this.blocs;
  }

}